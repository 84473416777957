import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";

import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { store as userStore } from "../../../../components/VerticalLayout/store";
import store, {
    setIsModalAssociateStore,
    setIsModalDissociateOpened,
} from "./store";
import ErrorToast from "@components/Common/ErrorTost";

type DropDownWrapperType = {
    element: any;
    setSelectedRows: Function;
    isProjectArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsConfirmationModalActived: Function;
    setIsConfirmationModalCopied: Function;
    associates: any;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    isProjectArchived,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActived,
    setIsConfirmationModalCopied,
    associates,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();

    const { oneShop } = useSnapshot(userStore);
    const { isModalAssociateStore } = useSnapshot(store);

    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }

    return (
        <React.Fragment>
            {element.selectedRow.original.projectId &&
            ((element.selectedRow.original.id_boutique === undefined &&
                !oneShop) ||
                (element.selectedRow.original.id_boutique !== undefined &&
                    oneShop)) ? (
                <Dropdown isOpen={isDropdownOpened} toggle={toggleDropdown}>
                    <DropdownToggle
                        id="page-header-user-dropdown"
                        tag="button"
                        className="pointer__clz btn btn header-item waves-effect"
                    >
                        <VerticalDotsIcon
                            height={25}
                            width={25}
                            className={classnames("", {
                                sco_drp_menuProject: !isProjectArchived,
                                cmn_drp_menuProject: isProjectArchived,
                            })}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        {element.selectedRow.original.Status === "Publié" ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className="cgt_icn_consult"
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("To consult")}</span>
                            </DropdownItem>
                        ) : (
                            <DropdownItem
                                className={classnames("cmn_icn_configuration", {
                                    "not-allowed-icon__clz": isProjectArchived,
                                })}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("Configure")}</span>
                            </DropdownItem>
                        )}
                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_publish", {
                                "not-allowed-icon__clz":
                                    isProjectArchived ||
                                    (associates[
                                        element?.selectedRow?.original.projectId
                                    ] > 1 &&
                                        oneShop),
                            })}
                            onClick={() => {
                                setSelectedRows(element.selectedRow.original);
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Publish")}</span>
                        </DropdownItem>
                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_duplicate", {
                                "not-allowed-icon__clz":
                                    isProjectArchived ||
                                    (associates[
                                        element?.selectedRow?.original.projectId
                                    ] > 1 &&
                                        oneShop),
                            })}
                            onClick={() => {
                                setSelectedRows([element.selectedRow.original]);
                                setIsConfirmationModalCopied(true);
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Duplicate")}</span>
                        </DropdownItem>
                        {!oneShop ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className={classnames(
                                    "cmn_icn_associateStore",
                                    {
                                        "not-allowed-icon__clz":
                                            isProjectArchived ||
                                            oneShop ||
                                            element.selectedRow.original
                                                .Status === "Publié",
                                    }
                                )}
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                    setIsModalAssociateStore(
                                        !isModalAssociateStore
                                    );
                                }}
                            >
                                <DuplicateIcon height={15} width={15} />
                                <span>{t("Associate to store(s)")}</span>
                            </DropdownItem>
                        ) : null}
                        <DropdownItem
                            className={classnames("", {
                                "not-allowed-icon__clz":
                                    element.selectedRow.original.Status ===
                                    "Publié",
                                cmn_btn_FRunarchive: !isProjectArchived,
                                cmn_icn_FRunarchive: isProjectArchived,
                            })}
                            onClick={() => {
                                setSelectedRows([element.selectedRow.original]);
                                if (oneShop) {
                                    setIsModalDissociateOpened(true);
                                } else if (!oneShop) {
                                    if (!isProjectArchived) {
                                        if (
                                            element.selectedRow.original.subRows
                                                .length > 0
                                        ) {
                                            return ErrorToast(
                                                `${t(
                                                    "Please note that you cannot archive a project associated with the store(s)."
                                                )!}`
                                            );
                                        } else {
                                            setIsConfirmationModalArchived(
                                                true
                                            );
                                        }
                                    } else {
                                        setIsConfirmationModalActived(true);
                                    }
                                }
                            }}
                        >
                            {!isProjectArchived ? (
                                <TrashIcon height={15} width={15} />
                            ) : (
                                <EyeIcon height={15} width={15} />
                            )}

                            <span
                                className={classnames("", {
                                    "not-allowed-icon__clz":
                                        element.selectedRow.original.Status ===
                                        "Publié",
                                })}
                            >
                                {!isProjectArchived
                                    ? oneShop
                                        ? `${t("Delete")}`
                                        : `${t("Archive")}`
                                    : `${t("Enable")}`}
                            </span>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
        </React.Fragment>
    );
}
