import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "../../../components/Common/CustomCardStyled";

import { store as aggregator, setIsConsultModalOpened } from "./store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type AggregatorFormType = {
    selectedShopsRows: any;
    setGlobalDataIsUpdated: Function;
};

export default function InformationAggregator({
    selectedShopsRows,
    setGlobalDataIsUpdated,
}: AggregatorFormType) {
    const { t } = useTranslation();
    const { isConsultModalOpened } = useSnapshot(aggregator);

    const [choiceAggregator, setChoiceAggregator] = React.useState<string>("");
    const [selectChoiceAggregator] = React.useState<boolean>(false);

    const [remark, setRemark] = React.useState<string>("");
    const [webhook, setWebhook] = React.useState<string>("");

    //eslint-disable-next-line
    const [isEdited, setIsEdited] = React.useState<boolean>(false);

    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const uuidUser = localStorage.getItem("uuidUser");

    async function editAggregator() {
        toast.dismiss();

        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        let dataConfig = {
            userId: userID,
            shopId: [selectedShopsRows[0].shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedShopsRows[0]?.Id]: {
                    aggregator: choiceAggregator,
                    webhook: webhook,
                    note: remark,
                    isArchived: false,
                    isActive: selectedShopsRows[0].isActive,
                    shopId: selectedShopsRows[0].shopId,
                    state: selectedShopsRows[0].state,
                    stateShop: selectedShopsRows[0].stateShop,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetch(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Aggregator setting has been successfully modified"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsConsultModalOpened(false);
                        setGlobalDataIsUpdated!(true);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    useEffect(() => {
        if (selectedShopsRows[0]?.Id !== undefined) {
            setChoiceAggregator(
                selectedShopsRows[0][t("Aggregator")]
                    ? selectedShopsRows[0][t("Aggregator")]
                    : ""
            );
            setWebhook(selectedShopsRows[0][t("Webhook")]);
            setRemark(selectedShopsRows[0][t("Remark")]);
        } else {
            setChoiceAggregator("");
            setRemark("");
            setWebhook("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShopsRows, t]);

    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={isConsultModalOpened}
                className="information_aggregator_modal__clz mt-5"
            >
                <AvForm className="card information_aggregator__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Interconnect setup"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsConsultModalOpened!(!isConsultModalOpened);
                            }}
                        />
                    </CustomCardHeader>
                    <CustomCardBody
                        className="pl-3 pr-5"
                        style={{
                            maxHeight: "500px",
                            overflowY: " scroll",
                        }}
                    >
                        <AvGroup>
                            <StyledLabel
                                className="required__clz mt-3"
                                htmlFor="choiceAggregator"
                            >
                                {t("Aggregator")}
                            </StyledLabel>

                            <StyledSelectInput
                                value={
                                    choiceAggregator === ""
                                        ? null
                                        : {
                                              label: choiceAggregator,
                                              value: choiceAggregator,
                                          }
                                }
                                className="not-allowed-aggregator__clz"
                                style={{ opactiy: "0.8" }}
                                onChange={(e: any) => {}}
                                options={[
                                    {
                                        label: t("Otter"),
                                        value: t("Otter"),
                                    },
                                    {
                                        label: t("Delivrect"),
                                        value: t("Delivrect"),
                                    },
                                ]}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t(
                                            "Please select a aggregator choice"
                                        )!}`,
                                    },
                                }}
                                placeholder={t("Select")}
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                            {selectChoiceAggregator ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a Aggregator choice")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel className="mt-3" htmlFor="name">
                                {t("Webhook")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="Webhook"
                                name="Webhook"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    setWebhook(e.target.value);
                                    setIsEdited(true);
                                }}
                                className={classnames("", {})}
                                value={webhook}
                            />
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel className="mt-3" htmlFor="name">
                                {t("Remark")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="remark"
                                name="remark"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    setRemark(e.target.value);
                                    setIsEdited(true);
                                }}
                                className={classnames("", {})}
                                value={remark}
                            />
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                setIsConsultModalOpened(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            className={`cmn_btn_validate ${classnames({})}`}
                            onClick={() => {
                                editAggregator();
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
