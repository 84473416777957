import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";

import ErrorToast from "@components/Common/ErrorTost";

import { store as posEditor } from "../../../../components/VerticalLayout/store";
import { store, setActions, setIsActionsClicked, setIsEdited } from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    setIsPosEditorArchived,
    isPosEditorArchived,
    setIsAddPosEditorClicked,
}: any) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsPosEditorData,
        archivedPosEditorFranchise,
        isEdited,
        isActionsClicked,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(posEditor);
    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = associatePosEditor();
        dataArchive = archivePosEditor();
    } else {
        dataArchive = archivePosEditorFranchise();
    }

    function archivePosEditor() {
        let idsAssociated: any = [];
        if (shopsPosEditorData.data !== undefined) {
            shopsPosEditorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function archivePosEditorFranchise() {
        let idsAssociated: any = [];
        if (
            archivedPosEditorFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedPosEditorFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function associatePosEditor() {
        let idsAssociated: any[] = [];
        if (shopsPosEditorData.data !== undefined) {
            shopsPosEditorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isPosEditorExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        }
        !isExist && setIsArchiveModalOpened(true);
    }

    async function activePosEditor() {
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let dataPosEditor = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        posEditor: el[t("Pos Editor")],
                        codeComment: el["codeComment"],
                        note: el[t("Remark")],
                        isArchived: false,
                        shopId: el.shopId,
                        user: el.user,
                        versionServer: el.versionServer,
                        keyAuthentication: el.keyAuthentication,
                        apiEmail: el.apiEmail,
                        port: el.port,
                        server: el.server,
                        apiServer: el.apiServer,
                        setupId: el.setupId,
                        apiPassword: el.apiPassword,
                        modeOfSaleEmpId: el.modeOfSaleEmpId,
                        modeOfSaleLivId: el.modeOfSaleLivId,
                        modeOfSaleSPId: el.modeOfSaleSPId,
                        divisionPriceItems: el.divisionPriceItems,
                        divisionPriceOptions: el.divisionPriceOptions,
                        divisionTax: el.divisionTax,
                        layoutLabel: el.layoutLabel,
                        apiWebhook: el.apiWebhook,
                        happyHour: el?.happyHour,
                        happyHourState: el?.happyHourState,
                        tcposStoreId: el?.tcposStoreId,
                        tcposWebhookPort: el?.tcposWebhookPort,
                        tcposWebhookIPAddess: el?.tcposWebhookIPAddess,
                        tcposFranchiseSchema: el?.tcposFranchiseSchema,
                        tcposPaymentCash: el?.tcposPaymentCash,
                        tcposPaymentCreditCard: el?.tcposPaymentCreditCard,
                        tcposSetupDisk: el?.tcposSetupDisk,
                        tcposCodeCountry: el?.tcposCodeCountry,
                        tcposLangue: el?.tcposLangue,
                        tcposApiPort: el?.tcposApiPort,
                        tcposPartnerApiUrl: el?.tcposPartnerApiUrl,
                        tcposIp: el?.tcposIp,
                        tcposConnectionCheckTime: el?.tcposConnectionCheckTime,
                        tcposDeletingDataSate: el?.tcposDeletingDataSate,
                        codeRetryTable: el?.codeRetryTable,
                        maxRetry: el?.maxRetry,
                        retryDelai: el?.retryDelai,
                        checkStock: el?.checkStock,
                        orderValidationPreCheck: el?.orderValidationPreCheck,
                        timeoutAPI: el?.timeoutAPI,
                        automaticProductionLevel: el?.automaticProductionLevel,
                        tcposCorrTakeawayDisplayName:
                            el?.tcposCorrTakeawayDisplayName,
                        tcposCorrTakeawayID: el?.tcposCorrTakeawayID,
                        tcposCorrOnSiteDisplayName:
                            el?.tcposCorrOnSiteDisplayName,
                        tcposCorrOnSiteID: el?.tcposCorrOnSiteID,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataPosEditor),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(
                `${t(
                    "The Pos Editor setting has been successfully unarchive"
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_FRmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsPosEditorArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistActivated">
                                {t("Pos Editor Enabled(s)")} ({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsPosEditorArchived(true);
                                        setIsAddPosEditorClicked(false);
                                    });
                                } else {
                                    setIsPosEditorArchived(true);
                                    setIsAddPosEditorClicked(false);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {t("Archived Pos Editor(s)")}({numberArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);

                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    });
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz ",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isPosEditorArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else if (
                                            selectedRows.length > 1 &&
                                            oneShop &&
                                            isPosEditorArchived
                                        ) {
                                            ErrorToast(
                                                t(
                                                    "Attention,a shop cannot have more than one Pos Editor"
                                                )
                                            );
                                        } else {
                                            if (isPosEditorArchived) {
                                                if (
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    activePosEditor();
                                                } else {
                                                    activePosEditor();
                                                }
                                            } else {
                                                isPosEditorExist();
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRarchived cmn_btn_FRunarchive">
                                        {isPosEditorArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
