import React from "react";

import { t } from "i18next";

export function CustomerAccount(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 239 68"
        >
            <defs>
                <filter
                    id="Rectangle_2"
                    x="0"
                    y="0"
                    width="239"
                    height="68"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_8"
                data-name="Groupe 8"
                transform="translate(2014 4004)"
            >
                <g
                    id="Groupe_1"
                    data-name="Groupe 1"
                    transform="translate(500 -3752)"
                >
                    <g
                        transform="matrix(1, 0, 0, 1, -2514, -252)"
                        filter="url(#Rectangle_2)"
                    >
                        <rect
                            id="Rectangle_2-2"
                            data-name="Rectangle 2"
                            width="221"
                            height="50"
                            rx="9"
                            transform="translate(9 6)"
                            fill="#f8f8f6"
                        />
                    </g>
                    <text
                        id="My_account"
                        data-name="My account"
                        transform="translate(-2377 -212)"
                        font-size="24"
                        font-family="HelveticaNeue-Medium, Helvetica Neue"
                        font-weight="500"
                    >
                        <tspan x="-70.236" y="0">
                            {t("My account", {
                                lng: props.selectedLanguage,
                            })}
                        </tspan>
                    </text>
                    <g
                        id="Layer_2"
                        data-name="Layer 2"
                        transform="translate(-2487.989 -236.999)"
                    >
                        <path
                            id="Tracé_1"
                            data-name="Tracé 1"
                            d="M16,17a6,6,0,1,1,6-6,6,6,0,0,1-6,6ZM16,7a4,4,0,1,0,4,4A4,4,0,0,0,16,7Z"
                        />
                        <path
                            id="Tracé_2"
                            data-name="Tracé 2"
                            d="M16,31A15,15,0,0,1,4.41,25.51l-.52-.64.52-.63a15,15,0,0,1,23.18,0l.52.63-.52.64A15,15,0,0,1,16,31ZM6.51,24.88a13,13,0,0,0,19,0,13,13,0,0,0-19,0Z"
                        />
                        <path
                            id="Tracé_3"
                            data-name="Tracé 3"
                            d="M16,31a15,15,0,1,1,11.59-5.49A15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"
                        />
                        <path
                            id="Tracé_4"
                            data-name="Tracé 4"
                            d="M5.18,24.88S15.25,36.13,25.5,26l1.32-1.12S18.26,16,9.57,21.33Z"
                        />
                        <circle
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="5"
                            cy="5"
                            r="5"
                            transform="translate(11 6)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
