import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import classnames from "classnames";
import ReactSwitch from "react-switch";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import {
    store as posEditor,
    setNumberActive,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsArchived,
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import {
    emailMatchWord,
    ipMatchWord,
    numberPositiveMatch,
} from "@constants/index";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type PosEditorFormType = {
    setIsAddPosEditorClicked: Function;
    isAddPosEditorClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataPosEditor: React.SetStateAction<any>;
    isAddNewPosEditorButtonClicked: boolean;
    setDataPosEditor: Function;
    setLocalFilterActive: Function;
    languageData: any;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function PosEditorForm({
    setIsAddPosEditorClicked,
    isAddPosEditorClicked,
    setDataIsUpdated,
    dataPosEditor,
    isAddNewPosEditorButtonClicked,
    setDataPosEditor,
    setLocalFilterActive,
    languageData,
    setIsConsult,
    isConsult,
}: PosEditorFormType) {
    const { t } = useTranslation();

    const {
        userID,
        franchiseID,
        resourceIp,
        shopID,
        oneShop,
        operatorID,
    } = useSnapshot(store);
    const {
        numberActive,
        shopsPosEditorData,
        posEditorData,
        posEditorFranchise,
        dataArchive,
        dataFranchiseArchive,
        isConsultModalOpened,
        isActionsClicked,
        isEdited,
    } = useSnapshot(posEditor);

    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const [canValidate, setCanValidate] = React.useState(true);

    const shopId = oneShop ? shopID : "0";

    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [isExist, setIsExist] = React.useState<boolean>(false);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [user, setUser] = React.useState<string>("");
    const [apiServer, setApiServer] = React.useState<string>("");
    const [server, setServer] = React.useState<string>("");
    const [setupId, setSetupId] = React.useState<string>("");
    const [apiPassword, setApiPassword] = React.useState<string>(
        "KRnyKX_ZgqwquqyLt7pG"
    );
    const [modeOfSaleEmpId, setModeOfSaleEmpId] = React.useState<string>("");
    const [modeOfSaleLivId, setModeOfSaleLivId] = React.useState<string>("");
    const [modeOfSaleSPId, setModeOfSaleSPId] = React.useState<string>("");
    const [divisionPriceItems, setDivisionPriceItems] = React.useState<string>(
        "1000"
    );
    const [divisionPriceOptions, setDivisionPriceOptions] = React.useState<
        string
    >("1000");
    const [divisionTax, setDivisionTax] = React.useState<string>("10");
    const [layoutLabel, setLayoutLabel] = React.useState<string>("");
    const [apiWebhook, setApiWebhook] = React.useState<string>("");
    const [port, setPort] = React.useState<string>("");
    const [apiEmail, setApiEmail] = React.useState<string>("aures@cashpad.fr");

    const [keyAuthentication, setKeyAuthentication] = React.useState<string>(
        ""
    );
    const [versionServer, setVersionServer] = React.useState<string>("");
    const [choicePosEditor, setChoicePosEditor] = React.useState<string>("");
    const [selectChoicePosEditor, setSelectChoicePosEditor] = React.useState<
        boolean
    >(false);
    const [APIServerCheck, setAPIServerCheck] = React.useState<boolean>(false);
    const [setupIDCheck, setSetupIDCheck] = React.useState<boolean>(false);
    const [userMailAPICheck, setUserMailAPICheck] = React.useState<boolean>(
        false
    );
    const [userPasswordAPICheck, setUserPasswordAPICheck] = React.useState<
        boolean
    >(false);
    const [takeawayCheck, setTakeawayCheck] = React.useState<boolean>(false);
    const [inDeliveryCheck, setInDeliveryCheck] = React.useState<boolean>(
        false
    );
    const [dineInCheck, setDineInCheck] = React.useState<boolean>(false);
    const [productPriceCheck, setProductPriceCheck] = React.useState<boolean>(
        false
    );
    const [optionPriceCheck, setOptionPriceCheck] = React.useState<boolean>(
        false
    );
    const [VATRateMatchCheck, setVATRateMatchCheck] = React.useState<boolean>(
        false
    );
    const [keyLayoutLabelCheck, setKeyLayoutLabelCheck] = React.useState<
        boolean
    >(false);

    const [remark, setRemark] = React.useState<string>("");
    const [tcposIp, setTcposIp] = React.useState<string>("127.0.0.1");
    const [tcposIpCheck, setTcposIpCheck] = React.useState<boolean>(false);
    const [ipValidCheck, setIpValidCheck] = React.useState<boolean>(false);
    const [ipExistCheck, setIpExistCheck] = React.useState<boolean>(false);
    const [tcposApiPort, setTcposApiPort] = React.useState<string>("8080");
    const [tcposApiPortCheck, setTcposApiPortCheck] = React.useState<boolean>(
        false
    );
    const [tcposLangue, setTcposLangue] = React.useState<any>({});

    const [tcposCodeCountry, setTcposCodeCountry] = React.useState<string>("");

    const [tcposSetupDisk, setTcposSetupDisk] = React.useState<string>("");

    const [tcposPaymentCash, setTcposPaymentCash] = React.useState<string>("1");
    const [tcposPaymentCashCheck, setTcposPaymentCashCheck] = React.useState<
        boolean
    >(false);
    const [tcposPaymentCreditCard, setTcposPaymentCreditCard] = React.useState<
        string
    >("3");
    const [
        tcposPaymentCreditCardCheck,
        setTcposPaymentCreditCardCheck,
    ] = React.useState<boolean>(false);
    const [tcposPartnerApiUrl, setTcposPartnerApiUrl] = React.useState<string>(
        "SelfCheckout"
    );
    const [
        tcposPartnerApiUrlCheck,
        setTcposPartnerApiUrlCheck,
    ] = React.useState<boolean>(false);
    const [tcposFranchiseSchema, setTcposFranchiseSchema] = React.useState<
        string
    >("");
    const [, setTcposFranchiseSchemaCheck] = React.useState<boolean>(false);
    const [tcposWebhookIPAddess, setTcposWebhookIPAddess] = React.useState<
        string
    >("127.0.0.1");
    const [
        tcposWebhookIPAddessCheck,
        setTcposWebhookIPAddessCheck,
    ] = React.useState<boolean>(false);
    const [tcposWebhookPort, setTcposWebhookPort] = React.useState<string>(
        "8181"
    );
    const [tcposWebhookPortCheck, setTcposWebhookPortCheck] = React.useState<
        boolean
    >(false);
    const [
        tcposConnectionCheckTime,
        setTcposConnectionCheckTime,
    ] = React.useState<string>("1000");
    const [
        tcposConnectionCheckTimeCheck,
        setTcposConnectionCheckTimeCheck,
    ] = React.useState<boolean>(false);

    const [tcposStoreId, setTcposStoreId] = React.useState<string>("");
    const [codeComment, setCodeComment] = React.useState<string>("");

    const [tcposDeletingDataSate, setTcposDeletingDataSate] = React.useState<
        boolean
    >(false);

    const [, setIsValidateButtonEnabled] = React.useState<boolean>(true);
    const [codeRetryTable, setCodeRetryTable] = React.useState<any>(["302"]);

    const [maxRetry, setMaxRetry] = React.useState<string>("3");
    const [retryDelai, setRetryDelai] = React.useState<string>("10");
    const [checkStock, setCheckStock] = React.useState<boolean>(false);
    const [timeoutAPI, setTimeoutAPI] = React.useState<string>("40");

    const [codeRetryTableCheck, setCodeRetryTableCheck] = React.useState<
        boolean
    >(false);
    const [maxRetryCheck, setMaxRetryCheck] = React.useState<boolean>(false);
    const [retryDelaiCheck, setRetryDelaiCheck] = React.useState<boolean>(
        false
    );
    const [
        orderValidationPreCheck,
        setOrderValidationPreCheck,
    ] = React.useState<boolean>(false);
    const [
        automaticProductionLevel,
        setAutomaticProductionLevel,
    ] = React.useState<boolean>(true);
    const [happyHourState, setHappyHourState] = React.useState<boolean>(false);
    const [happyHourCheck, setHappyHourCheck] = React.useState<boolean>(false);
    const [happyHour, setHappyHour] = React.useState<string>("Happy hour- LDF");

    const [timeoutAPICheck, setTimeoutAPICheck] = React.useState<boolean>(
        false
    );
    const uuidUser = localStorage.getItem("uuidUser");
    const [
        tcposCorrTakeawayDisplayName,
        setTcposCorrTakeawayDisplayName,
    ] = React.useState<string>("");
    const [tcposCorrTakeawayID, setTcposCorrTakeawayID] = React.useState<
        string
    >("");
    const [
        tcposCorrOnSiteDisplayName,
        setTcposCorrOnSiteDisplayName,
    ] = React.useState<string>("");
    const [tcposCorrOnSiteID, setTcposCorrOnSiteID] = React.useState<string>(
        ""
    );
    const [
        tcposCorrTakeawayDisplayNameCheck,
        setTcposCorrTakeawayDisplayNameCheck,
    ] = React.useState<boolean>(false);
    const [
        tcposCorrTakeawayIDCheck,
        setTcposCorrTakeawayIDCheck,
    ] = React.useState<boolean>(false);
    const [
        tcposCorrOnSiteDisplayNameCheck,
        setTcposCorrOnSiteDisplayNameCheck,
    ] = React.useState<boolean>(false);
    const [tcposCorrOnSiteIDCheck, setTcposCorrOnSiteIDCheck] = React.useState<
        boolean
    >(false);
    function checkIfPosEditorExist() {
        let isPosEditorExist = false;
        if (posEditorData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...posEditorData.data, ...dataArchive];
            allData.forEach((el: any) => {
                if (el[t("Pos Editor")] === choicePosEditor)
                    isPosEditorExist = true;

                return;
            });
        }

        return isPosEditorExist;
    }
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;

        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: shopID,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    function checkIfPosEditorFranchiseExist() {
        let isPosEditorExist = false;
        if (posEditorFranchise.othersData.allData.data !== undefined) {
            posEditorFranchise.othersData.allData.data.forEach((el: any) => {
                if (el[t("Pos Editor")] === choicePosEditor)
                    isPosEditorExist = true;
                return;
            });
        }
        return isPosEditorExist;
    }

    function checkIfPosEditorFranchiseExistAndArchive() {
        let isPosEditorExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (el.posEditor === choicePosEditor) isPosEditorExist = true;
                return;
            });

        return isPosEditorExist;
    }

    async function addPosEditor() {
        let isPosEditorFranchiseExist = checkIfPosEditorFranchiseExist();
        let isPosEditorFranchiseExistAndArchived = checkIfPosEditorFranchiseExistAndArchive();

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, "0"] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            adding: true,
            data: {
                [uuid()]: {
                    posEditor: choicePosEditor,
                    codeComment: codeComment,
                    note: remark,
                    isArchived: false,
                    shopId: shopId,
                    user: user,
                    versionServer: versionServer,
                    keyAuthentication: keyAuthentication,
                    apiEmail: apiEmail,
                    port: port,
                    server: server,
                    apiServer: apiServer,
                    setupId: setupId,
                    apiPassword: apiPassword,
                    modeOfSaleEmpId: modeOfSaleEmpId,
                    modeOfSaleLivId: modeOfSaleLivId,
                    modeOfSaleSPId: modeOfSaleSPId,
                    divisionPriceItems: divisionPriceItems,
                    divisionPriceOptions: divisionPriceOptions,
                    divisionTax: divisionTax,
                    layoutLabel: layoutLabel,
                    apiWebhook: apiWebhook,
                    happyHour: happyHour,
                    happyHourState: happyHourState,
                    tcposStoreId: tcposStoreId,
                    tcposWebhookPort: tcposWebhookPort,
                    tcposWebhookIPAddess: tcposWebhookIPAddess,
                    tcposFranchiseSchema: tcposFranchiseSchema,
                    tcposPaymentCash: tcposPaymentCash,
                    tcposPaymentCreditCard: tcposPaymentCreditCard,
                    tcposSetupDisk: tcposSetupDisk,
                    tcposCodeCountry: tcposCodeCountry,
                    tcposLangue: tcposLangue,
                    tcposApiPort: tcposApiPort,
                    tcposPartnerApiUrl: tcposPartnerApiUrl,
                    tcposIp: tcposIp,
                    tcposConnectionCheckTime: tcposConnectionCheckTime,
                    tcposDeletingDataSate: tcposDeletingDataSate,
                    codeRetryTable: codeRetryTable,
                    maxRetry: maxRetry,
                    retryDelai: retryDelai,
                    checkStock: checkStock,
                    orderValidationPreCheck: orderValidationPreCheck,
                    timeoutAPI: timeoutAPI,
                    automaticProductionLevel: automaticProductionLevel,
                    tcposCorrTakeawayDisplayName: tcposCorrTakeawayDisplayName,
                    tcposCorrTakeawayID: tcposCorrTakeawayID,
                    tcposCorrOnSiteDisplayName: tcposCorrOnSiteDisplayName,
                    tcposCorrOnSiteID: tcposCorrOnSiteID,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (posEditorData.data.length > 0 && oneShop) {
            ErrorToast(
                t("Attention, a shop cannot have more than one Pos Editor")
            );
            setCanValidate(true);
        } else if (isPosEditorFranchiseExistAndArchived && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(true);
            setCanValidate(true);
        } else if (isPosEditorFranchiseExist && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(false);
            setCanValidate(true);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setCanValidate(true);
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            setCanValidate(true);
                            toast.success(
                                `${t(
                                    "The Pos Editor setting has been successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (oneShop) RedisPublishPeripheral();
                            setDataPosEditor([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            setDataIsUpdated!(true);

                            setChoicePosEditor("");

                            clearData();
                            clearMultiCustomSelectionData();
                        })
                );
            } catch (e) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editPosEditor() {
        let dataAssociate: any[] = [];

        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        let dataConfig = {
            userId: userID,
            shopId: [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [dataPosEditor.Id]: {
                    posEditor: choicePosEditor,
                    codeComment: codeComment,
                    note: remark,
                    isArchived: false,
                    shopId: shopId,
                    user: user,
                    versionServer: versionServer,
                    keyAuthentication: keyAuthentication,
                    apiEmail: apiEmail,
                    port: port,
                    server: server,
                    apiServer: apiServer,
                    setupId: setupId,
                    apiPassword: apiPassword,
                    modeOfSaleEmpId: modeOfSaleEmpId,
                    modeOfSaleLivId: modeOfSaleLivId,
                    modeOfSaleSPId: modeOfSaleSPId,
                    divisionPriceItems: divisionPriceItems,
                    divisionPriceOptions: divisionPriceOptions,
                    divisionTax: divisionTax,
                    layoutLabel: layoutLabel,
                    apiWebhook: apiWebhook,
                    happyHour: happyHour,
                    happyHourState: happyHourState,
                    tcposStoreId: tcposStoreId,
                    tcposWebhookPort: tcposWebhookPort,
                    tcposWebhookIPAddess: tcposWebhookIPAddess,
                    tcposFranchiseSchema: tcposFranchiseSchema,
                    tcposPaymentCash: tcposPaymentCash,
                    tcposPaymentCreditCard: tcposPaymentCreditCard,
                    tcposSetupDisk: tcposSetupDisk,
                    tcposCodeCountry: tcposCodeCountry,
                    tcposLangue: tcposLangue,
                    tcposApiPort: tcposApiPort,
                    tcposPartnerApiUrl: tcposPartnerApiUrl,
                    tcposIp: tcposIp,
                    tcposConnectionCheckTime: tcposConnectionCheckTime,
                    tcposDeletingDataSate: tcposDeletingDataSate,
                    codeRetryTable: codeRetryTable,
                    maxRetry: maxRetry,
                    retryDelai: retryDelai,
                    checkStock: checkStock,
                    orderValidationPreCheck: orderValidationPreCheck,
                    timeoutAPI: timeoutAPI,
                    automaticProductionLevel,
                    tcposCorrTakeawayDisplayName: tcposCorrTakeawayDisplayName,
                    tcposCorrTakeawayID: tcposCorrTakeawayID,
                    tcposCorrOnSiteDisplayName: tcposCorrOnSiteDisplayName,
                    tcposCorrOnSiteID: tcposCorrOnSiteID,
                },
            },
        };
        setDataEdited(dataConfig);

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        if (!oneShop) {
            dataAssociate = AssociatePosEditor();
        }
        if (dataAssociate.length > 1) {
            setIsModalOpen(true);
            setCanValidate(true);
        } else {
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Pos Editor setting has been successfully modified"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setCanValidate(true);
                            setDataIsUpdated!(true);
                            setIsAddPosEditorClicked(!isAddPosEditorClicked);
                            setIsEdited(false);
                        })
                );
            } catch (e) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function AssociatePosEditor() {
        let idsAssociated = [0];
        let associated: any[] = [dataPosEditor];
        if (shopsPosEditorData.data !== undefined) {
            shopsPosEditorData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataPosEditor.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    function handleValidate() {
        setIsValidateButtonEnabled(false);

        if (choicePosEditor === "") {
            setSelectChoicePosEditor(true);
        }

        if (dataPosEditor.Id !== undefined) {
            if (
                (dataPosEditor[t("Remark")] === remark &&
                    choicePosEditor !== "TCPOS" &&
                    choicePosEditor !== "Cashpad") ||
                (choicePosEditor === "TCPOS" &&
                    dataPosEditor["tcposWebhookPort"] === tcposWebhookPort &&
                    dataPosEditor["tcposWebhookIPAddess"] ===
                        tcposWebhookIPAddess &&
                    dataPosEditor["tcposPaymentCash"] === tcposPaymentCash &&
                    dataPosEditor["tcposApiPort"] === tcposApiPort &&
                    dataPosEditor["tcposPartnerApiUrl"] ===
                        tcposPartnerApiUrl &&
                    dataPosEditor["tcposPaymentCash"] === tcposPaymentCash &&
                    dataPosEditor["tcposPaymentCreditCard"] ===
                        tcposPaymentCreditCard &&
                    dataPosEditor["tcposIp"] === tcposIp &&
                    dataPosEditor["codeComment"] === codeComment &&
                    dataPosEditor["tcposConnectionCheckTime"] ===
                        tcposConnectionCheckTime &&
                    tcposDeletingDataSate ===
                        dataPosEditor["tcposDeletingDataSate"] &&
                    tcposCorrTakeawayDisplayName ===
                        dataPosEditor["tcposCorrTakeawayDisplayName"] &&
                    tcposCorrTakeawayID ===
                        dataPosEditor["tcposCorrTakeawayID"] &&
                    tcposCorrOnSiteDisplayName ===
                        dataPosEditor["tcposCorrOnSiteDisplayName"] &&
                    tcposCorrOnSiteID === dataPosEditor["tcposCorrOnSiteID"] &&
                    dataPosEditor[t("Remark")] === remark) ||
                (choicePosEditor === "Cashpad" &&
                    dataPosEditor["apiServer"] === apiServer &&
                    dataPosEditor["setupId"] === setupId &&
                    dataPosEditor["apiEmail"] === apiEmail &&
                    dataPosEditor["apiPassword"] === apiPassword &&
                    dataPosEditor["modeOfSaleEmpId"] === modeOfSaleEmpId &&
                    dataPosEditor["modeOfSaleLivId"] === modeOfSaleLivId &&
                    dataPosEditor["modeOfSaleSPId"] === modeOfSaleSPId &&
                    dataPosEditor["divisionPriceItems"] ===
                        divisionPriceItems &&
                    dataPosEditor["divisionPriceOptions"] ===
                        divisionPriceOptions &&
                    dataPosEditor["divisionPriceItems"] ===
                        divisionPriceItems &&
                    dataPosEditor["divisionTax"] === divisionTax &&
                    dataPosEditor["layoutLabel"] === layoutLabel &&
                    dataPosEditor["codeRetryTable"] === codeRetryTable &&
                    dataPosEditor["maxRetry"] === maxRetry &&
                    dataPosEditor["retryDelai"] === retryDelai &&
                    dataPosEditor["checkStock"] === checkStock &&
                    dataPosEditor["orderValidationPreCheck"] ===
                        orderValidationPreCheck &&
                    dataPosEditor["timeoutAPI"] === timeoutAPI &&
                    dataPosEditor[t("Remark")] === remark &&
                    dataPosEditor["happyHour"] === happyHour &&
                    dataPosEditor["happyHourState"] === happyHourState)
            ) {
                if (
                    tcposCorrTakeawayDisplayName === "" ||
                    tcposCorrTakeawayID === "" ||
                    tcposCorrOnSiteDisplayName === "" ||
                    tcposCorrOnSiteID === ""
                ) {
                    if (tcposCorrTakeawayDisplayName.trim() === "") {
                        setTcposCorrTakeawayDisplayNameCheck(true);
                    } else {
                        setTcposCorrTakeawayDisplayNameCheck(false);
                    }
                    if (tcposCorrTakeawayID.trim() === "") {
                        setTcposCorrTakeawayIDCheck(true);
                    } else {
                        setTcposCorrTakeawayIDCheck(false);
                    }
                    if (tcposCorrOnSiteDisplayName.trim() === "") {
                        setTcposCorrOnSiteDisplayNameCheck(true);
                    } else {
                        setTcposCorrOnSiteDisplayNameCheck(false);
                    }
                    if (tcposCorrOnSiteID.trim() === "") {
                        setTcposCorrOnSiteIDCheck(true);
                    } else {
                        setTcposCorrOnSiteIDCheck(false);
                    }
                } else {
                    setIsModalOpened(true);
                }
            } else {
                if (choicePosEditor === "TCPOS") {
                    if (tcposCorrTakeawayDisplayName.trim() === "") {
                        setTcposCorrTakeawayDisplayNameCheck(true);
                    } else {
                        setTcposCorrTakeawayDisplayNameCheck(false);
                    }
                    if (tcposCorrTakeawayID.trim() === "") {
                        setTcposCorrTakeawayIDCheck(true);
                    } else {
                        setTcposCorrTakeawayIDCheck(false);
                    }
                    if (tcposCorrOnSiteDisplayName.trim() === "") {
                        setTcposCorrOnSiteDisplayNameCheck(true);
                    } else {
                        setTcposCorrOnSiteDisplayNameCheck(false);
                    }
                    if (tcposCorrOnSiteID.trim() === "") {
                        setTcposCorrOnSiteIDCheck(true);
                    } else {
                        setTcposCorrOnSiteIDCheck(false);
                    }

                    if (tcposWebhookPort.trim() === "") {
                        setTcposWebhookPortCheck(true);
                    } else {
                        setTcposWebhookPortCheck(false);
                    }
                    if (tcposWebhookIPAddess.trim() === "") {
                        setTcposWebhookIPAddessCheck(true);
                    } else {
                        setTcposWebhookIPAddessCheck(false);
                    }

                    if (tcposPaymentCash.trim() === "") {
                        setTcposPaymentCashCheck(true);
                    } else {
                        setTcposPaymentCashCheck(false);
                    }
                    if (tcposPaymentCreditCard.trim() === "") {
                        setTcposPaymentCreditCardCheck(true);
                    } else {
                        setTcposPaymentCreditCardCheck(false);
                    }
                    if (tcposApiPort.trim() === "") {
                        setTcposApiPortCheck(true);
                    } else {
                        setTcposApiPortCheck(false);
                    }
                    if (tcposIp.trim() === "") {
                        setTcposIpCheck(true);
                    } else {
                        setTcposIpCheck(false);
                    }
                    if (tcposPartnerApiUrl.trim() === "") {
                        setTcposPartnerApiUrlCheck(true);
                    } else {
                        setTcposPartnerApiUrlCheck(false);
                    }
                    if (tcposConnectionCheckTime.trim() === "") {
                        setTcposConnectionCheckTimeCheck(true);
                    } else {
                        setTcposConnectionCheckTimeCheck(false);
                    }
                    if (modeOfSaleEmpId.trim() === "") {
                        setTakeawayCheck(true);
                    } else {
                        setTakeawayCheck(false);
                    }
                    if (modeOfSaleLivId.trim() === "") {
                        setInDeliveryCheck(true);
                    } else {
                        setInDeliveryCheck(false);
                    }
                    if (modeOfSaleSPId.trim() === "") {
                        setDineInCheck(true);
                    } else {
                        setDineInCheck(false);
                    }
                }
                if (choicePosEditor === "Cashpad") {
                    if (apiServer.trim() === "") {
                        setAPIServerCheck(true);
                    } else {
                        setAPIServerCheck(false);
                    }
                    if (setupId.trim() === "") {
                        setSetupIDCheck(true);
                    } else {
                        setSetupIDCheck(false);
                    }
                    if (
                        apiEmail.trim() === "" ||
                        !apiEmail.match(emailMatchWord)
                    ) {
                        setUserMailAPICheck(true);
                    } else {
                        setUserMailAPICheck(false);
                    }
                    if (apiPassword.trim() === "") {
                        setUserPasswordAPICheck(true);
                    } else {
                        setUserPasswordAPICheck(false);
                    }
                    if (modeOfSaleEmpId.trim() === "") {
                        setTakeawayCheck(true);
                    } else {
                        setTakeawayCheck(false);
                    }
                    if (modeOfSaleLivId.trim() === "") {
                        setInDeliveryCheck(true);
                    } else {
                        setInDeliveryCheck(false);
                    }
                    if (modeOfSaleSPId.trim() === "") {
                        setDineInCheck(true);
                    } else {
                        setDineInCheck(false);
                    }
                    if (
                        divisionPriceItems.trim() === "" ||
                        !divisionPriceItems.match(numberPositiveMatch)
                    ) {
                        setProductPriceCheck(true);
                    } else {
                        setProductPriceCheck(false);
                    }
                    if (
                        divisionPriceOptions.trim() === "" ||
                        !divisionPriceOptions.match(numberPositiveMatch)
                    ) {
                        setOptionPriceCheck(true);
                    } else {
                        setOptionPriceCheck(false);
                    }
                    if (
                        divisionTax.trim() === "" ||
                        !divisionTax.match(numberPositiveMatch)
                    ) {
                        setVATRateMatchCheck(true);
                    } else {
                        setVATRateMatchCheck(false);
                    }
                    if (layoutLabel.trim() === "") {
                        setKeyLayoutLabelCheck(true);
                    } else {
                        setKeyLayoutLabelCheck(false);
                    }
                    if (codeRetryTable.length === 0) {
                        setCodeRetryTableCheck(true);
                    } else {
                        setCodeRetryTableCheck(false);
                    }
                    if (
                        maxRetry.trim() === "" ||
                        !maxRetry.match(numberPositiveMatch)
                    ) {
                        setMaxRetryCheck(true);
                    } else {
                        setMaxRetryCheck(false);
                    }
                    if (
                        retryDelai.trim() === "" ||
                        !retryDelai.match(numberPositiveMatch)
                    ) {
                        setRetryDelaiCheck(true);
                    } else {
                        setRetryDelaiCheck(false);
                    }
                    if (
                        timeoutAPI.trim() === "" ||
                        !timeoutAPI.match(numberPositiveMatch)
                    ) {
                        setTimeoutAPICheck(true);
                    } else {
                        setTimeoutAPICheck(false);
                    }
                    if (happyHour.trim() === "") {
                        setHappyHourCheck(false);
                    } else {
                        setHappyHourCheck(false);
                    }
                }
                if (
                    (["", "TCPOS", "Cashpad"].indexOf(choicePosEditor) === -1 &&
                        oneShop === true) ||
                    (choicePosEditor === "TCPOS" &&
                        tcposWebhookPort.trim() !== "" &&
                        tcposWebhookIPAddess.trim() !== "" &&
                        tcposPaymentCash.trim() !== "" &&
                        tcposPaymentCreditCard.trim() !== "" &&
                        tcposApiPort.trim() !== "" &&
                        tcposPartnerApiUrl.trim() !== "" &&
                        tcposIp.trim() !== "" &&
                        tcposConnectionCheckTime.trim() !== "" &&
                        !ipValidCheck &&
                        !ipExistCheck &&
                        tcposCorrOnSiteDisplayName.trim() !== "" &&
                        tcposCorrTakeawayDisplayName.trim() !== "" &&
                        tcposCorrTakeawayID.trim() !== "" &&
                        tcposCorrOnSiteID.trim() !== "" &&
                        oneShop === true) ||
                    (choicePosEditor === "Cashpad" &&
                        apiServer.trim() !== "" &&
                        setupId.trim() !== "" &&
                        apiEmail.trim() !== "" &&
                        apiPassword.trim() !== "" &&
                        modeOfSaleEmpId.trim() !== "" &&
                        modeOfSaleLivId.trim() !== "" &&
                        modeOfSaleSPId.trim() !== "" &&
                        codeRetryTable?.length > 0 &&
                        divisionPriceItems.trim() !== "" &&
                        divisionPriceOptions.trim() !== "" &&
                        divisionTax.trim() !== "" &&
                        layoutLabel.trim() !== "" &&
                        timeoutAPI.trim() !== "" &&
                        emailMatchWord.test(apiEmail) &&
                        numberPositiveMatch.test(divisionPriceOptions) &&
                        numberPositiveMatch.test(divisionPriceItems) &&
                        numberPositiveMatch.test(divisionTax) &&
                        numberPositiveMatch.test(timeoutAPI) &&
                        numberPositiveMatch.test(maxRetry) &&
                        maxRetry.trim() !== "" &&
                        // happyHour.trim() !== "" &&
                        oneShop === true) ||
                    (oneShop === false && choicePosEditor !== "")
                ) {
                    setCanValidate(false);
                    editPosEditor();
                }
            }
        } else if (choicePosEditor !== "" && !isExist) {
            if (choicePosEditor === "TCPOS") {
                if (tcposCorrTakeawayDisplayName.trim() === "") {
                    setTcposCorrTakeawayDisplayNameCheck(true);
                } else {
                    setTcposCorrTakeawayDisplayNameCheck(false);
                }
                if (tcposCorrTakeawayID.trim() === "") {
                    setTcposCorrTakeawayIDCheck(true);
                } else {
                    setTcposCorrTakeawayIDCheck(false);
                }
                if (tcposCorrOnSiteDisplayName.trim() === "") {
                    setTcposCorrOnSiteDisplayNameCheck(true);
                } else {
                    setTcposCorrOnSiteDisplayNameCheck(false);
                }
                if (tcposCorrOnSiteID.trim() === "") {
                    setTcposCorrOnSiteIDCheck(true);
                } else {
                    setTcposCorrOnSiteIDCheck(false);
                }
                if (tcposWebhookPort.trim() === "") {
                    setTcposWebhookPortCheck(true);
                } else {
                    setTcposWebhookPortCheck(false);
                }
                if (tcposWebhookIPAddess.trim() === "") {
                    setTcposWebhookIPAddessCheck(true);
                } else {
                    setTcposWebhookIPAddessCheck(false);
                }
                if (tcposFranchiseSchema.trim() === "") {
                    setTcposFranchiseSchemaCheck(true);
                } else {
                    setTcposFranchiseSchemaCheck(false);
                }
                if (tcposPaymentCash.trim() === "") {
                    setTcposPaymentCashCheck(true);
                } else {
                    setTcposPaymentCashCheck(false);
                }
                if (tcposPaymentCreditCard.trim() === "") {
                    setTcposPaymentCreditCardCheck(true);
                } else {
                    setTcposPaymentCreditCardCheck(false);
                }
                if (tcposApiPort.trim() === "") {
                    setTcposApiPortCheck(true);
                } else {
                    setTcposApiPortCheck(false);
                }
                if (tcposIp.trim() === "") {
                    setTcposIpCheck(true);
                } else {
                    setTcposIpCheck(false);
                }
                if (tcposPartnerApiUrl.trim() === "") {
                    setTcposPartnerApiUrlCheck(true);
                } else {
                    setTcposPartnerApiUrlCheck(false);
                }
                if (tcposConnectionCheckTime.trim() === "") {
                    setTcposConnectionCheckTimeCheck(true);
                } else {
                    setTcposConnectionCheckTimeCheck(false);
                }
            }
            if (choicePosEditor === "Cashpad") {
                if (apiServer.trim() === "") {
                    setAPIServerCheck(true);
                } else {
                    setAPIServerCheck(false);
                }
                if (setupId.trim() === "") {
                    setSetupIDCheck(true);
                } else {
                    setSetupIDCheck(false);
                }
                if (apiEmail.trim() === "" || !apiEmail.match(emailMatchWord)) {
                    setUserMailAPICheck(true);
                } else {
                    setUserMailAPICheck(false);
                }
                if (apiPassword.trim() === "") {
                    setUserPasswordAPICheck(true);
                } else {
                    setUserPasswordAPICheck(false);
                }
                if (modeOfSaleEmpId.trim() === "") {
                    setTakeawayCheck(true);
                } else {
                    setTakeawayCheck(false);
                }
                if (modeOfSaleLivId.trim() === "") {
                    setInDeliveryCheck(true);
                } else {
                    setInDeliveryCheck(false);
                }
                if (modeOfSaleSPId.trim() === "") {
                    setDineInCheck(true);
                } else {
                    setDineInCheck(false);
                }
                if (
                    divisionPriceItems.trim() === "" ||
                    !divisionPriceItems.match(numberPositiveMatch)
                ) {
                    setProductPriceCheck(true);
                } else {
                    setProductPriceCheck(false);
                }
                if (
                    divisionPriceOptions.trim() === "" ||
                    !divisionPriceOptions.match(numberPositiveMatch)
                ) {
                    setOptionPriceCheck(true);
                } else {
                    setOptionPriceCheck(false);
                }
                if (
                    divisionTax.trim() === "" ||
                    !divisionTax.match(numberPositiveMatch)
                ) {
                    setVATRateMatchCheck(true);
                } else {
                    setVATRateMatchCheck(false);
                }
                if (layoutLabel.trim() === "") {
                    setKeyLayoutLabelCheck(true);
                } else {
                    setKeyLayoutLabelCheck(false);
                }
                if (codeRetryTable.length === 0) {
                    setCodeRetryTableCheck(true);
                } else {
                    setCodeRetryTableCheck(false);
                }
                if (
                    maxRetry.trim() === "" ||
                    !maxRetry.match(numberPositiveMatch)
                ) {
                    setMaxRetryCheck(true);
                } else {
                    setMaxRetryCheck(false);
                }
                if (
                    retryDelai.trim() === "" ||
                    !retryDelai.match(numberPositiveMatch)
                ) {
                    setRetryDelaiCheck(true);
                } else {
                    setRetryDelaiCheck(false);
                }
                if (
                    timeoutAPI.trim() === "" ||
                    !timeoutAPI.match(numberPositiveMatch)
                ) {
                    setTimeoutAPICheck(true);
                } else {
                    setTimeoutAPICheck(false);
                }
                if (
                    timeoutAPI.trim() === "" ||
                    !timeoutAPI.match(numberPositiveMatch)
                ) {
                    setTimeoutAPICheck(true);
                } else {
                    setTimeoutAPICheck(false);
                }
                if (happyHour.trim() === "") {
                    setHappyHourCheck(false);
                } else {
                    setHappyHourCheck(false);
                }
            }
            if (
                (["", "TCPOS", "Cashpad"].indexOf(choicePosEditor) === -1 &&
                    oneShop === true) ||
                (choicePosEditor === "TCPOS" &&
                    tcposWebhookPort.trim() !== "" &&
                    tcposWebhookIPAddess.trim() !== "" &&
                    tcposPaymentCash.trim() !== "" &&
                    tcposPaymentCreditCard.trim() !== "" &&
                    tcposApiPort.trim() !== "" &&
                    tcposPartnerApiUrl.trim() !== "" &&
                    tcposIp.trim() !== "" &&
                    tcposConnectionCheckTime.trim() !== "" &&
                    !ipValidCheck &&
                    !ipExistCheck &&
                    tcposCorrOnSiteDisplayName.trim() !== "" &&
                    tcposCorrTakeawayDisplayName.trim() !== "" &&
                    tcposCorrTakeawayID.trim() !== "" &&
                    tcposCorrOnSiteID.trim() !== "" &&
                    oneShop === true) ||
                (choicePosEditor === "Cashpad" &&
                    apiServer.trim() !== "" &&
                    setupId.trim() !== "" &&
                    apiEmail.trim() !== "" &&
                    apiPassword.trim() !== "" &&
                    codeRetryTable?.length > 0 &&
                    divisionPriceItems.trim() !== "" &&
                    divisionPriceOptions.trim() !== "" &&
                    emailMatchWord.test(apiEmail) &&
                    numberPositiveMatch.test(divisionPriceOptions) &&
                    numberPositiveMatch.test(divisionPriceItems) &&
                    numberPositiveMatch.test(divisionTax) &&
                    numberPositiveMatch.test(timeoutAPI) &&
                    numberPositiveMatch.test(maxRetry) &&
                    layoutLabel.trim() !== "" &&
                    timeoutAPI.trim() !== "" &&
                    maxRetry.trim() !== "" &&
                    // happyHour.trim() !== "" &&
                    oneShop === true) ||
                (oneShop === false && choicePosEditor !== "")
            ) {
                setCanValidate(false);

                addPosEditor();
            }
        }
    }

    useEffect(() => {
        if (dataPosEditor.Id !== undefined) {
            setTitle(t(isConsult ? "View Pos Editor" : "Modify Pos Editor"));
            setChoicePosEditor(dataPosEditor[t("Pos Editor")]);
            setRemark(dataPosEditor[t("Remark")]);
            setServer(dataPosEditor?.server);
            setCodeComment(dataPosEditor?.codeComment);
            setSetupId(dataPosEditor?.setupId);
            setApiPassword(dataPosEditor?.apiPassword);
            setModeOfSaleEmpId(dataPosEditor?.modeOfSaleEmpId);
            setModeOfSaleLivId(dataPosEditor?.modeOfSaleLivId);
            setModeOfSaleSPId(dataPosEditor?.modeOfSaleSPId);
            setDivisionPriceItems(dataPosEditor?.divisionPriceItems);
            setDivisionPriceOptions(dataPosEditor?.divisionPriceOptions);
            setDivisionTax(dataPosEditor?.divisionTax);
            setLayoutLabel(dataPosEditor?.layoutLabel);
            setApiWebhook(dataPosEditor?.apiWebhook);
            setApiEmail(dataPosEditor?.apiEmail);
            setKeyAuthentication(dataPosEditor?.keyAuthentication);
            setVersionServer(dataPosEditor?.versionServer);
            setApiServer(dataPosEditor?.apiServer);
            setUser(dataPosEditor?.user);
            setPort(dataPosEditor?.port);
            setTcposStoreId(dataPosEditor?.tcposStoreId);
            setTcposWebhookPort(dataPosEditor?.tcposWebhookPort);
            setTcposWebhookIPAddess(dataPosEditor?.tcposWebhookIPAddess);
            setTcposFranchiseSchema(dataPosEditor?.tcposFranchiseSchema);
            setTcposPaymentCash(dataPosEditor?.tcposPaymentCash);
            setTcposSetupDisk(dataPosEditor?.tcposSetupDisk);
            setTcposCodeCountry(dataPosEditor?.tcposCodeCountry);
            setTcposLangue(dataPosEditor?.tcposLangue);
            setTcposApiPort(dataPosEditor?.tcposApiPort);
            setTcposPartnerApiUrl(dataPosEditor?.tcposPartnerApiUrl);
            setTcposIp(dataPosEditor?.tcposIp);
            setTcposConnectionCheckTime(
                dataPosEditor?.tcposConnectionCheckTime
            );
            setTcposDeletingDataSate(dataPosEditor?.tcposDeletingDataSate);

            setCodeRetryTable(dataPosEditor?.codeRetryTable);
            setMaxRetry(dataPosEditor?.maxRetry);
            setRetryDelai(dataPosEditor?.retryDelai);
            setCheckStock(dataPosEditor?.checkStock);
            setHappyHourState(dataPosEditor?.happyHourState);
            setHappyHour(dataPosEditor?.happyHour);
            setOrderValidationPreCheck(dataPosEditor?.orderValidationPreCheck);
            setTimeoutAPI(dataPosEditor?.timeoutAPI);
            setAutomaticProductionLevel(
                dataPosEditor?.automaticProductionLevel
            );
            setTcposPaymentCreditCard(dataPosEditor?.tcposPaymentCreditCard);
            setTcposCorrTakeawayDisplayName(
                dataPosEditor?.tcposCorrTakeawayDisplayName
            );
            setTcposCorrTakeawayID(dataPosEditor?.tcposCorrTakeawayID);
            setTcposCorrOnSiteDisplayName(
                dataPosEditor?.tcposCorrOnSiteDisplayName
            );
            setTcposCorrOnSiteID(dataPosEditor?.tcposCorrOnSiteID);
        } else {
            setTitle(t("Add Pos Editor"));
            setChoicePosEditor("");
            setSelectChoicePosEditor(false);
            setChoicePosEditor("");
            setRemark("");
            setServer("");
            setSetupId("");
            setApiPassword("KRnyKX_ZgqwquqyLt7pG");
            setModeOfSaleEmpId("");
            setModeOfSaleLivId("");
            setModeOfSaleSPId("");
            setDivisionPriceItems("1000");
            setDivisionPriceOptions("1000");
            setDivisionTax("10");
            setLayoutLabel("");
            setApiWebhook("");
            setApiEmail("aures@cashpad.fr");
            setKeyAuthentication("");
            setVersionServer("");
            setRemark("");
            setApiServer("");
            setPort("");
            setTcposStoreId("");
            setTcposWebhookPort("8181");
            setTcposWebhookIPAddess("127.0.0.1");
            setTcposFranchiseSchema("");
            setTcposPaymentCash("1");
            setTcposPaymentCreditCard("3");
            setTcposSetupDisk("");
            setTcposCodeCountry("");
            setTcposLangue("");
            setTcposApiPort("8080");
            setTcposPartnerApiUrl("SelfCheckout");
            setTcposIp("127.0.0.1");
            setTcposDeletingDataSate(false);
            setTcposConnectionCheckTime("1000");
            setCodeComment("");

            setTcposWebhookPortCheck(false);
            setTcposWebhookIPAddessCheck(false);
            setTcposFranchiseSchemaCheck(false);
            setTcposPaymentCashCheck(false);
            setTcposPaymentCreditCardCheck(false);

            setTcposApiPortCheck(false);
            setTcposPartnerApiUrlCheck(false);
            setTcposIpCheck(false);
            setTcposConnectionCheckTimeCheck(false);
            setIpValidCheck(false);
            setIpExistCheck(false);

            setCodeRetryTableCheck(false);
            setMaxRetryCheck(false);
            setRetryDelaiCheck(false);
            setTimeoutAPICheck(false);
            setHappyHourCheck(false);
            setHappyHour("Happy hour- LDF");
            setHappyHourState(false);
            setCodeRetryTable(["302"]);
            setMaxRetry("3");
            setRetryDelai("10");
            setCheckStock(false);
            setOrderValidationPreCheck(false);
            setTimeoutAPI("40");
            setAutomaticProductionLevel(true);

            setTcposCorrTakeawayDisplayName("");
            setTcposCorrTakeawayID("");
            setTcposCorrOnSiteDisplayName("");
            setTcposCorrOnSiteID("");
            setTcposCorrTakeawayIDCheck(false);
            setTcposCorrOnSiteDisplayNameCheck(false);
            setTcposCorrOnSiteIDCheck(false);
            setTcposCorrTakeawayDisplayNameCheck(false);
        }

        setAssociated(AssociatePosEditor);

        // eslint-disable-next-line
    }, [
        dataPosEditor,
        t,
        isAddNewPosEditorButtonClicked,
        isAddPosEditorClicked,
    ]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddPosEditorClicked, dataPosEditor.Id]);
    useEffect(() => {
        if (codeRetryTable.length === 0) {
            setCodeRetryTableCheck(true);
        } else {
            setCodeRetryTableCheck(false);
        }
    }, [codeRetryTable]);

    useEffect(() => {
        let isPosEditorExist = checkIfPosEditorExist();
        if (
            isPosEditorExist &&
            (dataPosEditor.Id === undefined ||
                (dataPosEditor.Id !== undefined &&
                    dataPosEditor[t("Pos Editor")] !== choicePosEditor))
        ) {
            setIsExist(true);
        } else {
            setIsExist(false);
        }
        //eslint-disable-next-line
    }, [choicePosEditor, t, dataPosEditor]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames("pt-3", {
                            fed_txt_FRtitleFormLoyalty:
                                dataPosEditor.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="fed_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataPosEditor([]);
                                    setIsAddPosEditorClicked!(
                                        !isAddPosEditorClicked
                                    );
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataPosEditor([]);
                                setIsAddPosEditorClicked!(
                                    !isAddPosEditorClicked
                                );
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: oneShop ? "512px" : "462px",
                        overflowY: "scroll",
                    }}
                >
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="choicePosEditor"
                        >
                            {t("Pos Editor")}
                        </StyledLabel>

                        <StyledSelectInput
                            value={
                                choicePosEditor === ""
                                    ? null
                                    : {
                                          label: choicePosEditor,
                                          value: choicePosEditor,
                                      }
                            }
                            className={classnames("fed_inp_FRposEditorType", {
                                invalid__clz: selectChoicePosEditor || isExist,
                                "not-allowed-posEditor__clz":
                                    dataPosEditor.Id !== undefined,
                            })}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setChoicePosEditor(e.value);
                                setSelectChoicePosEditor(false);
                            }}
                            options={[
                                {
                                    label: t("Cashpad"),
                                    value: t("Cashpad"),
                                },
                                {
                                    label: t("Cesar"),
                                    value: t("Cesar"),
                                },
                                {
                                    label: t("TCPOS"),
                                    value: t("TCPOS"),
                                },
                                {
                                    label: t("Crisalid"),
                                    value: t("Crisalid"),
                                },
                                {
                                    label: t("Zelty"),
                                    value: t("Zelty"),
                                },
                                {
                                    label: t("Cegid"),
                                    value: t("Cegid"),
                                },
                                {
                                    label: t("Merim"),
                                    value: t("Merim"),
                                },
                                {
                                    label: t("Synapsy"),
                                    value: t("Synapsy"),
                                },
                            ]}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a posEditor choice"
                                    )!}`,
                                },
                            }}
                            placeholder={t("Select")}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                            autoFocus={dataPosEditor.Id === undefined}
                        />
                        {selectChoicePosEditor ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a Pos Editor")}
                            </div>
                        ) : null}
                        {isExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The Pos Editor already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    {choicePosEditor === "Cashpad" && oneShop && (
                        <div>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("API Server")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="apiServer"
                                    name="apiServer"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setApiServer(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setAPIServerCheck(true);
                                        } else {
                                            setAPIServerCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: APIServerCheck,
                                    })}
                                    value={apiServer}
                                />
                            </AvGroup>
                            {APIServerCheck ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please write a API Server")}
                                </div>
                            ) : null}
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Setup ID")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="setupId"
                                    name="setupId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setSetupId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setSetupIDCheck(true);
                                        } else {
                                            setSetupIDCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: setupIDCheck,
                                    })}
                                    value={setupId}
                                />
                            </AvGroup>
                            {setupIDCheck ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please write a Setup ID")}
                                </div>
                            ) : null}
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("User mail API")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="apiEmail"
                                    name="apiEmail"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setApiEmail(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value.match(
                                                emailMatchWord
                                            )
                                        ) {
                                            setUserMailAPICheck(true);
                                        } else {
                                            setUserMailAPICheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: userMailAPICheck,
                                    })}
                                    value={apiEmail}
                                />
                                {userMailAPICheck ||
                                !apiEmail.match(emailMatchWord) ? (
                                    !apiEmail.match(emailMatchWord) &&
                                    apiEmail.trim() !== "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("User mail API is invalid")}
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please write a User mail API")}
                                        </div>
                                    )
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("User Password API")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="apiPassword"
                                    name="apiPassword"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setApiPassword(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setUserPasswordAPICheck(true);
                                        } else {
                                            setUserPasswordAPICheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: userPasswordAPICheck,
                                    })}
                                    value={apiPassword}
                                />
                                {userPasswordAPICheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a User Password API")}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Key Layout Label")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="layoutLabel"
                                    name="layoutLabel"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setLayoutLabel(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setKeyLayoutLabelCheck(true);
                                        } else {
                                            setKeyLayoutLabelCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: keyLayoutLabelCheck,
                                    })}
                                    value={layoutLabel}
                                />
                                {keyLayoutLabelCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a Key Layout Label")}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("API re-execution code")}
                                </StyledLabel>

                                <MultiCustomSelect
                                    onDelete={(e: any) => {
                                        setIsEdited(true);
                                        if (codeRetryTable.length === 0) {
                                            setCodeRetryTableCheck(true);
                                        } else {
                                            setCodeRetryTableCheck(false);
                                        }
                                    }}
                                    result={codeRetryTable}
                                    setResult={setCodeRetryTable}
                                    data={["302"]}
                                    onChange={(e: any) => {
                                        setIsEdited(true);

                                        if (codeRetryTable.length === 0) {
                                            setCodeRetryTableCheck(true);
                                        } else {
                                            setCodeRetryTableCheck(false);
                                        }
                                    }}
                                    className={classnames("dev_inp_FRcountry", {
                                        invalid__clz: codeRetryTableCheck,
                                    })}
                                    placeholder={`${t("Select")}…`}
                                    value={t("Choose")}
                                    name="country"
                                />

                                {codeRetryTableCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please select a API re-execution code"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Maximum API re-execution attempts")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="maxRetry"
                                    name="maxRetry"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setMaxRetry(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setMaxRetryCheck(true);
                                        } else {
                                            setMaxRetryCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: maxRetryCheck,
                                    })}
                                    value={maxRetry}
                                />

                                {maxRetryCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a maximum API re-execution attempts"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Time out API")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="timeoutAPI"
                                    name="timeoutAPI"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setTimeoutAPI(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setTimeoutAPICheck(true);
                                        } else {
                                            setTimeoutAPICheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: timeoutAPICheck,
                                    })}
                                    value={timeoutAPI}
                                />

                                {timeoutAPICheck ? (
                                    timeoutAPI.trim() === "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please write a timeout api")}
                                        </div>
                                    ) : !timeoutAPI
                                          .trim()
                                          .match(numberPositiveMatch) ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Timeout api is invalid")}
                                        </div>
                                    ) : null
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Time delay between API executions")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="retryDelai"
                                    name="retryDelai"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setRetryDelai(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setRetryDelaiCheck(true);
                                        } else {
                                            setRetryDelaiCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: retryDelaiCheck,
                                    })}
                                    value={retryDelai}
                                />

                                {retryDelaiCheck ? (
                                    retryDelai.trim() === "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a time delay between API executions"
                                            )}
                                        </div>
                                    ) : !retryDelai
                                          .trim()
                                          .match(numberPositiveMatch) ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Time delay between API executions is invalid"
                                            )}
                                        </div>
                                    ) : null
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Stock verification")}
                                </StyledLabel>

                                <div className="float-right mt-3">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={checkStock}
                                        onChange={async () => {
                                            setCheckStock(!checkStock);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={` pointer__clz  ${classnames()}`}
                                    />
                                </div>
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Order validation pre-check")}
                                </StyledLabel>

                                <div className="float-right mt-3">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={orderValidationPreCheck}
                                        onChange={async () => {
                                            setOrderValidationPreCheck(
                                                !orderValidationPreCheck
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={` pointer__clz  ${classnames()}`}
                                    />
                                </div>
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Automatic Production level")}
                                </StyledLabel>

                                <div className="float-right mt-3">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={automaticProductionLevel}
                                        onChange={async () => {
                                            setAutomaticProductionLevel(
                                                !automaticProductionLevel
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={` pointer__clz  ${classnames()}`}
                                    />
                                </div>
                            </AvGroup>
                            <hr />
                            <StyledLabel className="" htmlFor="name">
                                {t("Correspondence")}
                            </StyledLabel>

                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode Take away")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleEmpId"
                                    name="modeOfSaleEmpId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleEmpId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setTakeawayCheck(true);
                                        } else {
                                            setTakeawayCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: takeawayCheck,
                                    })}
                                    value={modeOfSaleEmpId}
                                />
                                {takeawayCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Sale mode Take away"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode In delivery")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleLivId"
                                    name="modeOfSaleLivId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleLivId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setInDeliveryCheck(true);
                                        } else {
                                            setInDeliveryCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: inDeliveryCheck,
                                    })}
                                    value={modeOfSaleLivId}
                                />
                                {inDeliveryCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Sale mode In delivery"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode Dine-in")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleSPId"
                                    name="modeOfSaleSPId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleSPId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setDineInCheck(true);
                                        } else {
                                            setDineInCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: dineInCheck,
                                    })}
                                    value={modeOfSaleSPId}
                                />
                                {dineInCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a Sale mode Dine-in")}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Product price for 1€")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="divisionPriceItems"
                                    name="divisionPriceItems"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setDivisionPriceItems(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setProductPriceCheck(true);
                                        } else {
                                            setProductPriceCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: productPriceCheck,
                                    })}
                                    value={divisionPriceItems}
                                />

                                {productPriceCheck ? (
                                    divisionPriceItems.trim() === "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Product price for 1€"
                                            )}
                                        </div>
                                    ) : !divisionPriceItems
                                          .trim()
                                          .match(numberPositiveMatch) ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Product price for 1€ is invalid"
                                            )}
                                        </div>
                                    ) : null
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Option price for 1€")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="divisionPriceOptions"
                                    name="divisionPriceOptions"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setDivisionPriceOptions(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setOptionPriceCheck(true);
                                        } else {
                                            setOptionPriceCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: optionPriceCheck,
                                    })}
                                    value={divisionPriceOptions}
                                />
                                {optionPriceCheck ? (
                                    divisionPriceOptions.trim() === "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Option price for 1€"
                                            )}
                                        </div>
                                    ) : !divisionPriceOptions
                                          .trim()
                                          .match(numberPositiveMatch) ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Option price for 1€ is invalid"
                                            )}
                                        </div>
                                    ) : null
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("VAT rate")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="divisionTax"
                                    name="divisionTax"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setDivisionTax(e.target.value);
                                        setIsEdited(true);
                                        if (
                                            e.target.value.trim() === "" ||
                                            !e.target.value
                                                .trim()
                                                .match(numberPositiveMatch)
                                        ) {
                                            setVATRateMatchCheck(true);
                                        } else {
                                            setVATRateMatchCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: VATRateMatchCheck,
                                    })}
                                    value={divisionTax}
                                />

                                {VATRateMatchCheck ? (
                                    divisionTax.trim() === "" ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please write a VAT rate")}
                                        </div>
                                    ) : !divisionTax
                                          .trim()
                                          .match(numberPositiveMatch) ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("VAT rate match is invalid")}
                                        </div>
                                    ) : null
                                ) : null}
                            </AvGroup>
                            <AvGroup className="d-none">
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Webhook API")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="apiWebhook"
                                    name="apiWebhook"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setApiWebhook(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={apiWebhook}
                                />
                            </AvGroup>

                            <hr
                                className={`d-none ${
                                    isConsult ? "pe-none__clz" : ""
                                }`}
                            />
                            <AvGroup
                                className={`d-none ${
                                    isConsult ? "pe-none__clz" : ""
                                }`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Happy Hour")}
                                </StyledLabel>

                                <div className="float-right mt-3">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={happyHourState}
                                        onChange={async () => {
                                            setHappyHourState(!happyHourState);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={` pointer__clz  ${classnames()}`}
                                    />
                                </div>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="happyHour"
                                    name="happyHour"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setHappyHour(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setHappyHourCheck(false);
                                        } else {
                                            setHappyHourCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: happyHourCheck,
                                    })}
                                    value={happyHour}
                                />
                                {happyHourCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a Happy Hour")}
                                    </div>
                                ) : null}
                            </AvGroup>
                        </div>
                    )}
                    {choicePosEditor === "TCPOS" && oneShop && (
                        <div>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("IP Address")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposIp"
                                    name="tcposIp"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setTcposIp(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setTcposIpCheck(true);
                                        } else {
                                            setTcposIpCheck(false);
                                            if (
                                                !e.target.value.match(
                                                    ipMatchWord
                                                )
                                            ) {
                                                setIpValidCheck(true);
                                            } else {
                                                setIpValidCheck(false);
                                            }
                                            if (
                                                resourceIp

                                                    .filter((x: any) => {
                                                        if (
                                                            x.shopId === shopID
                                                        ) {
                                                            return x.ip;
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                    .map((x: any) => {
                                                        return x.ip;
                                                    })
                                                    .indexOf(e.target.value) !==
                                                -1
                                            ) {
                                                setIpExistCheck(true);
                                            } else {
                                                setIpExistCheck(false);
                                            }
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz:
                                            tcposIpCheck ||
                                            ipExistCheck ||
                                            ipValidCheck,
                                    })}
                                    value={tcposIp}
                                />
                                {tcposIpCheck ||
                                ipExistCheck ||
                                ipValidCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            tcposIpCheck
                                                ? "Please write a IP address"
                                                : ipValidCheck
                                                ? "IP address is invalid"
                                                : ipExistCheck
                                                ? "The ip address is already exists"
                                                : ""
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Port API")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposApiPort"
                                    name="tcposApiPort"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setTcposApiPortCheck(true);
                                        } else {
                                            setTcposApiPort(e.target.value);
                                            setTcposApiPortCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposApiPortCheck,
                                    })}
                                    value={tcposApiPort}
                                />
                                {tcposApiPortCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a port api")}
                                    </div>
                                ) : null}
                            </AvGroup>

                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Payment Method: Cash")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposPaymentCash"
                                    name="tcposPaymentCash"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposPaymentCash(e.target.value);
                                        if (e.target.value.trim() === "") {
                                            setTcposPaymentCashCheck(true);
                                        } else {
                                            setTcposPaymentCashCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposPaymentCashCheck,
                                    })}
                                    value={tcposPaymentCash}
                                />
                                {tcposPaymentCashCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Payment Method: Cash"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Payment Method: Credit Card")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposPaymentCreditCard"
                                    name="tcposPaymentCreditCard"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposPaymentCreditCard(
                                            e.target.value
                                        );
                                        if (e.target.value.trim() === "") {
                                            setTcposPaymentCreditCardCheck(
                                                true
                                            );
                                        } else {
                                            setTcposPaymentCreditCardCheck(
                                                false
                                            );
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposPaymentCreditCardCheck,
                                    })}
                                    value={tcposPaymentCreditCard}
                                />
                                {tcposPaymentCreditCardCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Payment Method: Credit Card"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>

                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("API URL Suffix")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposPartnerApiUrl"
                                    name="tcposPartnerApiUrl"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposPartnerApiUrl(e.target.value);
                                        if (e.target.value.trim() === "") {
                                            setTcposPartnerApiUrlCheck(true);
                                        } else {
                                            setTcposPartnerApiUrlCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposPartnerApiUrlCheck,
                                    })}
                                    value={tcposPartnerApiUrl}
                                />
                                {tcposPartnerApiUrlCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a API URL Suffix")}
                                    </div>
                                ) : null}
                            </AvGroup>

                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Webhook IP Address")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="tcposWebhookIPAddess"
                                    name="tcposWebhookIPAddess"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposWebhookIPAddess(e.target.value);
                                        if (e.target.value.trim() === "") {
                                            setTcposWebhookIPAddessCheck(true);
                                        } else {
                                            setTcposWebhookIPAddessCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposWebhookIPAddessCheck,
                                    })}
                                    value={tcposWebhookIPAddess}
                                />
                                {tcposWebhookIPAddessCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a webhook ip address")}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Webhook port")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="Server"
                                    name="Server"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposWebhookPort(e.target.value);
                                        if (e.target.value.trim() === "") {
                                            setTcposWebhookPortCheck(true);
                                        } else {
                                            setTcposWebhookPortCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposWebhookPortCheck,
                                    })}
                                    value={tcposWebhookPort}
                                />
                                {tcposWebhookPortCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a webhook port")}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup>
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode : Take away")}
                                </StyledLabel>

                                <div className="row">
                                    <div className="col-6">
                                        <AvGroup
                                            className={`${
                                                isConsult ? "pe-none__clz" : ""
                                            }`}
                                        >
                                            <StyledLabel
                                                className="mt-3 required__clz"
                                                htmlFor="name"
                                            >
                                                {t("Display name")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                autocomplete="off"
                                                id="apiPassword"
                                                name="apiPassword"
                                                placeholder={t("Write")}
                                                type="text"
                                                onChange={(e: any) => {
                                                    setTcposCorrTakeawayDisplayName(
                                                        e.target.value
                                                    );
                                                    setIsEdited(true);
                                                    if (
                                                        e.target.value.trim() ===
                                                        ""
                                                    ) {
                                                        setTcposCorrTakeawayDisplayNameCheck(
                                                            true
                                                        );
                                                    } else {
                                                        setTcposCorrTakeawayDisplayNameCheck(
                                                            false
                                                        );
                                                    }
                                                }}
                                                className={classnames("", {
                                                    "not-allowed-icon__clz": isConsultModalOpened,
                                                    input__clz: tcposCorrTakeawayDisplayNameCheck,
                                                })}
                                                value={
                                                    tcposCorrTakeawayDisplayName
                                                }
                                            />
                                            {tcposCorrTakeawayDisplayNameCheck ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t(
                                                        "Please write a Sale mode Take away display name"
                                                    )}
                                                </div>
                                            ) : null}
                                        </AvGroup>
                                    </div>
                                    <div className="col-6">
                                        <AvGroup
                                            className={`${
                                                isConsult ? "pe-none__clz" : ""
                                            }`}
                                        >
                                            <StyledLabel
                                                className="mt-3 required__clz"
                                                htmlFor="name"
                                            >
                                                {t("ID")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                autocomplete="off"
                                                id="apiPassword"
                                                name="apiPassword"
                                                placeholder={t("Write")}
                                                type="text"
                                                onChange={(e: any) => {
                                                    setTcposCorrTakeawayID(
                                                        e.target.value
                                                    );
                                                    setIsEdited(true);
                                                    if (
                                                        e.target.value.trim() ===
                                                        ""
                                                    ) {
                                                        setTcposCorrTakeawayIDCheck(
                                                            true
                                                        );
                                                    } else {
                                                        setTcposCorrTakeawayIDCheck(
                                                            false
                                                        );
                                                    }
                                                }}
                                                className={classnames("", {
                                                    "not-allowed-icon__clz": isConsultModalOpened,
                                                    input__clz: tcposCorrTakeawayIDCheck,
                                                })}
                                                value={tcposCorrTakeawayID}
                                            />
                                            {tcposCorrTakeawayIDCheck ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t(
                                                        "Please write a Sale mode Take away ID"
                                                    )}
                                                </div>
                                            ) : null}
                                        </AvGroup>
                                    </div>
                                </div>
                            </AvGroup>
                            <AvGroup>
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode : on-site")}
                                </StyledLabel>

                                <div className="row">
                                    <div className="col-6">
                                        <AvGroup
                                            className={`${
                                                isConsult ? "pe-none__clz" : ""
                                            }`}
                                        >
                                            <StyledLabel
                                                className="mt-3 required__clz"
                                                htmlFor="name"
                                            >
                                                {t("Display name")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                autocomplete="off"
                                                id="apiPassword"
                                                name="apiPassword"
                                                placeholder={t("Write")}
                                                type="text"
                                                onChange={(e: any) => {
                                                    setTcposCorrOnSiteDisplayName(
                                                        e.target.value
                                                    );
                                                    setIsEdited(true);
                                                    if (
                                                        e.target.value.trim() ===
                                                        ""
                                                    ) {
                                                        setTcposCorrOnSiteDisplayNameCheck(
                                                            true
                                                        );
                                                    } else {
                                                        setTcposCorrOnSiteDisplayNameCheck(
                                                            false
                                                        );
                                                    }
                                                }}
                                                className={classnames("", {
                                                    "not-allowed-icon__clz": isConsultModalOpened,
                                                    input__clz: tcposCorrOnSiteDisplayNameCheck,
                                                })}
                                                value={
                                                    tcposCorrOnSiteDisplayName
                                                }
                                            />
                                            {tcposCorrOnSiteDisplayNameCheck ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t(
                                                        "Please write a sale mode on-site display name"
                                                    )}
                                                </div>
                                            ) : null}
                                        </AvGroup>
                                    </div>
                                    <div className="col-6">
                                        <AvGroup
                                            className={`${
                                                isConsult ? "pe-none__clz" : ""
                                            }`}
                                        >
                                            <StyledLabel
                                                className="mt-3 required__clz"
                                                htmlFor="name"
                                            >
                                                {t("ID")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                autocomplete="off"
                                                id="apiPassword"
                                                name="apiPassword"
                                                placeholder={t("Write")}
                                                type="text"
                                                onChange={(e: any) => {
                                                    setTcposCorrOnSiteID(
                                                        e.target.value
                                                    );
                                                    setIsEdited(true);
                                                    if (
                                                        e.target.value.trim() ===
                                                        ""
                                                    ) {
                                                        setTcposCorrOnSiteIDCheck(
                                                            true
                                                        );
                                                    } else {
                                                        setTcposCorrOnSiteIDCheck(
                                                            false
                                                        );
                                                    }
                                                }}
                                                className={classnames("", {
                                                    "not-allowed-icon__clz": isConsultModalOpened,
                                                    input__clz: tcposCorrOnSiteIDCheck,
                                                })}
                                                value={tcposCorrOnSiteID}
                                            />
                                            {tcposCorrOnSiteIDCheck ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t(
                                                        "Please write a sale mode on-site ID"
                                                    )}
                                                </div>
                                            ) : null}
                                        </AvGroup>
                                    </div>
                                </div>
                            </AvGroup>
                            {/* <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode : Takeaway testtt2")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleEmpId"
                                    name="modeOfSaleEmpId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleEmpId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setTakeawayCheck(true);
                                        } else {
                                            setTakeawayCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: takeawayCheck,
                                    })}
                                    value={modeOfSaleEmpId}
                                />
                                {takeawayCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Sale mode : Takeaway"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t(
                                        "Sale mode : In delivery"
                                    )}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleLivId"
                                    name="modeOfSaleLivId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleLivId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setInDeliveryCheck(true);
                                        } else {
                                            setInDeliveryCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: inDeliveryCheck,
                                    })}
                                    value={modeOfSaleLivId}
                                />
                                {inDeliveryCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Sale mode : In delivery"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Sale mode : Dine-in")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="modeOfSaleSPId"
                                    name="modeOfSaleSPId"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setModeOfSaleSPId(e.target.value);
                                        setIsEdited(true);
                                        if (e.target.value.trim() === "") {
                                            setDineInCheck(true);
                                        } else {
                                            setDineInCheck(false);
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: dineInCheck,
                                    })}
                                    value={modeOfSaleSPId}
                                />
                                {dineInCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a Sale mode : Dine-in"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup> */}
                            <AvGroup
                                className={` d-none ${
                                    isConsult ? "pe-none__clz" : ""
                                }`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t(
                                        "The TCPOS update overwritres existing data"
                                    )}
                                </StyledLabel>
                                <div className="float-right mt-3">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={tcposDeletingDataSate}
                                        onChange={async () => {
                                            setTcposDeletingDataSate(
                                                !tcposDeletingDataSate
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={` pointer__clz  ${classnames()}`}
                                    />
                                </div>
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel
                                    className="mt-3 required__clz"
                                    htmlFor="name"
                                >
                                    {t("Connection check time")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="Server"
                                    name="Server"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setTcposConnectionCheckTime(
                                            e.target.value
                                        );
                                        if (e.target.value.trim() === "") {
                                            setTcposConnectionCheckTimeCheck(
                                                true
                                            );
                                        } else {
                                            setTcposConnectionCheckTimeCheck(
                                                false
                                            );
                                        }
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                        input__clz: tcposConnectionCheckTimeCheck,
                                    })}
                                    value={tcposConnectionCheckTime}
                                />
                                {tcposConnectionCheckTimeCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t(
                                            "Please write a connection check time"
                                        )}
                                    </div>
                                ) : null}
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3 " htmlFor="name">
                                    {t("Code comment")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="Codecomment"
                                    name="Codecomment"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        setCodeComment(e.target.value);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={codeComment}
                                />
                            </AvGroup>
                        </div>
                    )}
                    {choicePosEditor === "Crisalid" && oneShop && (
                        <div>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3" htmlFor="name">
                                    {t("Port")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="port"
                                    name="port"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setPort(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={port}
                                />
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3" htmlFor="name">
                                    {t("Server")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="Server"
                                    name="Server"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setServer(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={server}
                                />
                            </AvGroup>
                        </div>
                    )}
                    {choicePosEditor === "Zelty" && oneShop && (
                        <div>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3" htmlFor="name">
                                    {t("User")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="user"
                                    name="user"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setUser(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={user}
                                />
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3" htmlFor="name">
                                    {t("Server version")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="versionServer"
                                    name="versionServer"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setVersionServer(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={versionServer}
                                />
                            </AvGroup>
                            <AvGroup
                                className={`${isConsult ? "pe-none__clz" : ""}`}
                            >
                                <StyledLabel className="mt-3" htmlFor="name">
                                    {t(
                                        "Authentication key (Bearer authentication)"
                                    )}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="keyAuthentication"
                                    name="keyAuthentication"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setKeyAuthentication(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    className={classnames("", {
                                        "not-allowed-icon__clz": isConsultModalOpened,
                                    })}
                                    value={keyAuthentication}
                                />
                            </AvGroup>
                        </div>
                    )}
                    <hr />
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("fed_inp_FRremark", {
                                "not-allowed-posEditor__clz": isConsultModalOpened,
                            })}
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setIsEdited(true);
                                if (isConsultModalOpened === false) {
                                    setRemark(e.target.value);
                                    setIsEdited(true);
                                }
                            }}
                            value={remark}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 fed_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddPosEditorClicked(
                                        !isAddPosEditorClicked
                                    );
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setDataPosEditor([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddPosEditorClicked(
                                    !isAddPosEditorClicked
                                );
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataPosEditor([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`fed_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        disabled={!canValidate}
                        onClick={() => {
                            handleValidate();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddPosEditorClicked}
                setIsAddClicked={setIsAddPosEditorClicked}
                setEditedData={setDataPosEditor}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataPosEditor={dataEdited}
                    associated={associated}
                    setIsAddFiscalYearClicked={setIsAddPosEditorClicked}
                    isAddFiscalYearClicked={isAddPosEditorClicked}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        choicePosEditor: choicePosEditor,
                    }}
                    PosData={{
                        posEditor: choicePosEditor,
                        codeComment: codeComment,
                        note: remark,
                        isArchived: false,
                        shopId: shopId,
                        user: user,
                        versionServer: versionServer,
                        keyAuthentication: keyAuthentication,
                        apiEmail: apiEmail,
                        port: port,
                        server: server,
                        apiServer: apiServer,
                        setupId: setupId,
                        apiPassword: apiPassword,
                        modeOfSaleEmpId: modeOfSaleEmpId,
                        modeOfSaleLivId: modeOfSaleLivId,
                        modeOfSaleSPId: modeOfSaleSPId,
                        divisionPriceItems: divisionPriceItems,
                        divisionPriceOptions: divisionPriceOptions,
                        divisionTax: divisionTax,
                        layoutLabel: layoutLabel,
                        apiWebhook: apiWebhook,
                        happyHour: happyHour,
                        happyHourState: happyHourState,
                        tcposStoreId: tcposStoreId,
                        tcposWebhookPort: tcposWebhookPort,
                        tcposWebhookIPAddess: tcposWebhookIPAddess,
                        tcposFranchiseSchema: tcposFranchiseSchema,
                        tcposPaymentCash: tcposPaymentCash,
                        tcposPaymentCreditCard: tcposPaymentCreditCard,
                        tcposSetupDisk: tcposSetupDisk,
                        tcposCodeCountry: tcposCodeCountry,
                        tcposLangue: tcposLangue,
                        tcposApiPort: tcposApiPort,
                        tcposPartnerApiUrl: tcposPartnerApiUrl,
                        tcposIp: tcposIp,
                        tcposConnectionCheckTime: tcposConnectionCheckTime,
                        tcposDeletingDataSate: tcposDeletingDataSate,
                        codeRetryTable: codeRetryTable,
                        maxRetry: maxRetry,
                        retryDelai: retryDelai,
                        checkStock: checkStock,
                        orderValidationPreCheck: orderValidationPreCheck,
                        timeoutAPI: timeoutAPI,
                        automaticProductionLevel: automaticProductionLevel,
                        tcposCorrTakeawayDisplayName: tcposCorrTakeawayDisplayName,
                        tcposCorrTakeawayID: tcposCorrTakeawayID,
                        tcposCorrOnSiteDisplayName: tcposCorrOnSiteDisplayName,
                        tcposCorrOnSiteID: tcposCorrOnSiteID,
                    }}
                    setChoicePosEditor={setChoicePosEditor}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}
