import React from "react";
import classnames from "classnames";
import { EyeIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import store, {
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setSelectedApplicationKds,
} from "./store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddApplication,
    setIsAddApplication,
    isApplicationArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActive,
}: any) {
    const { t } = useTranslation();
    const { modify, isEdited, isActionsClicked } = useSnapshot(store);
    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow["Application"] !== "" ? (
                <div className="d-flex justify-content-between mt-2">
                    {modify === true && (
                        <div>
                            <PencilIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows([e.selectedRow]);
                                            if (!isAddApplication) {
                                                setIsAddApplication(
                                                    !isAddApplication
                                                );
                                            }
                                        });
                                    } else {
                                        setSelectedRows([e.selectedRow]);
                                        if (!isAddApplication) {
                                            setIsAddApplication(
                                                !isAddApplication
                                            );
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_edit",
                                    {
                                        "not-allowed-icon__clz": isApplicationArchived,
                                    }
                                )}`}
                            />
                        </div>
                    )}
                    {modify === true && (
                        <div>
                            {!isApplicationArchived ? (
                                <TrashIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsAddApplication(false);
                                                setIsEdited(false);
                                                if (
                                                    e.selectedRow.subRows
                                                        .length > 0
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Please note that you cannot archive a application associated with an device."
                                                        )
                                                    );
                                                } else {
                                                    setIsConfirmationModalArchived(
                                                        true
                                                    );
                                                }

                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            });
                                        } else {
                                            setIsAddApplication(false);
                                            if (
                                                e.selectedRow.subRows.length > 0
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note that you cannot archive a application associated with an device."
                                                    )
                                                );
                                            } else {
                                                setIsConfirmationModalArchived(
                                                    true
                                                );
                                            }

                                            setSelectedRows([e.selectedRow]);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    fill="red"
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz ml-1 cmn_icn_archived"
                                    )}`}
                                />
                            ) : (
                                <EyeIcon
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsConfirmationModalActive(
                                                    true
                                                );
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            });
                                        } else {
                                            setIsConfirmationModalActive(true);
                                            setSelectedRows([e.selectedRow]);
                                        }
                                    }}
                                    className={`${classnames(
                                        "pointer__clz ml-1 cmn_icn_unarchive",
                                        {}
                                    )}`}
                                />
                            )}
                        </div>
                    )}
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setSelectedApplicationKds([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.appId,
                                            },
                                        ]);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );

                                        setSelectedRows([e.selectedRow]);
                                    });
                                } else {
                                    setSelectedApplicationKds([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.appId,
                                        },
                                    ]);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );

                                    setSelectedRows([e.selectedRow]);
                                }
                            }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_history",
                                "ml-2"
                            )}`}
                        />
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
