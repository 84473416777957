import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    AvForm,
    AvRadioGroup,
    AvRadio,
    AvGroup,
} from "availity-reactstrap-validation";

import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../components/VerticalLayout/store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import {
    store as pathStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "./store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelectV2/store";

import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";
import { checkIpIfExist, getUniqueId } from "@helpers/general";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import ErrorToast from "@components/Common/ErrorTost";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import PrintersPathsModal from "./PrintersPathsModal";
import {
    ipMatchWord,
    numberMatchWord,
    numberMatchWordWithNullInLeft,
    SPEEDS,
} from "../../../constants";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type PrintersType = {
    setIsAddPrinterClicked: Function;
    setDataIsUpdated: Function;
    isAddPrinterClicked: boolean;
    editedData: any;
    setEditedData: Function;
    isAddNewPrinterButtonClicked: boolean;
    shopIdPrinter: string;
    localShopId?: any;
    inputDesignation: boolean;
    setInputDesignation: Function;
    inputIp: boolean;
    setInputIp: Function;
    dataIsUpdated?: boolean | number;
    dataTable?: any;
    setIsUpdateOperation: Function;
    isConsult: boolean;
    setIsConsult: Function;
    isComingFromKiosk?: boolean;
};

export default function PrintersConfig({
    setIsAddPrinterClicked,
    isAddPrinterClicked,
    setDataIsUpdated,
    editedData,
    setEditedData,
    isAddNewPrinterButtonClicked,
    shopIdPrinter,
    localShopId,
    inputDesignation,
    setInputDesignation,
    inputIp,
    setInputIp,
    dataIsUpdated,
    dataTable,
    setIsUpdateOperation,
    isConsult,
    setIsConsult,
    isComingFromKiosk,
}: PrintersType) {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        resourceIp,
        operatorID,
    } = useSnapshot(store);
    const {
        selected,
        path,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(pathStore);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    let ips: string[] = [];
    const localNamesPrinters: string[] = [];
    const pathname = window.location.pathname.toString();

    const [printerType, setPrinterType] = useState<string>(
        editedData.type !== undefined ? editedData.type : "ip"
    );
    const [selectedShopPrinterCodes, setSelectedShopPrinterCodes] = useState<
        any
    >([]);
    const [printerPath, setPrinterPath] = useState<string>("");
    const [designation, setDesignation] = useState<string>("");
    const [mark, setMark] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [speed, setSpeed] = useState<string | number>(19200);
    const [ip, setIp] = useState<string>("");
    const [, setPortNum] = useState<string>(
        printerType === "series" ? "COM 2" : "LPT 2"
    );
    const [fontSize, setFontSize] = useState<string | number>(10);
    const [ticketWidth, setTicketWidth] = useState<string | number>(40);
    const [ipPortNumber, setIpPortNumber] = useState<string>("7000");
    const [printerCode, setPrinterCode] = useState<any>(1);
    const [portSeries, setPortSeries] = useState<string>("COM 2");
    const [tickets, setTickets] = React.useState<any>(null);
    const [ticketsList, setTicketsList] = React.useState<any[]>([]);
    const [inputTicket, setInputTicket] = React.useState<boolean>(false);
    const [isWaiting, setIsWaiting] = React.useState<boolean>(false);
    const [
        isPrintersPathsModalOpened,
        setIsPrintersPathsModalOpened,
    ] = useState<boolean>(false);
    const [exist, setExist] = useState({
        isNameExist: false,
        isIpExist: false,
    });
    const [emptyIp, setEmptyIp] = React.useState<boolean>(false);
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [codeIsExist, setCodeIsExist] = React.useState<boolean>(false);
    const [isCodeValid, setIsCodeValid] = useState(true);

    const isUsbPrinter = printerType === "usb";
    const isIpPrinter = printerType === "ip";
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const uuidUser = localStorage.getItem("uuidUser");

    React.useEffect(() => {
        fetchDataIp_Name();
        uniqueDesignationIp(designation, "designation");

        // eslint-disable-next-line
    }, [dataIsUpdated, localShopId, designation]);

    const fetchDataIp_Name = () => {
        let printerData: any = [];
        let data: any = [
            ...dataTable.data,
            ...dataTable.othersData.dataArchived,
        ];

        if (data !== undefined) {
            if (dataTable !== undefined && data.length > 0) {
                if (data[0][t("Shop")] !== undefined) {
                    if (localShopId.length > 0) {
                        localShopId.forEach((element: any) => {
                            const matchingRows = data.filter((row: any) => {
                                return row.shopId === element;
                            });
                            const subRowsArray = matchingRows.map(
                                (matchingRow: any) => matchingRow?.subRows
                            );
                            printerData = [...printerData, ...subRowsArray];
                        });
                    }
                } else if (data[0][t("Shop")] === undefined) {
                    printerData = data;
                }
            }
        }

        printerData.flat().forEach((datum: any) => {
            localNamesPrinters.push(
                datum[t("Printer name")]?.toUpperCase().trim()
            );
        });

        ips = resourceIp
            // eslint-disable-next-line array-callback-return
            .filter((x: any) => {
                if (x.shopId === localShopId[0] && x.type !== "printer") {
                    return x.ip;
                }
            })
            .map((x: any) => {
                return x.ip;
            });
    };
    const [salesModes, setSalesModes] = React.useState<any>([]);

    const fetchModeOfSale = React.useCallback(() => {
        const languageHeader = "en-US";
        const headers = new Headers({
            "Accept-Language": languageHeader,
        });
        const requestOptions = {
            method: "GET",
            headers: headers,
        };

        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,
            requestOptions
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });
                let rowDataSales: any[] = [];

                let salesSupportMode: any[] = [];
                if (
                    editedData["customer_ticket"]?.customerTicketSalesModes !==
                    undefined
                ) {
                    for (let element of editedData["customer_ticket"]
                        ?.customerTicketSalesModes) {
                        rowDataSales.push(element);
                    }
                }
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                let sales: any[] = [];
                let salesModes: any[] = [];
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            editedData["customer_ticket"]
                                ?.customerTicketSalesModes === undefined ||
                            editedData["customer_ticket"]
                                ?.customerTicketSalesModes.length === 0
                        ) {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    editedData["customer_ticket"]
                                        ?.customerTicketSalesModes ===
                                        undefined ||
                                    editedData["customer_ticket"]
                                        ?.customerTicketSalesModes.length === 0
                                ) {
                                    elt !== undefined && sales.push(elt);
                                }
                            });
                        } else {
                            for (let element of editedData["customer_ticket"]
                                ?.customerTicketSalesModes) {
                                salesModes.push(element.designation);
                                sales.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    sales.push(elt);
                                }
                            });
                        }
                    });
                sales = [...new Set(sales)];
                for (let sale of rowDataSales) {
                    sales = sales.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                setSalesModes(sales);
            });
    }, [franchiseID, shopID, userID, editedData]);

    React.useEffect(() => {
        if (isAddPrinterClicked) fetchModeOfSale();
    }, [fetchModeOfSale, isAddPrinterClicked]);

    const fontSizeArray: {
        label: number;
        value: number;
    }[] = [...Array(16).keys()].map((e: any, index: number) => {
        return {
            label: index + 1,
            value: index + 1,
        };
    });

    const ticketWidthArray: {
        label: number;
        value: number;
    }[] = [...Array(7).keys()].map((e: any, index: number) => {
        return {
            label: index + 34,
            value: index + 34,
        };
    });

    const portNumArray: {
        label: string;
        value: string;
    }[] = [...Array(32).keys()].map((e: any, index: number) => {
        if (printerType === "series")
            return {
                label: `COM ${index + 1}`,
                value: `COM ${index + 1}`,
            };
        return { label: `LPT ${index + 1}`, value: `LPT ${index + 1}` };
    });

    const printerSpeedArray: {
        label: number;
        value: number;
    }[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });

    function uniqueDesignationIp(data: any, type: string) {
        switch (type) {
            case "designation":
                if (
                    (localNamesPrinters.includes(data.toUpperCase().trim()) &&
                        editedData.Id === undefined) ||
                    (localNamesPrinters.includes(data.toUpperCase().trim()) &&
                        editedData.Id !== undefined &&
                        editedData[t("Printer name")].toUpperCase().trim() !==
                            data.toUpperCase())
                ) {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: true,
                    }));
                } else {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: false,
                    }));
                }
                break;
            case "ip":
                if (
                    (ips.includes(data) && editedData.Id === undefined) ||
                    (ips.includes(data) &&
                        editedData.Id !== undefined &&
                        editedData[t(`IP/port`)] !== data)
                ) {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isIpExist: true,
                    }));
                } else {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isIpExist: false,
                    }));
                }
                break;
        }
    }
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        let ids =
            localShopId.length !== 0
                ? localShopId
                : oneShop
                ? [shopID.toString()]
                : [shopIdPrinter.toString()];
        ids.forEach(async (shop: any) => {
            try {
                mutate(
                    redisApiUrl,
                    await fetch(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: shop,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        });
    }

    async function handleEditButtonClickEvent() {
        toast.dismiss();
        resetAllPeripherals();
        clearMultiCustomSelectionData();
        if (canValidate) {
            setCanValidate(false);
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            userId = userID;
            shopId = oneShop ? shopID.toString() : shopIdPrinter.toString();
            franchiseId = franchiseID;

            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                editedData["Id"],
                shopId,
                franchiseID,
                userID,
                appId
            );

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;
            let data = [
                { label: "Customer", value: "01" },
                { label: "Production", value: "02" },
                {
                    label: "Sticker",
                    value: "03",
                },
            ];

            let dataPrint;

            if (
                editedData["tickets"].value === "01" &&
                (tickets.value === "02" || tickets.value === "03")
            ) {
                dataPrint = {
                    Kitchen_ticket: {
                        note_kitchen_ticket: {
                            print_note: true,
                        },
                        ticketKitchenModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: true,
                            };
                        }),
                        customerAddressModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customer_information_mode_sale: {
                            customerInformationModeSaleName: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleNumTel: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCity: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleAdress: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCode: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCompany: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                        },
                    },
                    customer_ticket: {
                        customerTicketSalesModes: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),

                        dateTimeDeliveryModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        print_date_time_delivery_mode_sale: {
                            provider_name: false,
                            without_price: false,
                            print_notes: false,
                            items_lines: false,
                            method_payment: false,
                            method_payment_TVA: false,
                        },
                    },
                };
            }
            if (
                (editedData["tickets"].value === "02" ||
                    editedData["tickets"].value === "03") &&
                tickets.value === "01"
            ) {
                dataPrint = {
                    Kitchen_ticket: {
                        note_kitchen_ticket: {
                            print_note: false,
                        },
                        ticketKitchenModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customerAddressModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        customer_information_mode_sale: {
                            customerInformationModeSaleName: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleNumTel: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCity: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleAdress: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCode: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                            customerInformationModeSaleCompany: salesModes.map(
                                (el: any) => {
                                    return {
                                        designation: el.designation,
                                        state: false,
                                    };
                                }
                            ),
                        },
                    },
                    customer_ticket: {
                        customerTicketSalesModes: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: true,
                            };
                        }),

                        dateTimeDeliveryModeSale: salesModes.map((el: any) => {
                            return {
                                designation: el.designation,
                                state: false,
                            };
                        }),
                        print_date_time_delivery_mode_sale: {
                            provider_name: false,
                            without_price: true,
                            print_notes: true,
                            items_lines: false,
                            method_payment: false,
                            method_payment_TVA: false,
                        },
                    },
                };
            }

            let originTickets = data.filter(
                (el: any) => tickets.value === el.value
            );

            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData["Id"],
                data: [
                    {
                        id: editedData["Id"],
                        designation: designation,
                        mark: mark,
                        note: remark,
                        shopId: shopId,
                        type: printerType,
                        speed: speed,
                        path: printerPath,
                        ip: printerType !== "ip" ? "" : ip,
                        port: ipPortNumber,
                        font_size: fontSize,
                        edit_date: new Date(),
                        add_date: "",
                        ticket_width: ticketWidth,
                        port_series: portSeries,
                        isArchived: false,
                        code: printerCode,
                        tickets: originTickets[0],
                        Kitchen_ticket:
                            tickets.value === editedData["tickets"].value
                                ? editedData.Kitchen_ticket
                                : dataPrint?.Kitchen_ticket,
                        customer_ticket:
                            tickets.value === editedData["tickets"].value
                                ? editedData.customer_ticket
                                : dataPrint?.customer_ticket,
                        modelKitchenTicket:
                            tickets.value === editedData["tickets"].value
                                ? editedData.modelKitchenTicket
                                : editedData.modelKitchenTicket,
                        general:
                            tickets.value === editedData["tickets"].value
                                ? editedData.general
                                : editedData.general,
                        divers:
                            tickets.value === editedData["tickets"].value
                                ? editedData.divers
                                : editedData.divers,
                    },
                ],
            };

            setIsWaiting(true);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setCanValidate(true);
                                throw Error("Error!");
                            }
                            setCanValidate(true);
                            toast.success(
                                `${t("The device is successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (
                                ifPeripheralAssociateObject.isPeripheralAssociate
                            )
                                setTimeout(RedisPublishPeripheral, 3000);
                            setIsAddPrinterClicked(!isAddPrinterClicked);
                            setDataIsUpdated(true);
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                            setTickets({ label: t("Customer"), value: "01" });
                            setIsWaiting(false);
                        })
                );
            } catch (e: any) {
                setCanValidate(true);
                setIsWaiting(false);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function IsExistPeripheral(data: any, des: string) {
        let res: boolean = true;
        for (let index = 0; index < data.length; index++) {
            const element = data[index][t("Printer name")]?.toString();
            if (element.toUpperCase() === des.toUpperCase()) {
                res = false;
            }
        }
        return res;
    }
    async function handleValidateButtonClickEvent() {
        let hasIpConflict: number = 0;
        toast.dismiss();
        resetAllPeripherals();
        clearMultiCustomSelectionData();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer`;
        let userId: number;
        let franchiseId: string;
        if (oneShop) {
            localShopId = [shopID.toString()];
        }

        if (localShopId.length === 0) {
            ErrorToast(`${t("Attention ! you must select a store.")!}`);
        } else if (localShopId.length >= 1) {
            setIsWaiting(true);
            if (canValidate) {
                setCanValidate(false);
                try {
                    let action: boolean = false;
                    for (let index = 0; index < localShopId.length; index++) {
                        const element = localShopId[index];
                        userId = userID;
                        franchiseId = franchiseID;
                        let data = [
                            { label: "Customer", value: "01" },
                            { label: "Production", value: "02" },
                            {
                                label: "Sticker",
                                value: "03",
                            },
                        ];

                        let originTickets = data.filter(
                            (el: any) => tickets.value === el.value
                        );
                        let savedData = {
                            userId: userId,
                            shopId: element,
                            franchiseId: franchiseId,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: [
                                {
                                    id: getUniqueId(10),
                                    designation: designation.trim(),
                                    mark: mark,
                                    note: remark,
                                    shopId: element,
                                    type: printerType,
                                    speed: printerType !== "ip" ? speed : "",
                                    path: printerPath,
                                    ip: ip,
                                    port: ipPortNumber,
                                    font_size: fontSize,
                                    edit_date: new Date(),
                                    add_date: new Date(),
                                    ticket_width: ticketWidth,
                                    port_series: portSeries,
                                    isArchived: false,
                                    code: printerCode,
                                    tickets: originTickets[0],
                                    Kitchen_ticket: {
                                        note_kitchen_ticket: {
                                            print_note:
                                                originTickets[0].value === "02"
                                                    ? true
                                                    : false,
                                        },
                                        ticketKitchenModeSale: salesModes.map(
                                            (el: any) => {
                                                return {
                                                    designation: el.designation,
                                                    state:
                                                        originTickets[0]
                                                            .value === "02"
                                                            ? true
                                                            : false,
                                                };
                                            }
                                        ),
                                        customerAddressModeSale: salesModes.map(
                                            (el: any) => {
                                                return {
                                                    designation: el.designation,
                                                    state: false,
                                                };
                                            }
                                        ),
                                        customer_information_mode_sale: {
                                            customerInformationModeSaleName: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                            customerInformationModeSaleNumTel: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                            customerInformationModeSaleCity: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                            customerInformationModeSaleAdress: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                            customerInformationModeSaleCode: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                            customerInformationModeSaleCompany: salesModes.map(
                                                (el: any) => {
                                                    return {
                                                        designation:
                                                            el.designation,
                                                        state: false,
                                                    };
                                                }
                                            ),
                                        },
                                    },
                                    customer_ticket: {
                                        customerTicketSalesModes: salesModes.map(
                                            (el: any) => {
                                                return {
                                                    designation: el.designation,
                                                    state:
                                                        originTickets[0]
                                                            .value === "01"
                                                            ? true
                                                            : false,
                                                };
                                            }
                                        ),

                                        dateTimeDeliveryModeSale: salesModes.map(
                                            (el: any) => {
                                                return {
                                                    designation: el.designation,
                                                    state: false,
                                                };
                                            }
                                        ),
                                        print_date_time_delivery_mode_sale: {
                                            provider_name: false,
                                            without_price:
                                                originTickets[0].value === "01"
                                                    ? true
                                                    : false,
                                            print_notes:
                                                originTickets[0].value === "01"
                                                    ? true
                                                    : false,
                                            items_lines: false,
                                            method_payment: false,
                                            method_payment_TVA: false,
                                        },
                                    },
                                    modelKitchenTicket: {
                                        model_1: {
                                            black_background: true,
                                            isChecked: true,
                                            smaller_font: true,
                                        },
                                        model_2: {
                                            isChecked: false,
                                            menu_component: false,
                                            name_menu: false,
                                            online_order: false,
                                            single_label: false,
                                        },
                                        model_3: {
                                            Activate_manual_dispatch_follow: false,
                                            dispatch_automatically_follow: false,
                                            follow_kitchen_ticket: false,
                                            font_small: false,
                                            isChecked: false,
                                            order_kitchen: false,
                                            Send_changes_kitchen: false,
                                            ticket_additions_cancellations: false,
                                        },
                                        model_4: {
                                            isChecked: false,
                                        },
                                        model_5: {
                                            isChecked: false,
                                        },
                                    },
                                    general: {
                                        automatic_printing: {
                                            NumberCustomer: 1,
                                            NumberTicketsKitchen: 1,
                                        },
                                        information_mode: {
                                            information_bold: false,
                                            printer_information_mode: false,
                                        },
                                        ticket_header: {
                                            logo_customer: false,
                                            logo_kitchen: false,
                                            name_phone_number_bold: false,
                                            Path_customer: "",
                                            Path_kitchen: "",
                                            sale_mode_black_backround: false,
                                            sale_mode_bold: true,
                                            ticketHeaderStyleOrderNumber:false
                                        },
                                    },
                                    divers: {
                                        Code: {
                                            barcode_ticket: "",
                                            checked_barcode_ticket: false,
                                            checked_QR_code: false,
                                            QR_code: "",
                                        },
                                        paperless_ticket: {
                                            paperless_ticket_custom: true,
                                        },
                                        printing_options: {
                                            force_paper: false,
                                            special_characters: false,
                                        },
                                    },
                                },
                            ],
                        };

                        let filteredData = JSON.parse(
                            JSON.stringify(resourceIp)
                        )
                            ?.filter(
                                (item: any) =>
                                    item?.shopId === element &&
                                    item?.type !== "printer" &&
                                    item.ip.trim() !== ""
                            )
                            .map((y: any) => {
                                return y.ip;
                            });
                        filteredData = [...new Set(filteredData)];

                        let dataSearch: any = [];
                        if (dataTable.data.length > 0) {
                            dataSearch =
                                oneShop ||
                                dataTable.data[0][t("Shop")] === undefined
                                    ? dataTable.data
                                    : dataTable.data.find((x: any) => {
                                          return x.shopId === element;
                                      }).subRows;
                        }
                        if (filteredData.includes(ip)) {
                            hasIpConflict++;
                        }

                        if (IsExistPeripheral(dataSearch, designation.trim())) {
                            if (!filteredData.includes(ip)) {
                                action = true;
                                mutate(
                                    apiUrl,
                                    await fetch(apiUrl, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bareer ${localStorage.getItem(
                                                "jwt"
                                            )}`,
                                        },
                                        body: JSON.stringify(savedData),
                                        method: "POST",
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (data.error) {
                                                throw Error(
                                                    "Error while saving selection!"
                                                );
                                            }
                                        })
                                );
                            }
                        }
                    }
                    if (action === true) {
                        toast.success(
                            `${t("The device is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                    if (hasIpConflict > 0) {
                        ErrorToast(
                            t(
                                "Please note, the device could not be added to one or more stores. Please check the IP address settings."
                            )
                        );
                    }
                    setTickets({ label: t("Customer"), value: "01" });
                    setDataIsUpdated(true);
                    setEditedData([]);
                    setIsEdited(false);
                    activateCheckbox();
                    setCanValidate(true);
                    setIsWaiting(false);
                } catch (e: any) {
                    setCanValidate(true);
                    setIsWaiting(false);
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    React.useEffect(() => {
        oneShop || isComingFromKiosk !== undefined
            ? setSelectedShopPrinterCodes(
                  dataTable?.data?.map((el: any) => el.code) || []
              )
            : setSelectedShopPrinterCodes(
                  dataTable.data
                      .filter((el: any) => {
                          return localShopId.includes(el.shopId);
                      })
                      .map((element: any) => element.subRows)
                      .reduce(function (acc: any, curr: any) {
                          return acc.concat(curr);
                      }, [])
                      .map((el: any) => el.code)
              );

        setTicketsList([
            { label: t("Customer"), value: "01" },
            { label: t("Production"), value: "02" },
            {
                label: t("Sticker"),
                value: "03",
            },
        ]);
    }, [localShopId, dataTable.data, isComingFromKiosk, oneShop, t]);

    useEffect(() => {
        if (editedData.length !== 0) {
            setPrinterType(editedData.type);
            setDesignation(
                editedData[t(`Printer name`)]
                    ? editedData[t(`Printer name`)]
                    : ""
            );
            setPrinterPath(editedData.path);
            setIp(editedData.type === "ip" ? editedData[t(`IP/port`)] : "");
            setPortNum(
                editedData.type === "series"
                    ? editedData["port"]
                    : editedData.type !== "ip"
                    ? editedData[t(`IP/port`)]
                    : printerType === "series"
                    ? "COM 2"
                    : "LPT 2"
            );

            setSpeed(editedData["speed"] !== "" ? editedData["speed"] : 19200);
            setFontSize(editedData[t(`Font size`)]);
            setTicketWidth(editedData["ticket_width"]);
            setMark(editedData.Marque);
            setRemark(editedData.Remarque);
            setPortSeries(editedData.port_series);
            setIpPortNumber(editedData["port"]);
            setInputDesignation(false);
            setInputTicket(false);
            setInputIp(false);
            setEmptyIp(false);

            setTickets(
                editedData.tickets === undefined ||
                    editedData.tickets === "" ||
                    editedData.tickets.length === 0
                    ? null
                    : {
                          label: t(editedData.tickets.label),
                          value: editedData.tickets.value,
                      }
            );
        } else {
            setPrinterType("ip");
            setDesignation("");
            setSpeed(19200);
            setMark("");
            setIp("");
            setRemark("");
            setFontSize(9);
            setPrinterPath("");
            setTicketWidth(40);
            setPortNum(printerType === "series" ? "COM 2" : "LPT 2");
            setIpPortNumber("7000");
            setPortSeries("COM 2");
            setInputDesignation(false);
            setInputTicket(false);
            setInputIp(false);
            setEmptyIp(false);
            setTickets({ label: t("Customer"), value: "01" });

            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: false,
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, isAddNewPrinterButtonClicked, t]);

    useEffect(() => {
        if (editedData.length === 0) {
            setPrinterCode(
                selectedShopPrinterCodes.length > 0
                    ? Math.max(...selectedShopPrinterCodes) + 1
                    : 1
            );
        }
        // eslint-disable-next-line
    }, [selectedShopPrinterCodes.length]);

    useEffect(() => {
        if (editedData?.peripheralId !== undefined) {
            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: checkIpIfExist(
                    resourceIp,
                    ip,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId,
                    "printer"
                ),
            }));
        } else if (oneShop) {
            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: checkIpIfExist(
                    resourceIp,
                    ip,
                    "",
                    shopID,
                    "printer"
                ),
            }));
        }

        let isExistIp =
            resourceIp
                // eslint-disable-next-line
                ?.filter((device) => {
                    if (editedData.Id !== undefined) {
                        if (
                            device.shopId === editedData.shopId &&
                            device.type !== "printer" &&
                            device.id !== editedData.Id
                        ) {
                            return device;
                        }
                    } else {
                        if (
                            device.shopId === localShopId[0] &&
                            device.type !== "printer" &&
                            localShopId.length === 1
                        ) {
                            return device;
                        }
                    }
                })
                ?.map((device) => device.ip)
                ?.includes(ip) || false;

        setExist((prevState: any) => ({
            ...prevState,
            isIpExist: isExistIp,
        }));
        if (ip?.trim() === "" && printerType === "ip") {
            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: false,
            }));
        }
        // eslint-disable-next-line
    }, [editedData, ip, localShopId]);
    useEffect(() => {
        if (editedData.length !== 0) {
            setPrinterCode(editedData.code !== undefined ? editedData.code : 0);
        }
    }, [editedData]);
    useEffect(() => {
        if (printerType !== "ip") {
            setEmptyIp(false);
        }
        if (printerType === "ip" && isEdited === false) {
            setEmptyIp(false);
            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: false,
            }));
        }
        if (ip.trim() === "") {
            setExist((prevState: any) => ({
                ...prevState,
                isIpExist: false,
            }));
        }
        // 1032;
        // eslint-disable-next-line
    }, [printerType, editedData, ip]);

    useEffect(() => {
        if (editedData.length !== 0 && editedData?.Id !== undefined) {
            setIsUpdateOperation(true);
        } else {
            setIsUpdateOperation(false);
        }

        if (editedData.length === 0) {
            (oneShop &&
                selectedShopPrinterCodes
                    .map((el: any) => Number(el))
                    .includes(Number(printerCode))) ||
            (!oneShop &&
                selectedShopPrinterCodes
                    .map((el: any) => Number(el))
                    .includes(Number(printerCode)))
                ? setCodeIsExist(true)
                : setCodeIsExist(false);
        } else {
            let localSelectedShopPrinterCodes =
                oneShop || isComingFromKiosk
                    ? dataTable.data
                          .map((el: any) => el.code)
                          .filter(
                              (elem: any) =>
                                  Number(elem) !== Number(editedData.code)
                          )
                    : dataTable.data
                          .filter((el: any) => {
                              return editedData.shopId === el.shopId;
                          })
                          .map((element: any) => element.subRows)
                          .reduce(function (acc: any, curr: any) {
                              return acc.concat(curr);
                          }, [])
                          .map((el: any) => el.code)
                          .filter((elem: any) => elem !== editedData.code);
            localSelectedShopPrinterCodes
                .map((el: any) => Number(el))
                .includes(Number(printerCode))
                ? setCodeIsExist(true)
                : setCodeIsExist(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, localShopId, printerCode, selectedShopPrinterCodes]);

    useEffect(() => {
        if (editedData.length !== 0) {
            setPortNum(
                editedData.type === "series"
                    ? editedData["port"]
                    : editedData.type !== "ip"
                    ? editedData[t(`IP/port`)]
                    : printerType === "series"
                    ? "COM 2"
                    : "LPT 2"
            );
            setSpeed(editedData["speed"] !== "" ? editedData["speed"] : 19200);

            setIpPortNumber(editedData["port"]);
        } else {
            setSpeed(19200);
            setPortNum(printerType === "series" ? "COM 2" : "LPT 2");
            setIpPortNumber("7000");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printerType]);

    useEffect(() => {
        if (path !== "" && selected !== "") {
            setPrinterPath(selected);
            setIsEdited(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, selected]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 imp_txt_addPrinterDevice">
                        {isConsult === true
                            ? t("View peripheral: printer")
                            : editedData.length === 0
                            ? t("Add device: printer")
                            : t("Modify a device: printer")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="imp_icn_closeForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    activateCheckbox();
                                    setEditedData([]);
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsConsult(false);
                                    resetAllPeripherals();
                                    clearMultiCustomSelectionData();
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                activateCheckbox();
                                setEditedData([]);
                                setIsAddPrinterClicked!(!isAddPrinterClicked);
                                setIsConsult(false);
                                setIsEdited(false);
                                resetAllPeripherals();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/printers"
                                ? "500px"
                                : "522px",
                        overflowY: "auto",
                    }}
                >
                    <StyledLabel>{t("Type of configuration")}</StyledLabel>
                    <AvRadioGroup
                        name="locationType"
                        required
                        inline
                        className={classnames("form-check-label__clz", {
                            readOnly__clz: isConsult,
                        })}
                        value={printerType}
                    >
                        <AvRadio
                            onChange={() => {
                                setPrinterType("ip");
                                setIsEdited(true);
                            }}
                            className="ml-4 imp_icn_ip"
                            label={<StyledLabel>{t("IP")}</StyledLabel>}
                            name="type"
                            value="ip"
                        />
                        <AvRadio
                            onChange={() => {
                                setPrinterType("series");
                                setIsEdited(true);
                            }}
                            id="series"
                            className="ml-4 mt-0 imp_icn_series"
                            label={<StyledLabel>{t("Series")}</StyledLabel>}
                            name="type"
                            value="series"
                        />
                        <AvRadio
                            className="ml-4 imp_icn_usb"
                            onChange={() => {
                                setPrinterType("usb");
                                setIsEdited(true);
                            }}
                            label={<StyledLabel>{t("USB")}</StyledLabel>}
                            name="type"
                            value="usb"
                            id="usb"
                        />
                    </AvRadioGroup>
                    <AvGroup style={{ width: "100%" }}>
                        <StyledLabel
                            htmlFor="name"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>

                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("imp_inp_printerName", {
                                input__clz:
                                    inputDesignation ||
                                    exist.isNameExist ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                                readOnly__clz: isConsult,
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                                uniqueDesignationIp(
                                    e.target.value,
                                    "designation"
                                );
                            }}
                            value={designation}
                            autoFocus={isConsult === true ? false : true}
                        />
                        {inputDesignation ||
                        (designation.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {exist.isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    {printerType === "usb" ? (
                        <div className="justify-content-between d-flex mt-3">
                            <AvGroup style={{ width: "100%" }}>
                                <StyledLabel htmlFor="path" className=" ">
                                    {t("Name of printer")}
                                </StyledLabel>
                                <StyledTextInput
                                    className={classnames(
                                        "imp_inp_selectPrinter",
                                        {
                                            readOnly__clz: isConsult,
                                        }
                                    )}
                                    style={{ width: "100%" }}
                                    id="path"
                                    name="path"
                                    placeholder={t("Write")}
                                    type="text"
                                    value={printerPath}
                                    onChange={(e: any) => {
                                        setPrinterPath(e.target.value);
                                        setIsEdited(true);
                                    }}
                                />
                            </AvGroup>
                        </div>
                    ) : null}
                    <AvGroup
                        style={{
                            width: "100%",
                        }}
                    >
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="example-input"
                        >
                            {t("Printer code")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("imp_inp_code", {
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="code"
                            name="code"
                            placeholder={t("Write")}
                            onChange={(e: any) => {
                                setPrinterCode(e.target.value);
                                setIsEdited(true);
                                const isValid = e.target.value.match(
                                    numberMatchWordWithNullInLeft
                                );
                                setIsCodeValid(isValid !== null);
                            }}
                            value={printerCode}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please enter a printer code"
                                    )!}`,
                                },
                                pattern: {
                                    value: numberMatchWordWithNullInLeft,
                                    errorMessage: t("Printer code is invalid"),
                                },
                            }}
                            maxMenuHeight="90px"
                        />
                        {codeIsExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The code is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    {isIpPrinter ? (
                        <AvGroup>
                            <StyledLabel
                                htmlFor="example-input"
                                className="required__clz mt-3"
                            >
                                {t("IP address")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                style={{ width: "100%" }}
                                className={classnames("imp_inp_ipAddress", {
                                    input__clz:
                                        inputIp || emptyIp || exist.isIpExist,
                                    readOnly__clz: isConsult,
                                })}
                                id="ip"
                                name="ip"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    setIp(e.target.value);
                                    setIsEdited(true);
                                    if (
                                        !e.target.value.match(ipMatchWord) &&
                                        e.target.value !== ""
                                    ) {
                                        setInputIp(true);
                                    } else {
                                        setInputIp(false);
                                    }
                                    if (e.target.value !== "") {
                                        setEmptyIp(false);
                                    }
                                    uniqueDesignationIp(e.target.value, "ip");
                                }}
                                value={ip}
                            />

                            {emptyIp || inputIp || exist.isIpExist ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t(
                                        emptyIp
                                            ? "Please enter a IP address"
                                            : inputIp
                                            ? "IP address is invalid"
                                            : exist.isIpExist
                                            ? "The ip address is already exists"
                                            : ""
                                    )}
                                </div>
                            ) : null}
                        </AvGroup>
                    ) : null}
                    {isIpPrinter || printerType === "series" ? (
                        <div
                            className="d-flex align-items-start"
                            style={{ gap: "10%" }}
                        >
                            {isIpPrinter ? (
                                <AvGroup
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Port number")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        className={classnames(
                                            "imp_inp_portNumber",
                                            {
                                                readOnly__clz: isConsult,
                                            }
                                        )}
                                        autocomplete="off"
                                        id="port"
                                        name="port"
                                        placeholder={t("Write")}
                                        onChange={(e: any) => {
                                            setIpPortNumber(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        value={ipPortNumber}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: `${t(
                                                    "Please enter a port number"
                                                )!}`,
                                            },
                                            pattern: {
                                                value: numberMatchWord,
                                                errorMessage: t(
                                                    "Port number is invalid"
                                                ),
                                            },
                                        }}
                                        maxMenuHeight="90px"
                                    />
                                </AvGroup>
                            ) : (
                                <AvGroup style={{ width: "50%" }}>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Port number")}
                                    </StyledLabel>
                                    <StyledSelectInput
                                        style={{ width: "100%" }}
                                        name="port_num"
                                        id="port_num"
                                        options={portNumArray}
                                        onChange={(e: any) => {
                                            setPortSeries(e.label);
                                            setIsEdited(true);
                                        }}
                                        placeholder={t("Write")}
                                        value={{
                                            value: portSeries,
                                            label: portSeries,
                                        }}
                                        noOptionsMessage={() => t("No options")}
                                        maxMenuHeight="90px"
                                        className={classnames(
                                            "imp_inp_portNumber",
                                            {
                                                readOnly__clz: isConsult,
                                            }
                                        )}
                                    />
                                </AvGroup>
                            )}

                            {printerType === "series" && (
                                <AvGroup style={{ width: "50%" }}>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="speed"
                                    >
                                        {t("Speed")}
                                    </StyledLabel>
                                    <StyledSelectInput
                                        value={{
                                            label: speed,
                                            value: speed,
                                        }}
                                        id="speed"
                                        name="speed"
                                        placeholder={t("Write")}
                                        options={printerSpeedArray}
                                        onChange={(e: any) => {
                                            setSpeed(e.label);
                                            setIsEdited(true);
                                        }}
                                        noOptionsMessage={() => t("No options")}
                                        maxMenuHeight="90px"
                                        className={classnames("imp_inp_speed", {
                                            readOnly__clz: isConsult,
                                        })}
                                    />
                                </AvGroup>
                            )}
                        </div>
                    ) : null}

                    <div
                        className="d-flex align-items-start"
                        style={{ gap: "10%" }}
                    >
                        <AvGroup style={{ width: "50%" }}>
                            <StyledLabel className="mt-3" htmlFor="size">
                                {t("Font size")}
                            </StyledLabel>
                            <StyledSelectInput
                                value={{
                                    label: fontSize,
                                    value: fontSize,
                                }}
                                name="font_size"
                                id="label"
                                style={{ width: "50%" }}
                                className={classnames("imp_inp_fontSize", {
                                    readOnly__clz: isConsult,
                                })}
                                options={fontSizeArray}
                                placeholder={t("Write")}
                                onChange={(e: any) => {
                                    setFontSize(e.label);
                                    setIsEdited(true);
                                }}
                                noOptionsMessage={() => t("No options")}
                                maxMenuHeight="90px"
                            />
                        </AvGroup>

                        <AvGroup style={{ width: "50%" }}>
                            <StyledLabel className="mt-3" htmlFor="width">
                                {t("Ticket width")}
                            </StyledLabel>
                            <StyledSelectInput
                                value={{
                                    label: ticketWidth,
                                    value: ticketWidth,
                                }}
                                name="ticket_width"
                                id="ticket_width"
                                options={ticketWidthArray}
                                placeholder={t("Write")}
                                onChange={(e: any) => {
                                    setTicketWidth(e.label);
                                    setIsEdited(true);
                                }}
                                noOptionsMessage={() => t("No options")}
                                maxMenuHeight="90px"
                                className={classnames("imp_inp_ticketWidth", {
                                    readOnly__clz: isConsult,
                                })}
                            />
                        </AvGroup>
                    </div>

                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="mark">
                            {t("Mark")}
                        </StyledLabel>
                        <StyledTextInput
                            style={{ width: "100%" }}
                            id="mark"
                            name="mark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setMark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={mark}
                            className={classnames("imp_inp_mark", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Ticket")}
                        </StyledLabel>
                        <StyledSelectInput
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setTickets(e);
                                setInputTicket(false);
                            }}
                            className={classnames("imp_drp_ticket", {
                                input__clz: inputTicket,
                                readOnly__clz: isConsult,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={tickets}
                            name="country"
                            isClearable
                            options={ticketsList}
                            noOptionsMessage={() => t("No options")}
                        />
                        {inputTicket ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a ticket")}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            style={{ width: "100%" }}
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setRemark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remark}
                            className={classnames("imp_inp_remark", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 imp_btn_cancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setEditedData([]);
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsConsult(false);
                                    setIp("");
                                    activateCheckbox();
                                    setInputIp(false);
                                    setIsEdited(false);
                                });
                            } else {
                                setEditedData([]);
                                setIsAddPrinterClicked!(!isAddPrinterClicked);
                                setIsConsult(false);
                                setIp("");
                                activateCheckbox();
                                setInputIp(false);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={classnames(
                            "imp_btn_validate cmn_btn_validate",
                            {
                                "not-allowed-icon__clz": isConsult || isWaiting,
                            }
                        )}
                        onClick={() => {
                            if (designation === "") {
                                setInputDesignation(true);
                            }
                            if (tickets === null) {
                                setInputTicket(true);
                            }
                            if (!ip.match(ipMatchWord)) {
                                setInputIp(true);
                            }
                            if (ip === "") {
                                setEmptyIp(true);
                            }
                            if (editedData.length === 0) {
                                if (
                                    (isIpPrinter &&
                                        !codeIsExist &&
                                        isCodeValid &&
                                        ip.length !== 0 &&
                                        designation.length !== 0 &&
                                        printerCode !== "" &&
                                        tickets !== null &&
                                        ip.match(ipMatchWord) &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist &&
                                        ipPortNumber.match(numberMatchWord)) ||
                                    (!isIpPrinter &&
                                        isCodeValid &&
                                        !codeIsExist &&
                                        !isUsbPrinter &&
                                        designation.length !== 0 &&
                                        printerCode !== "" &&
                                        !exist.isNameExist &&
                                        tickets !== null &&
                                        !exist.isIpExist) ||
                                    (isUsbPrinter &&
                                        isCodeValid &&
                                        designation !== "" &&
                                        printerCode !== "" &&
                                        !exist.isNameExist &&
                                        !codeIsExist &&
                                        tickets !== null)
                                ) {
                                    handleValidateButtonClickEvent();
                                }
                            } else {
                                if (isEdited === false) {
                                    setIsModalOpened(true);
                                } else if (
                                    (isIpPrinter &&
                                        !codeIsExist &&
                                        isCodeValid &&
                                        ip.length !== 0 &&
                                        designation.length !== 0 &&
                                        printerCode !== "" &&
                                        tickets !== null &&
                                        ip.match(ipMatchWord) &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist &&
                                        ipPortNumber.match(numberMatchWord)) ||
                                    (!isIpPrinter &&
                                        isCodeValid &&
                                        !codeIsExist &&
                                        !isUsbPrinter &&
                                        designation.length !== 0 &&
                                        printerCode !== "" &&
                                        !exist.isNameExist &&
                                        tickets !== null &&
                                        !exist.isIpExist) ||
                                    (isUsbPrinter &&
                                        isCodeValid &&
                                        designation !== "" &&
                                        printerCode !== "" &&
                                        !exist.isNameExist &&
                                        !codeIsExist &&
                                        tickets !== null)
                                )
                                    handleEditButtonClickEvent();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
                <PrintersPathsModal
                    setIsPrintersPathsModalOpened={
                        setIsPrintersPathsModalOpened
                    }
                    isPrintersPathsModalOpened={isPrintersPathsModalOpened}
                    setPrinterPath={setPrinterPath}
                    printerPath={printerPath}
                />
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddPrinterClicked}
                setIsAddClicked={setIsAddPrinterClicked}
                setEditedData={setEditedData}
            />
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
