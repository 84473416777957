import React from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { uuid } from "uuidv4";

import ErrorToast from "@components/Common/ErrorTost";

import {
    store,
    setIsAddAppClicked,
    setIsMultiDeletePeripheralModalOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "./store";
import {
    setMessage,
    setFN,
} from "@components/Common/ConfirmationMultiDeleteMessage/store";
import { ConfirmationDissociate } from "./ConfirmationDissociate";
import { setEditedSelectedRows } from "./ApplicationAssociateModal/store";

type actionType = {
    typeDisplay: string;
    setTypeDisplay: Function;
    selectedIds: any;
    ListNumberOrigin: any;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    displayMode: string;
    setIsAssociateModalOpened: Function;
    selectedRows: any;
};
export default function DropdownAction({
    typeDisplay,
    setTypeDisplay,
    selectedIds,
    ListNumberOrigin,
    setDataIsUpdated,
    displayMode,
    setIsHistoryModalOpened,
    setIsAssociateModalOpened,
    selectedRows,
}: actionType) {
    const { t } = useTranslation();
    const {
        isActionsClicked,
        isEdited,
        isLoading,
        modify,
        isAddAppClicked,
    } = useSnapshot(store);
    const [
        isDissociateModalOpened,
        setIsDissociateModalOpened,
    ] = React.useState(false);
    const appId = "KIOSK";
    const canViewHist: boolean =
        (displayMode !== t("View by device")
            ? selectedRows.filter(
                  (x: any) =>
                      ["printer", "monetic", "cash_management"].indexOf(
                          x.peripheralType
                      ) === -1
              )
            : []
        )?.length === 0;

    const getArrayOfShopIds = () => {
        let shopIds: any = [];
        let application: any = [];
        selectedRows.forEach((elt: any) => {
            if (elt[t("Application")] !== "") {
                application.push(elt.Application);
                shopIds.push(elt.shopId);
            }
        });
        shopIds = [...new Set(shopIds)];
        return { shopIds, application };
    };
    let shopIds = getArrayOfShopIds();

    const getShopIdsOfDevices = () => {
        let shopIds: any = [];
        selectedRows.forEach((elt: any) => {
            if (elt[t("Peripheral")] !== "") {
                shopIds.push(elt.shopId);
            }
        });
        shopIds = [...new Set(shopIds)];
        return shopIds;
    };
    let shopIdsOfDevices = getShopIdsOfDevices();

    return (
        <React.Fragment>
            <div>
                <div
                    className={` cmn_drp_FRmenu ${classnames("dropdown", {})}`}
                    onClick={() => {}}
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className={` dropdown-item pl-1  ${classnames("", {
                                "not-allowed-icon__clz":
                                    displayMode === t("View by device"),
                            })}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setTypeDisplay(
                                            typeDisplay === "notArchived"
                                                ? "archived"
                                                : "notArchived"
                                        );
                                        if (typeDisplay === "notArchived") {
                                            setIsAddAppClicked(false);
                                        }
                                    });
                                } else {
                                    setTypeDisplay(
                                        typeDisplay === "notArchived"
                                            ? "archived"
                                            : "notArchived"
                                    );
                                    if (typeDisplay === "notArchived") {
                                        setIsAddAppClicked(false);
                                    }
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {displayMode === t("View by device")
                                    ? t(
                                          typeDisplay === "notArchived"
                                              ? "Archived(s) device(s)"
                                              : "Activated(s) device(s)"
                                      )
                                    : t(
                                          typeDisplay === "notArchived"
                                              ? "Archived(s) application(s)"
                                              : "Activated(s) application(s)"
                                      )}
                                (
                                {typeDisplay === "notArchived"
                                    ? ListNumberOrigin?.nbrArchived
                                    : ListNumberOrigin?.nbrNotArchived}
                                )
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        className={`cmn_btn_FRarchived cmn_btn_FRunarchive cmn_btn_FRarchived ${classnames(
                                            " dropdown-item pl-1 pointer__clz",
                                            {
                                                "not-allowed-icon__clz":
                                                    isLoading ||
                                                    shopIds.shopIds.length <
                                                        1 ||
                                                    displayMode ===
                                                        t("View by device") ||
                                                    isAddAppClicked,
                                            }
                                        )}`}
                                        onClick={async () => {
                                            let error: boolean = false;
                                            setIsLoading(true);
                                            try {
                                                setFN(async () => {
                                                    setIsLoading(true);
                                                    for (
                                                        let index = 0;
                                                        index <
                                                        selectedIds.length;
                                                        index++
                                                    ) {
                                                        let item =
                                                            selectedIds[index];
                                                        const link = `${process.env.REACT_APP_API_V2_URL}/settings/application`;
                                                        let savedData = {
                                                            userId: item.userID,
                                                            shopId: item.shopId,
                                                            operatorId:
                                                                item.operatorId,
                                                            franchiseId:
                                                                item.franchiseID,
                                                            appId: appId,
                                                            appId_children: [
                                                                item.Id,
                                                            ],
                                                            isArchived:
                                                                item.isArchived ===
                                                                "true",
                                                            uuidUser:
                                                                item.uuidUser,
                                                        };
                                                        mutate(
                                                            link,
                                                            await fetch(link, {
                                                                headers: {
                                                                    "Content-Type":
                                                                        "application/json",
                                                                    authorization: `Bareer ${localStorage.getItem(
                                                                        "jwt"
                                                                    )}`,
                                                                },

                                                                method:
                                                                    "Delete",
                                                                body: JSON.stringify(
                                                                    savedData
                                                                ),
                                                            })
                                                                .then(
                                                                    (
                                                                        response
                                                                    ) =>
                                                                        response.json()
                                                                )
                                                                // eslint-disable-next-line no-loop-func
                                                                .then(
                                                                    // eslint-disable-next-line no-loop-func
                                                                    (data) => {
                                                                        if (
                                                                            data.error
                                                                        ) {
                                                                            error = true;
                                                                            throw Error(
                                                                                "Error!"
                                                                            );
                                                                        }

                                                                        if (
                                                                            index ===
                                                                            selectedIds.length -
                                                                                1
                                                                        ) {
                                                                            if (
                                                                                error ===
                                                                                    false &&
                                                                                selectedIds.length >
                                                                                    0
                                                                            ) {
                                                                                setDataIsUpdated(
                                                                                    true
                                                                                );
                                                                                setIsLoading(
                                                                                    false
                                                                                );
                                                                                setKey(
                                                                                    uuid()
                                                                                );
                                                                                toast.success(
                                                                                    `${t(
                                                                                        displayMode !==
                                                                                            t(
                                                                                                "View by device"
                                                                                            )
                                                                                            ? typeDisplay ===
                                                                                              "notArchived"
                                                                                                ? "This application was successfully archived"
                                                                                                : "This application was successfully unarchived"
                                                                                            : typeDisplay ===
                                                                                              "notArchived"
                                                                                            ? "The device was successfully archived"
                                                                                            : "The device was successfully unarchived"
                                                                                    )!}`,
                                                                                    {
                                                                                        position:
                                                                                            toast
                                                                                                .POSITION
                                                                                                .TOP_CENTER,
                                                                                        autoClose: 2000,
                                                                                        theme:
                                                                                            "colored",
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        icon: (
                                                                                            <CheckboxIcon
                                                                                                height={
                                                                                                    25
                                                                                                }
                                                                                                width={
                                                                                                    25
                                                                                                }
                                                                                                fill="white"
                                                                                            />
                                                                                        ),
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                )
                                                        );
                                                    }
                                                });
                                                setMessage(
                                                    t(
                                                        typeDisplay ===
                                                            "notArchived"
                                                            ? shopIds
                                                                  .application
                                                                  .length > 1
                                                                ? "Do you want to confirm archiving of selected applications?"
                                                                : "Do you want to confirm the archiving of the selected application?"
                                                            : shopIds
                                                                  .application
                                                                  .length > 1
                                                            ? "Do you want to confirm unarchiving of selected applications?"
                                                            : "Do you want to confirm the archiving of the selected application?"
                                                    )
                                                );

                                                if (
                                                    selectedRows?.length > 0 &&
                                                    selectedRows.filter(
                                                        (x: any) => {
                                                            return (
                                                                (x[
                                                                    t("Shop")
                                                                ] ===
                                                                    undefined ||
                                                                    x[
                                                                        t(
                                                                            "Shop"
                                                                        )
                                                                    ] === "") &&
                                                                x?.key_application !==
                                                                    "" &&
                                                                x?.subRows
                                                                    ?.length > 0
                                                            );
                                                        }
                                                    ).length > 0 &&
                                                    typeDisplay ===
                                                        "notArchived" &&
                                                    displayMode !==
                                                        t("View by device")
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Please note that you cannot archive a application associated with an device."
                                                        )
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    setIsMultiDeletePeripheralModalOpened(
                                                        true
                                                    );
                                                    setIsLoading(false);
                                                }
                                            } catch (e: any) {
                                                error = true;
                                                setIsLoading(false);
                                                toast.error(
                                                    `${t("There's an error")!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        <span className="ml-2">
                                            {typeDisplay === "notArchived"
                                                ? t("Archive")
                                                : t("Dearchive")}
                                        </span>
                                    </div>
                                </>
                                <div
                                    className={` dropdown-item pl-1 ${classnames(
                                        "cmn_btn_FRhistory",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedIds.length < 2 ||
                                                displayMode ===
                                                    t("View by device") ||
                                                canViewHist,
                                        }
                                    )}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setIsHistoryModalOpened(true);
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRhistory">
                                        {t("Historical")}
                                    </span>
                                </div>
                                <div
                                    className={` dropdown-item pl-1 ${classnames(
                                        {
                                            "not-allowed-icon__clz":
                                                (shopIds.shopIds.length < 1 &&
                                                    displayMode !==
                                                        t("View by device")) ||
                                                (shopIdsOfDevices.length < 1 &&
                                                    displayMode ===
                                                        t("View by device")) ||
                                                typeDisplay === "archived" ||
                                                modify !== true,
                                        }
                                    )}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (
                                            shopIds.shopIds.length > 1 &&
                                            displayMode !== t("View by device")
                                        ) {
                                            ErrorToast(
                                                t(
                                                    "Attention, multiple association is only available for applications within the same store."
                                                )
                                            );
                                        } else if (
                                            shopIdsOfDevices.length > 1 &&
                                            displayMode === t("View by device")
                                        ) {
                                            ErrorToast(
                                                t(
                                                    "Attention, multiple association is only available for devices within the same store."
                                                )
                                            );
                                        } else {
                                            setIsAssociateModalOpened(true);
                                            setEditedSelectedRows(selectedRows);
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRhistory">
                                        {t("Associate")}
                                    </span>
                                </div>
                                <div
                                    className={`${classnames(
                                        "dropdown-item pl-1",
                                        {
                                            "not-allowed-icon__clz":
                                                (displayMode !==
                                                    t("View by device") &&
                                                    !selectedRows
                                                        .map(
                                                            (element: any) =>
                                                                element.peripheralType !==
                                                                undefined
                                                        )
                                                        .includes(true)) ||
                                                (displayMode ===
                                                    t("View by device") &&
                                                    !selectedRows
                                                        .map(
                                                            (element: any) =>
                                                                element.subRows ===
                                                                undefined
                                                        )
                                                        .includes(true)),
                                        }
                                    )}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setIsDissociateModalOpened(true);
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRhistory">
                                        {t("Dissociate")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isDissociateModalOpened === true ? (
                <ConfirmationDissociate
                    isModalOpened={isDissociateModalOpened}
                    setIsModalOpened={setIsDissociateModalOpened}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
