import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { diff } from "deep-diff";
import moment from "moment";

import {
    store,
    setIsModalConfirmationConfigurationKiosk,
    setIsLoading,
} from "../../store/project/index";
import { generalConfigStore } from "@store";
import { store as userStore } from "@components/VerticalLayout/store";
import kioskSettingStore from "@pages/GeneralConfig/store";
import { setKey, store as localStore } from "./store";

import { capitalize } from "../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function ModalConfirmationConfigurationKiosk() {
    const { t } = useTranslation();
    const { isModalConfirmationConfigurationKiosk, kioskProject } = useSnapshot(
        store
    );

    const { itemsDisable } = useSnapshot(generalConfigStore);

    const {
        userID,
        projectId,
        franchiseID,
        oneShop,
        operatorID,
        shopID,
    } = useSnapshot(userStore);
    const { projectRowData } = useSnapshot(kioskSettingStore);
    const { initialProject } = useSnapshot(localStore);

    const uuidUser = localStorage.getItem("uuidUser");

    const getDiff = (type: any) => {
        let newData: any = [];
        if (
            Object.keys(
                JSON.parse(
                    JSON.stringify(kioskProject.template.content[type] as any)
                )
            ).includes("items")
        ) {
            (kioskProject.template.content[type] as any).items.forEach(
                (el: any) => {
                    let element = (initialProject.template.content[
                        type
                    ] as any).items.find((elt: any) => elt.id === el.id);
                    if (element !== undefined) {
                        newData.push(JSON.parse(JSON.stringify(element)));
                    }
                }
            );
        }

        let difference: any =
            Object.keys(
                JSON.parse(
                    JSON.stringify(kioskProject.template.content[type] as any)
                )
            ).includes("items") &&
            diff(
                JSON.parse(
                    JSON.stringify(
                        (kioskProject.template.content[type] as any).items
                    )
                ),
                newData
            )
                ? diff(
                      newData,
                      JSON.parse(
                          JSON.stringify(
                              (kioskProject.template.content[type] as any).items
                          )
                      )
                  )
                : [];
        let newDiff = [...difference];
        if (difference.length !== 0) {
            newDiff = difference.map((element: any) => {
                let defaultArray = ["template", "content", `${type}`, "items"];
                if (element.path !== undefined) {
                    element.path.forEach((item: any) =>
                        defaultArray.push(item)
                    );
                }
                return { ...element, path: defaultArray };
            });
        }

        return newDiff;
    };

    const fetchData = async (apiUrl: string) => {
        try {
            const response = await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
                method: "GET",
            });
            const data = await response.json();
            return JSON.parse(JSON.stringify(data[0]));
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };

    const processDifferences = (initial: any, modified: any) => {
        let differences: any = diff(initial, modified);
        if (differences) {
            Object.keys(modified.template.content)
                .filter(
                    (item: any) =>
                        item !== "TPAValina" &&
                        item !== "generalDesign" &&
                        item !== "finalMessage"
                )
                .forEach((el: any) => {
                    const diff = getDiff(el);
                    differences = differences.filter(
                        (elt: any) =>
                            elt.path[2] !== el || elt.path.includes("id")
                    );
                    differences = [...differences, ...diff];
                });
        }
        return differences;
    };

    const processUpdatedData = (
        modifiedDataProject: any,
        differences: any,
        shopId: string,
        warn: boolean
    ) => {
        let changedItems: any = [];
        differences.forEach((change: any) => {
            const { path } = change;
            const kind = change.item?.kind ?? change.kind;
            const rhs = change?.rhs ?? change?.item?.rhs ?? null;
            const deletedElement = change.item?.lhs ?? change.lhs ?? null;

            let data = applyChange(
                modifiedDataProject,
                path,
                kind,
                JSON.parse(JSON.stringify(rhs)),
                warn,
                changedItems,
                JSON.parse(JSON.stringify(deletedElement)),
                shopId
            );

            modifiedDataProject = data.obj;
            warn = data.warn;
        });
        return { modifiedDataProject, warn };
    };

    const processTemplateData = (
        dataProject: any,
        modifiedDataProject: any,
        warn: boolean
    ) => {
        let languageItems: any = [];
        if (
            modifiedDataProject.template.content.languages.items.every(
                (el: any) => el.active === false
            )
        ) {
            warn = true;
            languageItems = dataProject.template.content.languages.items;
        } else {
            languageItems =
                modifiedDataProject.template.content.languages.items;
        }
        let saleItems: any = modifiedDataProject.template.content.salesMethods.items.filter(
            (el: any) =>
                el.active === true &&
                el.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
        );

        if (saleItems.length === 0) {
            warn = true;
            saleItems = dataProject.template.content.salesMethods.items;
        } else {
            saleItems = modifiedDataProject.template.content.salesMethods.items;
        }
        let paymentItems: any = [];
        if (
            modifiedDataProject.template.content.meansOfPayment.items.every(
                (el: any) => el.active === false
            )
        ) {
            warn = true;
            paymentItems = dataProject.template.content.meansOfPayment.items;
        } else {
            paymentItems =
                modifiedDataProject.template.content.meansOfPayment.items;
        }
        let template = {
            ...modifiedDataProject.template,
            content: {
                ...modifiedDataProject.template.content,
                languages: {
                    ...JSON.parse(
                        JSON.stringify(
                            modifiedDataProject.template.content.languages
                        )
                    ),
                    // eslint-disable-next-line no-loop-func
                    items: languageItems,
                },
                salesMethods: {
                    // eslint-disable-next-line no-loop-func
                    items: saleItems,
                },
                meansOfPayment: {
                    // eslint-disable-next-line no-loop-func
                    items: paymentItems,
                },
            },
        };

        return { template, files: modifiedDataProject.files, warn };
    };

    const getData = async (
        shopId: string,
        appId?: any,
        isApplication: boolean = false
    ) => {
        const apiUrl = isApplication
            ? `${process.env.REACT_APP_API_V2_URL}/settings/applicationTemplate?userId=106&shopId=${shopId}&franchiseId=${franchiseID}&projectId=${projectId}&applicationId=${appId}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/projectData?userId=106&shopId=${shopId}&franchiseId=${franchiseID}&projectId=${projectId}`;

        try {
            let dataProject = await fetchData(apiUrl);
            let modifiedDataProject = JSON.parse(JSON.stringify(dataProject));

            const differences = isApplication
                ? processDifferences(
                      {
                          template: initialProject.template,
                          files: initialProject.files,
                      },
                      {
                          template: kioskProject.template,
                          files: kioskProject.files,
                      }
                  )
                : processDifferences(initialProject, kioskProject);

            let { modifiedDataProject: updatedData, warn } = processUpdatedData(
                modifiedDataProject,
                differences,
                shopId,
                false
            );

            const { template, files } = processTemplateData(
                dataProject,
                updatedData,
                warn
            );
            return { shopProject: { template, files }, warn };
        } catch (error) {
            console.error(
                `Error in getData (${
                    isApplication ? "Application" : "Project"
                }):`,
                error
            );
            return { shopProject: null, warn: false }; // Return default values in case of an error
        }
    };

    function applyChange(
        obj: any,
        path: any,
        kind: string,
        value: any,
        warn: boolean,
        changedItems: any,
        deletedElement: any,
        shopId: any
    ) {
        if (path.includes("standbyScreen") && path.includes("items")) {
            // eslint-disable-next-line no-eval
            eval(
                `(obj.template.pages.ways.standbyScreen.items =
                    kioskProject.template.pages.ways.standbyScreen.items)`
            );
        } else if (path.includes("standbyStateScreen")) {
            // eslint-disable-next-line no-eval
            eval(
                `(obj.template.content.standbyStateScreen.items =
                    kioskProject.template.content.standbyStateScreen.items)`
            );
        } else if (path.includes("files")) {
            const isNewObjectAddedToFiles =
                obj.files.findIndex((el: any) => el.id === value.id) === -1 &&
                obj.files[path[1]].id === undefined;
            const isObjectDeletedFromFiles =
                obj.files.findIndex((el: any) => el.id === value.id) === -1 &&
                obj.files[path[1]].id === undefined;
            if (isNewObjectAddedToFiles === true) {
                obj.files.push(value);
            } else if (isObjectDeletedFromFiles === true) {
                obj.files.filter((item: any) => item.id !== value.id);
            } else {
                let id = kioskProject.files[path[1]].id;
                let index = obj.files.findIndex((el: any) => el.id === id);
                if (index !== -1) {
                    let bracketNotationString = path
                        .map((element: any, key: number) => {
                            if (key > 1) {
                                if (!isNaN(element)) {
                                    return `[${element}]`;
                                }
                                return `['${element}']`;
                            } else return "";
                        })
                        .join("");
                    // eslint-disable-next-line no-eval
                    eval(`obj.files[${index}]${bracketNotationString} = value`);
                }
            }
        } else {
            let indexOfItems: number = path.findIndex(
                (el: any) => el === "items"
            );
            let itemOccurrence =
                indexOfItems !== -1
                    ? path.filter((el: any) => el === "items").length
                    : 0;
            if (indexOfItems !== -1) {
                if (path[1] === "content") {
                    let indexOfId: number = path.findIndex(
                        (el: any) => el === "id"
                    );
                    let newP = [];
                    for (let index = 0; index <= indexOfItems; index++) {
                        newP.push(path[index]);
                    }

                    if (
                        (indexOfId !== -1 &&
                            !changedItems.includes(path[indexOfItems - 1])) ||
                        itemOccurrence === 2
                    ) {
                        changedItems.push(path[indexOfItems - 1]);
                        let bracketNotationString = newP
                            .map((element: any) => {
                                if (!isNaN(element)) {
                                    return `[${element}]`;
                                }
                                return `['${element}']`;
                            })
                            .join("");

                        let items: any = [];
                        let itemsShop: any = [];
                        let newItems: any = [];
                        // eslint-disable-next-line no-eval
                        eval(`items=kioskProject${bracketNotationString}`);
                        // eslint-disable-next-line no-eval
                        eval(`itemsShop=obj${bracketNotationString}`);

                        if (!newP.includes("languages")) {
                            items.forEach((el: any) => {
                                let index: number = itemsShop.findIndex(
                                    (elt: any) => el.id === elt.id
                                );
                                if (index !== -1) {
                                    newItems.push({
                                        ...itemsShop[index],
                                    });
                                }
                            });
                            // eslint-disable-next-line no-eval
                            eval(`obj${bracketNotationString} = newItems`);
                        } else {
                            let favoredFranchise: any = items.find(
                                (el: any) => el.isDefault === true
                            ).id;
                            let isFavoredExistInShop: any = itemsShop.find(
                                (el: any) => el.id === favoredFranchise
                            );
                            if (
                                isFavoredExistInShop !== undefined &&
                                itemsDisable.find(
                                    (disabled: any) =>
                                        disabled.id === favoredFranchise &&
                                        disabled.shopId === shopId
                                ) === undefined
                            ) {
                                items.forEach((el: any) => {
                                    let index: number = itemsShop.findIndex(
                                        (elt: any) => el.id === elt.id
                                    );
                                    if (index !== -1) {
                                        newItems.push({
                                            ...itemsShop[index],
                                            isDefault: el.isDefault,
                                            active: el.isDefault,
                                        });
                                    }
                                });
                                // eslint-disable-next-line no-eval
                                eval(`obj${bracketNotationString} = newItems`);
                            } else {
                                warn = true;
                            }
                        }
                    } else {
                        let x = [...newP, path[indexOfItems + 1]].reduce(
                            (obj1: any, key: any) => obj1[key],
                            kioskProject
                        );
                        let id = "";
                        if (x !== undefined) {
                            id = x.id;
                        }
                        let indexInShop: any = [...newP]
                            .reduce((obj1: any, key: any) => obj1[key], obj)
                            .findIndex((el: any) => el.id === id);

                        if (
                            itemsDisable.some(
                                (el: any) =>
                                    el.id === id && el.shopId === shopId
                            ) &&
                            value === true
                        ) {
                            warn = true;
                        } else if (indexInShop !== -1) {
                            path.splice(indexOfItems + 1, 1, indexInShop);
                            // eslint-disable-next-line
                            let bracketNotationString = path
                                .map((element: any) => {
                                    if (!isNaN(element)) {
                                        return `[${element}]`;
                                    }
                                    return `['${element}']`;
                                })
                                .join("");

                            let isFavored =
                                obj?.template?.content?.languages?.items[
                                    indexInShop
                                ]?.isDefault ?? false;

                            if (
                                !(
                                    path.includes("languages") &&
                                    !path.includes("isDefault") &&
                                    value === false &&
                                    isFavored === true
                                )
                            ) {
                                kind === "D"
                                    ? // eslint-disable-next-line no-eval
                                      eval(`delete obj${bracketNotationString}`)
                                    : // eslint-disable-next-line no-eval
                                      eval(
                                          `obj${bracketNotationString} = value`
                                      );
                            } else if (
                                path.includes("languages") &&
                                value === false &&
                                isFavored === true
                            ) {
                                warn = true;
                            }
                        }
                    }
                }
                if (
                    path.includes("ways") ||
                    path.includes("elements") ||
                    path.includes("subSteps")
                ) {
                    let keys = Object.keys(obj.template.pages[path[2]]);
                    let bracketNotationString = path
                        .map((element: any) => {
                            if (!isNaN(element)) {
                                return `[${element}]`;
                            }
                            return `['${element}']`;
                        })
                        .join("");
                    if (keys.includes(path[3])) {
                        kind === "D"
                            ? // eslint-disable-next-line no-eval
                              eval(`delete obj${bracketNotationString}`)
                            : // eslint-disable-next-line no-eval
                              eval(`obj${bracketNotationString} = value`);
                    } else if (kind === "N") {
                        // eslint-disable-next-line no-eval
                        eval(`obj${bracketNotationString} = value`);
                    }
                }
            } else {
                if (
                    path.includes("ways") ||
                    path.includes("elements") ||
                    path.includes("subSteps")
                ) {
                    let keys = Object.keys(obj.template.pages[path[2]]);
                    let bracketNotationString = path
                        .map((element: any) => {
                            if (!isNaN(element)) {
                                return `[${element}]`;
                            }
                            return `['${element}']`;
                        })
                        .join("");
                    if (keys.includes(path[3])) {
                        kind === "D"
                            ? // eslint-disable-next-line no-eval
                              eval(`delete obj${bracketNotationString}`)
                            : // eslint-disable-next-line no-eval
                              eval(`obj${bracketNotationString} = value`);
                    } else if (kind === "N") {
                        // eslint-disable-next-line no-eval
                        eval(`obj${bracketNotationString} = value`);
                    }
                } else {
                    let pathSetting = path
                        .map((element: any) => {
                            if (!isNaN(element)) {
                                return `[${element}]`;
                            }
                            return `['${element}']`;
                        })
                        .join("");
                    // eslint-disable-next-line no-eval
                    eval(`obj${pathSetting} =
                            kioskProject${pathSetting}`);
                }
            }
        }
        return { obj, warn };
    }

    async function handleUpdateApplicationButtonOnClickEvent(
        element: any,
        appId: any
    ) {
        let data: any = {};

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/kiosk/template`;
        let { shopProject, warn }: any = await getData(element, appId, true);

        if (shopProject !== null) {
            data[projectId] = {
                ...shopProject,
                modified_at: `${moment().format("MM/DD/YY [at] HH:mm a")}`,
            };
            let dataShop = {
                shopId: element,
                userId: userID,
                franchiseId: franchiseID,
                appId: "KIOSK",
                appId_children: appId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: data,
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataShop),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
                return warn;
            } catch (e) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function handleUpdateProjectShopsButtonOnClickEvent(
        element: any,
        isError?: boolean
    ) {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        let { shopProject, warn }: any = await getData(element);

        if (shopProject !== null) {
            let dataShop = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: projectId,
                shopId: element,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    template: shopProject.template,
                    files: shopProject.files,
                },
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataShop),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
                return warn;
            } catch (e) {
                if (isError !== undefined) isError = true;
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function OverwriteTemplateOneShop() {
        let warning: boolean = false;
        if (projectRowData.subRows.length !== 0) {
            for (let element of projectRowData.subRows) {
                let warn = await handleUpdateApplicationButtonOnClickEvent(
                    oneShop ? shopID : element.shopId,
                    element.appId
                );
                if (warn === true) {
                    warning = true;
                }
            }
        }
        if (warning === true) {
            toast.warning(
                `${t(
                    "Please note, the modification could not be carried out successfully for one or more stores. Please check the Language, Sales method, and/or payment method configuration."
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }

        toast.success(`${t("Template saved successfully")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
        setIsLoading(false);
        setKey(Math.floor(Math.random() * 10000));
        if (projectRowData.Status === "Publié")
            setTimeout(() => {
                RedisPublish();
            }, 3000);
    }
    async function RedisPublish() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/redis`;

        if (projectRowData.subRows.length !== 0) {
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: JSON.parse(JSON.stringify(projectRowData))
                                .shopId,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    async function OverwriteTemplate() {
        setIsLoading(true);
        setIsModalConfirmationConfigurationKiosk(
            !isModalConfirmationConfigurationKiosk
        );

        await updateTemplate();
    }
    async function updateTemplate() {
        let warning: any = false;
        let isError = false;
        if (projectRowData.shopId !== 0 && projectRowData.shopId !== "0") {
            for (let element of projectRowData.subRows) {
                let warn = await handleUpdateApplicationButtonOnClickEvent(
                    oneShop ? shopID : element.shopId,
                    element.appId
                );
                if (warn === true) {
                    warning = true;
                }
            }
        } else {
            for (let element of JSON.parse(JSON.stringify(projectRowData))
                ?.subRows) {
                if (element.subRows.length !== 0) {
                    for (let el of element?.subRows) {
                        let warn = await handleUpdateApplicationButtonOnClickEvent(
                            oneShop ? shopID : element.shopId,
                            el.appId
                        );
                        if (warn === true) {
                            warning = true;
                        }
                    }
                    let warnShop = await handleUpdateProjectShopsButtonOnClickEvent(
                        element.id_boutique
                    );
                    if (warnShop === true) {
                        warning = true;
                    }
                } else {
                    warning = await handleUpdateProjectShopsButtonOnClickEvent(
                        element.id_boutique,
                        isError
                    );
                }
            }
        }

        if (warning === true) {
            toast.warning(
                `${t(
                    "Please note, the modification could not be carried out successfully for one or more stores. Please check the Language, Sales method, and/or payment method configuration."
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }

        toast.success(`${t("Template saved successfully")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
        setIsLoading(false);
        setKey(Math.floor(Math.random() * 10000));
        if (projectRowData.Status === "Publié")
            setTimeout(() => {
                RedisPublish();
            }, 3000);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isModalConfirmationConfigurationKiosk}
                style={{
                    maxWidth: "650px",
                    maxHeight: "500px",
                    marginTop: "300px",
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalConfirmationConfigurationKiosk(
                            !isModalConfirmationConfigurationKiosk
                        );
                        setKey(Math.floor(Math.random() * 10000));
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{
                            font: "normal normal normal 20px/27px Segoe UI",
                            whiteSpace: "pre-line",
                            opacity: "1",
                            textAlign: "left",
                            color: "#000000",
                        }}
                    >
                        {(projectRowData.shopId === 0 ||
                            projectRowData.shopId === "0") &&
                        !oneShop
                            ? t(
                                  "Attention, if you click on validate, the specific configuration of the shops will be edited."
                              )
                            : t(
                                  "Be careful, if you click on validate, the specific configuration of the applications will be edited."
                              )}
                        !
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        outline
                        variant="primary"
                        onClick={() => {
                            setIsModalConfirmationConfigurationKiosk(
                                !isModalConfirmationConfigurationKiosk
                            );
                            if (projectRowData.Status === "Publié")
                                setTimeout(() => {
                                    RedisPublish();
                                }, 3000);
                            setKey(Math.floor(Math.random() * 10000));
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomMainColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            if (oneShop) {
                                setIsModalConfirmationConfigurationKiosk(false);
                                OverwriteTemplateOneShop();
                            } else {
                                OverwriteTemplate();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
