import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PreviewBackgroundWrapper } from "@components/Common/Design/Preview/PreviewBackgroundWrapper";

import { ContainerWrapper } from "./ContainerWrapper";
import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { TopBannerWrapper } from "./TopBannerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";

import "@pages/GeneralConfig/Design/index.css";
import { LanguagesWrapper } from "@components/Common/Design/Preview/LanguagesWrapper";
import { MainContentPrinterOptionsPreview } from "./MainContentPrinterOptionsPreview";
import { ConfigOptionsWrapper } from "@components/Common/Design/Preview/ConfigOptionsWrapper";
import { EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB } from "@constants/index";

export function Preview(): JSX.Element {
    const {
        activeTabPrinterOptionsSetupOptions,
        isActionButtonsActive,
        activeTabIndex,
    } = useSnapshot(designStore);

    const { project } = useSnapshot(generalConfigStore);

    return (
        <PreviewBackgroundWrapper subStep={activeTabPrinterOptionsSetupOptions}>
            <ContainerWrapper
                isTopBannerActive={
                    activeTabPrinterOptionsSetupOptions !==
                    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB
                        ? (project.template.pages.ways[
                              "printerOptions"
                          ] as PrinterOptionsPageType).topBanner.active
                        : ((project.template.pages.subSteps[
                              "printerOptionsConfirmationEmail"
                          ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                              .topBanner.active
                }
                isHeaderActive={true}
                isActionButtonsActive={
                    (((project.template.pages.subSteps[
                        "printerOptionsConfirmationEmail"
                    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                        .actionsButtons as ActionsButtonsDesignType).active
                }
            >
                {activeTabPrinterOptionsSetupOptions !==
                EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB ? (
                    (project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType).topBanner.active ? (
                        <TopBannerWrapper
                            isLogoActive={
                                (project.template.pages.ways[
                                    "printerOptions"
                                ] as PrinterOptionsPageType).topBanner.logo
                                    .active
                            }
                        />
                    ) : (
                        <div></div>
                    )
                ) : ((project.template.pages.subSteps[
                      "printerOptionsConfirmationEmail"
                  ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                      .topBanner.active ? (
                    <TopBannerWrapper
                        isLogoActive={
                            ((project.template.pages.subSteps[
                                "printerOptionsConfirmationEmail"
                            ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                                .topBanner.logo.active
                        }
                    />
                ) : (
                    <div></div>
                )}

                <div
                    className={classNames({
                        "border-wrapper__clz": activeTabIndex === 1,
                    })}
                    style={{
                        width: "430px",
                    }}
                >
                    <div className={classNames("d-flex align-items-center")}>
                        <HeaderWrapper />
                    </div>

                    <div
                        className={classNames(
                            "d-flex flex-column align-items-center justify-content-center p-2"
                        )}
                        style={{
                            justifyContent: "center",
                            height: "40vh",
                        }}
                    >
                        <MainContentPrinterOptionsPreview />
                    </div>
                </div>
                <div
                    className={classNames({
                        "border-wrapper__clz": activeTabIndex === 3,
                    })}
                    style={{ width: "430px" }}
                >
                    {isActionButtonsActive ? <ActionsButtonsWrapper /> : null}
                </div>
                <LanguagesWrapper />
                <ConfigOptionsWrapper />
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
