import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { t } from "i18next";
import React from "react";
import ChromePicker from "react-color/lib/components/chrome/Chrome";
import ReactSwitch from "react-switch";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setImageKey,
    setIsBackgroundColorActive,
    setIsClosingButtonActive,
    setIsNextOrPreviousButtonsActive,
    setPageOrderTakingCompositeProduct,
    setProductAccessIconCompositeProduct,
    setProgressColor,
    setProjectFiles,
    setIsNutriscoreInCompositeProductActive,
} from "@store";

import { InputGroupWrapper } from "@components/ColorPickerWrapper/InputGroupWrapper";
import { Uploader } from "@components/Uploader";
import "@components/Common/Design/index.css";

export function Header(): JSX.Element {
    const {
        project: { template, files: images },
        imageKey,
        isNutriscoreActive,
        isNutriscoreInCompositeProductActive,
    } = useSnapshot(generalConfigStore);
    const {
        progressColor,
        closingButton,
        productAccessIconCompositeProduct,
        isBackgroundColorActive,
        isNextOrPreviousButtonsActive,
        isClosingButtonActive,
    } = useSnapshot(designStore);

    function handleShowColorPickerOnClickEvent() {
        setIsColorPickerShown(!isColorPickerShown);
    }

    function handleColorPickerCloseEvent() {
        setIsColorPickerShown(false);
    }

    const [isColorPickerShown, setIsColorPickerShown] = React.useState<boolean>(
        false
    );

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uuid());
    }

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Header")}
            </div>
            <div className="p-4 d-flex flex-column" style={{ gap: "10px" }}>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Header color")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isBackgroundColorActive}
                        onChange={() => {
                            setIsBackgroundColorActive(
                                !isBackgroundColorActive
                            );
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                headerColor: !isBackgroundColorActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Closing button")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(closingButton.id);
                            }}
                            disabled={
                                isClosingButtonActive === true ? false : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={closingButton.content as string}
                            id={closingButton.id}
                            disabled={
                                isClosingButtonActive === true ? false : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isClosingButtonActive}
                            onChange={() => {
                                setIsClosingButtonActive(
                                    !isClosingButtonActive
                                );
                                setPageOrderTakingCompositeProduct({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType).compositeProduct,
                                    closingButton: {
                                        ...(template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .compositeProduct.closingButton,
                                        active: !isClosingButtonActive,
                                    },
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Product information access icon")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(
                                    productAccessIconCompositeProduct.id
                                );
                            }}
                            disabled={
                                productAccessIconCompositeProduct.active ===
                                true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={
                                productAccessIconCompositeProduct.content as string
                            }
                            id={productAccessIconCompositeProduct.id}
                            disabled={
                                productAccessIconCompositeProduct.active ===
                                true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={productAccessIconCompositeProduct.active}
                            onChange={() => {
                                setProductAccessIconCompositeProduct({
                                    ...productAccessIconCompositeProduct,
                                    active: !productAccessIconCompositeProduct.active,
                                });
                                setPageOrderTakingCompositeProduct({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType).compositeProduct,
                                    productAccessIcon: {
                                        ...(template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .compositeProduct.productAccessIcon,
                                        active: !productAccessIconCompositeProduct.active,
                                    },
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Next/previous button")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isNextOrPreviousButtonsActive}
                        onChange={() => {
                            setIsNextOrPreviousButtonsActive(
                                !isNextOrPreviousButtonsActive
                            );
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                nextOrPreviousButtons: !isNextOrPreviousButtonsActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Course Progress Color")}</div>
                    <AvForm
                        className="btn-group"
                        style={{
                            display: "grid",
                            gridColumnGap: "2px",
                        }}
                    >
                        <InputGroupWrapper
                            color={progressColor.content}
                            handleShowColorPickerOnClickEvent={
                                handleShowColorPickerOnClickEvent
                            }
                        />

                        {isColorPickerShown ? (
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    marginLeft: "-229px",
                                    marginTop: "-38px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                    }}
                                    onClick={handleColorPickerCloseEvent}
                                />
                                <ChromePicker
                                    onChangeComplete={(colorResult) => {
                                        setProgressColor({
                                            ...progressColor,
                                            content: colorResult.hex as ColorType,
                                        });
                                        setPageOrderTakingCompositeProduct({
                                            ...(template.pages.ways[
                                                "orderTaking"
                                            ] as OrderTakingPageType)
                                                .compositeProduct,
                                            courseProgressColor: {
                                                ...(template.pages.ways[
                                                    "orderTaking"
                                                ] as OrderTakingPageType)
                                                    .compositeProduct
                                                    .courseProgressColor,
                                                content: colorResult.hex as ColorType,
                                            },
                                        });
                                    }}
                                    color={progressColor.content}
                                />
                            </div>
                        ) : null}
                    </AvForm>
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Show 'Nutri-score' informations")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isNutriscoreInCompositeProductActive}
                        onChange={() => {
                            setIsNutriscoreInCompositeProductActive(
                                !isNutriscoreInCompositeProductActive
                            );
                        }}
                        disabled={!isNutriscoreActive}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
