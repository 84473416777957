import React from "react";

import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";

import { store as ModeOfSale } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIsLoading,
    setKey,
} from "../store";
import { generalConfigStore, setItemsDisable } from "@store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { ConfirmationActivationDeactivationMessageModal } from "../ConfirmationActivationDeactivationMessageModal";

export function CustomIcons({
    e,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfSaleArchived,
    selectedRows,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        modeSaleData,
        shopsModeSaleData,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        ModeOfSale
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isActivationDeactivationModalOpened,
        setIsActivationDeactivationModalOpened,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archiveModeSale();
    }

    function archiveModeSale() {
        let idsAssociated: any = [];
        if (modeSaleData.data !== undefined) {
            modeSaleData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeModeOfSale() {
        toast.dismiss();
        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        designation: e.selectedRow["Mode of sale"],
                        tag: e.selectedRow.tag,
                        display_name: e.selectedRow.display_name,
                        support_vente: e.selectedRow.support_vente,
                        isArchived: false,
                        isFavorite: e.selectedRow.isFavorite,
                        state: e.selectedRow.state,
                        shopId: e.selectedRow.shopId,
                        advancedDisplayName: e.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];

        if (
            e.selectedRow.state === false &&
            itemsDisable.find(
                (item: any) =>
                    item.id === e.selectedRow.Id &&
                    item.shopId === e.selectedRow.shopId
            ) === undefined
        ) {
            const item = [
                ...JSON.parse(JSON.stringify(itemsDisable)),
                {
                    id: e.selectedRow.Id,
                    state: false,
                    shopId: e.selectedRow.shopId,
                },
            ];
            setItemsDisable(item);
            localStorage.setItem("itemsDisable", JSON.stringify(item));
        }

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Sales method has been successfully activated"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsLoading(false);
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    async function favoriteModeOfSale() {
        toast.dismiss();

        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: updatedSaleMode(),
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Favorite state has been enabled successfully"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function updatedSaleMode() {
        let updatedSale: any = {};
        shopsModeSaleData.data.forEach((el: any) =>
            el.subRows.forEach((modeOfSale: any) => {
                if (
                    modeOfSale.Id === e.selectedRow.Id &&
                    modeOfSale.shopId === e.selectedRow.shopId
                ) {
                    updatedSale[modeOfSale.Id] = {
                        tag: modeOfSale.tag,
                        display_name: modeOfSale.display_name,
                        support_vente: modeOfSale.support_vente,
                        isArchived: modeOfSale.isArchived,
                        state: modeOfSale.state,
                        shopId: modeOfSale.shopId,
                        isFavorite: true,
                        designation: modeOfSale["Mode of sale"],
                        advancedDisplayName: modeOfSale.advancedDisplayName,
                    };
                } else if (
                    modeOfSale.Id !== e.selectedRow.Id &&
                    modeOfSale.shopId === e.selectedRow.shopId
                ) {
                    updatedSale[modeOfSale.Id] = {
                        tag: modeOfSale.tag,
                        display_name: modeOfSale.display_name,
                        support_vente: modeOfSale.support_vente,
                        isArchived: modeOfSale.isArchived,
                        state: modeOfSale.state,
                        shopId: modeOfSale.shopId,
                        isFavorite: false,
                        designation: modeOfSale["Mode of sale"],
                        advancedDisplayName: modeOfSale.advancedDisplayName,
                    };
                }
            })
        );
        return updatedSale;
    }

    async function isModeOfSaleUsed() {
        toast.dismiss();
        let isModeOfSaleUsed = false;
        let isSaleModeConsumed = false;

        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/isModeOfSaleUsed?userId=${userID}&shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&modeOfSale=${e.selectedRow.Id}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.modeOfSaleUsed.length > 0) {
                        isModeOfSaleUsed = true;
                        ErrorToast(
                            t("Attention, you cannot archive a used element.")
                        );
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        if (isModeOfSaleUsed === false) {
            isSaleModeConsumed = await isModeOfSaleConsumed();
            if (isSaleModeConsumed === true) {
                ErrorToast(
                    t(
                        "Please note, you cannot archive an element used in a template"
                    )
                );
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setIsArchiveModalOpened(true);
            }
        }
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        e.selectedRow.support_vente.forEach((support: any) => {
                            if (
                                elt.subRows.some(
                                    (sale: any) =>
                                        `${sale[t("Sale support")]} ${
                                            sale.shopId
                                        }` ===
                                        `${support} ${e.selectedRow.shopId}`
                                )
                            ) {
                                isSaleSupportArchived = true;
                            }
                        });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return isSaleSupportArchived;
    }

    async function isModeOfSaleConsumed() {
        toast.dismiss();
        let isModeOfSaleUsed = false;

        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&type=salesMethods`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) => el.id === e.selectedRow.Id
                        )
                    ) {
                        isModeOfSaleUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isModeOfSaleUsed;
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfSaleArchived ||
                                    e.selectedRow.isFavorite
                                        ? ""
                                        : t("Enable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (!e.selectedRow.state) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot activate the favorite state for a disabled mode of sale"
                                                    )
                                                );
                                            } else if (
                                                !e.selectedRow.isFavorite
                                            ) {
                                                setIsLoading(true);
                                                setRowData(e.selectedRow);
                                                favoriteModeOfSale();
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            e.selectedRow.isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "mdv_icn_BTlike",
                                            {
                                                "not-allowed-icon__clz": isModeOfSaleArchived,
                                            },
                                            {
                                                pointer__clz: !e.selectedRow
                                                    .isFavorite,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={
                                    isModeOfSaleArchived
                                        ? ""
                                        : e.selectedRow.state === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={e.selectedRow.state}
                                        onChange={async () => {
                                            if (
                                                e.selectedRow.isFavorite ===
                                                true
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot deactivate a favorite mode of sale"
                                                    )
                                                );
                                            } else {
                                                setIsActivationDeactivationModalOpened(
                                                    true
                                                );
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1",
                                            {
                                                "not-allowed-icon__clz": isModeOfSaleArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {!isModeOfSaleArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsLoading(true);
                                                if (
                                                    e.selectedRow.isFavorite ===
                                                    true
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Please note, you cannot archive a favorite mode of sale"
                                                        )
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    isModeOfSaleUsed();
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "ml-1 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                let isArchived = await isSaleSupportArchived();

                                                if (isArchived === true) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    activeModeOfSale();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop +
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop -
                                                            1
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-1 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-1 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setRowData(e.selectedRow);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
            {isActivationDeactivationModalOpened ? (
                <ConfirmationActivationDeactivationMessageModal
                    isModalOpened={isActivationDeactivationModalOpened}
                    setIsModalOpened={setIsActivationDeactivationModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    modeOfSaleState={e.selectedRow.state}
                />
            ) : null}
        </React.Fragment>
    );
}
