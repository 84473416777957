import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { PlusIcon } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "@components/VerticalLayout/store";
import {
    store as salesModeStore,
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setModeSaleData,
    setArchivedModeOfSaleFranchise,
    setModeOfSaleFranchise,
    setDataArchive,
    setDataFranchiseArchive,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setSalesSupports,
} from "../store";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomIcons } from "./CustomIcons";
import SaleForm from "./SaleForm";
import { ActionColumnModeSale } from "./ActionColumnModeSale";
import { ConfirmationActiveModeOfSale } from "../ConfirmationActiveModeOfSale";
import HistoryModal from "../HistoryModal";
import { ConfirmationFavorite } from "./ConfirmationFavorite";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { GetSalesModesTypesAndTags } from "./helper";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function ModeOfSales({
    isModeOfSaleArchived,
    setIsModeOfSaleArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const didMountRef = React.useRef(false);

    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const [
        isAddNewModeOfSaleButtonClicked,
        setIsAddNewModeOfSaleButtonClicked,
    ] = useState(false);
    const {
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
        key,
        isLoading,
    } = useSnapshot(salesModeStore);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );

    const [rowData, setRowData] = useState<any>({});
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [SalesModesTypesAndTag, setSalesModesTypesAndTag] = React.useState<
        any
    >({});
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [sale, setSale] = useState<any>([]);
    const [shopId, setShopId] = React.useState<string | number>(
        oneShop ? shopID : 0
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [inputSaleMode, setInputSaleMode] = React.useState<boolean>(false);
    const [selectName, setSelectName] = React.useState<boolean>(false);
    const [selectSales, setSelectSales] = React.useState<boolean>(false);
    const [isAddModeOfSaleClicked, setIsAddModeOfSaleClicked] = React.useState<
        boolean
    >(false);
    const [isFavoriteModified, setIsFavoriteModified] = React.useState<boolean>(
        false
    );
    const [dataState, setDataState] = useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={e.selectedRow.display_name} />
            ),
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 1,
            columnName: t("Sales method"),
            customJsx: (e: any) => <span>{e.selectedRow["Mode of sale"]}</span>,
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Sale support"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={e.selectedRow.support_vente.join()}
                />
            ),
            filterName: "support_vente",
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={e.selectedRow.tag.join()} />
            ),
            filterName: "tag",
            canFilter: true,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    setSale={setSale}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isOneShop={true}
                    isModeOfSaleArchived={isModeOfSaleArchived}
                    setSelectName={setSelectName}
                    setSelectSales={setSelectSales}
                    setInputSaleMode={setInputSaleMode}
                    isAddModeOfSaleClicked={isAddModeOfSaleClicked}
                    setIsAddModeOfSaleClicked={setIsAddModeOfSaleClicked}
                    isFavoriteModified={isFavoriteModified}
                    setIsFavoriteModified={setIsFavoriteModified}
                    selectedRows={selectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    setIsConsult={setIsConsult}
                    setDataState={setDataState}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        if (
            dataTable.othersData !== undefined &&
            dataTable.othersData.numberActive !== undefined
        ) {
            setNumberActive(dataTable.othersData.numberActive);
            setNumberArchived(dataTable.othersData.numberArchive);
        } else {
            setNumberActive(0);
            setNumberArchived(0);
        }
    }

    const getSaleSupport = React.useCallback(async () => {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=activated`,
            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    let shopSalesSupport: any[] = [];
                    let LocalSaleSupport: any[] = [];

                    result.data.forEach((el: any) => {
                        shopSalesSupport = el.subRows.map(
                            (element: any) => element[t("Sale support")]
                        );
                        LocalSaleSupport.push({
                            id: el.shopId,
                            salesSupport: shopSalesSupport,
                        });
                    });
                    setSalesSupports(LocalSaleSupport);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, [franchiseID, userID, t, i18nextLng]);

    React.useEffect(() => {
        if (filterActive) setIsAddModeOfSaleClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        getSaleSupport();
    }, [getSaleSupport]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setModeSaleData(dataTable);
        setArchivedModeOfSaleFranchise(dataTable);
        setModeOfSaleFranchise(dataTable);
        setDataArchive(
            dataTable.othersData !== undefined
                ? dataTable.othersData.dataArchive
                : []
        );
        setDataFranchiseArchive(
            dataTable.othersData !== undefined
                ? dataTable.othersData.dataOfFranchiseArchive
                : []
        );
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    const fetchData = async () => {
        try {
            const Data = await GetSalesModesTypesAndTags(
                userID,
                franchiseID,
                shopId
            );

            setSalesModesTypesAndTag(Data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [i18nextLng, t]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddModeOfSaleClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddModeOfSaleClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={isModeOfSaleArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={false}
                    canSelect
                    customSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddModeOfSaleClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center ml-2">
                                            <span
                                                onClick={() => {
                                                    setSale([]);

                                                    setIsAddModeOfSaleClicked(
                                                        !isAddModeOfSaleClicked
                                                    );
                                                    setLocalFilterActive(false);
                                                }}
                                                className={`${classnames(
                                                    "w-100 ml-3 cmn_btn_openForm",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            isModeOfSaleArchived ||
                                                            isLoading,
                                                    }
                                                )}`}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t("Add a sales method")}
                                                </CustomMainColorButtonWithIcon>
                                            </span>
                                            <div></div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className="mdv_btn_FRreset"
                                                rounded
                                                disabled={
                                                    sale.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                    else {
                                                        setInputSaleMode(false);
                                                        setSelectSales(false);
                                                        setSelectName(false);
                                                    }
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumnModeSale
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                setIsModeOfSaleArchived={
                                    setIsModeOfSaleArchived
                                }
                                isModeOfSaleArchived={isModeOfSaleArchived}
                                setIsAddModeOfSaleClicked={
                                    setIsAddModeOfSaleClicked
                                }
                                setLimit={setLimit}
                                setSelectedRows={setSelectedRows}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="ModeOfSale"
                />
                {isAddModeOfSaleClicked ? (
                    <SaleForm
                        isAddModeOfSaleClicked={isAddModeOfSaleClicked}
                        setIsAddModeOfSaleClicked={setIsAddModeOfSaleClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        dataSale={sale}
                        isAddNewModeOfSaleButtonClicked={
                            isAddNewModeOfSaleButtonClicked
                        }
                        setDataSale={setSale}
                        setLocalFilterActive={setLocalFilterActive}
                        inputSaleMode={inputSaleMode}
                        setInputSaleMode={setInputSaleMode}
                        selectName={selectName}
                        setSelectName={setSelectName}
                        selectSales={selectSales}
                        setSelectSales={setSelectSales}
                        setLimit={setLimit}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                        dataState={dataState}
                        SalesModesTypesAndTag={SalesModesTypesAndTag}
                    />
                ) : null}
            </div>

            <ConfirmationActiveModeOfSale
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={true}
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfSaleButtonClicked}
                    isClicked={isAddNewModeOfSaleButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    rowData={rowData}
                    isOneShop={true}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ) : null}
            {isFavoriteModified ? (
                <ConfirmationFavorite
                    isModalOpened={isFavoriteModified}
                    setIsModalOpened={setIsFavoriteModified}
                    e={rowData}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
