import React from "react";
import ReactSwitch from "react-switch";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { SettingsIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    computedStore,
    designStore,
    setIsPageSkipped,
    setProjectMainContentItems,
    setActiveMainContentItems,
    setMainContentItems,
    setIsSubStepActivated,
    setSelectedSubStep,
} from "@store";

import { SubStepContent } from "../SubStepContent";

import "@components/Common/Design/index.css";

type MainContentWrapperPropsType = {
    title: string;
};

export function MainContentWrapper({
    title,
}: MainContentWrapperPropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    const { elements } = useSnapshot(computedStore);

    const {
        mainContentItems,
        activeSlideIndex,
        isSubStepActivated,
        activePageName,
    } = useSnapshot(designStore);

    const activeMainContentItems = mainContentItems.filter((item) => {
        return item.active === true;
    });

    const [
        isSkipThisStepSwitchChecked,
        setIsSkipThisStepSwitchChecked,
    ] = React.useState<boolean>(
        (template.pages.ways[elements[activeSlideIndex].name] as PageType)
            ?.skipped as boolean
    );

    function handleSkipThisStepOnClickEvent() {
        setIsSkipThisStepSwitchChecked(!isSkipThisStepSwitchChecked);
        setIsPageSkipped(
            elements[activeSlideIndex].name,
            !isSkipThisStepSwitchChecked
        );
    }

    function handleActivateSwitchOnClickEvent(item: string) {
        const array = [...mainContentItems];

        const element = array.find((element) => element.name === item);

        if (element !== undefined) {
            const itemIndex = array.indexOf(element);

            array[itemIndex] = {
                ...element,
                active: !array[itemIndex].active,
            };

            setMainContentItems(array);
            setProjectMainContentItems(elements[activeSlideIndex].name, array);
        }
    }

    function handleOnClickEvent(item: ProjectMainContentItemType) {
        setIsSubStepActivated(!isSubStepActivated);
        setSelectedSubStep(item);
    }

    // eslint-disable-next-line
    React.useEffect(() => {
        setIsSkipThisStepSwitchChecked(
            (template.pages.ways[elements[activeSlideIndex].name] as PageType)
                ?.skipped as boolean
        );
    });

    React.useEffect(() => {
        setActiveMainContentItems(activeMainContentItems);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (
            mainContentItems.filter((item) => {
                return item.active === true;
            }).length > 1
        ) {
            setIsSkipThisStepSwitchChecked(false);
            setIsPageSkipped(elements[activeSlideIndex].name, false);
        }
        // eslint-disable-next-line
    }, [mainContentItems]);

    return (
        <React.Fragment>
            {isSubStepActivated === true ? (
                <SubStepContent />
            ) : (
                <div
                    className="mt-4 rounded border"
                    style={{
                        borderColor: "#CECECE",
                        marginRight: "60px",
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            fontSize: "20px",
                            backgroundColor: "#EDEDED",
                            height: "40px",
                        }}
                    >
                        <div className="mx-3 cursor__clz">{t(title)}</div>

                        {activeMainContentItems.length === 1 ? (
                            <div
                                className="py-2 d-flex align-items-center justify-content-center cursor__clz"
                                style={{
                                    fontSize: "17px",
                                    gap: "20px",
                                }}
                            >
                                <span>
                                    {t(
                                        "Do not offer this step in the customer journey"
                                    )}
                                </span>
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isSkipThisStepSwitchChecked}
                                    onChange={handleSkipThisStepOnClickEvent}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>
                        ) : null}
                    </div>
                    <SimpleBar autoHide={true} style={{ maxHeight: 184 }}>
                        <div
                            className={classNames("px-4 py-2", {
                                "main-content-has-sub-step__clz":
                                    activePageName !== "authenticationModes",
                            })}
                            style={{
                                display: "grid",
                            }}
                        >
                            {mainContentItems
                                .filter(
                                    (item: any) =>
                                        item.id !==
                                        "3be5c758-6b97-44dd-8e6f-6959e6540c25"
                                )
                                .map(
                                    (
                                        item: ProjectMainContentItemType,
                                        index: number
                                    ) => {
                                        const salesMethodsActive = (template
                                            .content
                                            .salesMethods as ProjectContentItemType).items.filter(
                                            (sale: any) =>
                                                sale.active === true &&
                                                sale.id !==
                                                    "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                        );
                                        return (
                                            <div
                                                key={item.id}
                                                className={classNames(
                                                    "cursor__clz",
                                                    {
                                                        "switch-items__clz":
                                                            activePageName !==
                                                            "authenticationModes",
                                                        "switch-items-with-sub-steps__clz":
                                                            activePageName ===
                                                            "authenticationModes",
                                                    }
                                                )}
                                            >
                                                <span>{t(item.name)}</span>
                                                {activePageName ===
                                                "authenticationModes" ? (
                                                    <StyledIconButton
                                                        icon="SettingsIcon"
                                                        className="m-0"
                                                        onClick={() => {
                                                            handleOnClickEvent(
                                                                item
                                                            );
                                                        }}
                                                    >
                                                        <SettingsIcon
                                                            height={25}
                                                            width={25}
                                                        />
                                                    </StyledIconButton>
                                                ) : null}
                                                <div
                                                    className={classNames(
                                                        "d-flex justify-content-end"
                                                    )}
                                                >
                                                    <ReactSwitch
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        handleDiameter={26}
                                                        offColor="#f7b4b8"
                                                        offHandleColor="#E30613"
                                                        checked={
                                                            mainContentItems[
                                                                index
                                                            ].active as boolean
                                                        }
                                                        onChange={() => {
                                                            handleActivateSwitchOnClickEvent(
                                                                mainContentItems[
                                                                    index
                                                                ].name
                                                            );
                                                        }}
                                                        onColor="#c2eddd"
                                                        onHandleColor="#34C38F"
                                                        width={50}
                                                        height={20}
                                                        disabled={
                                                            (salesMethodsActive.length ===
                                                                1 &&
                                                                salesMethodsActive[0]
                                                                    .name ===
                                                                    item.name &&
                                                                item.id !==
                                                                    "f25e7c96-b5d3-4f2e-acd0-1500258283c2") ||
                                                            (activeMainContentItems !==
                                                                (template
                                                                    .content
                                                                    .salesMethods as ProjectContentItemType)
                                                                    .items &&
                                                                activeMainContentItems.length ===
                                                                    1 &&
                                                                activeMainContentItems[0]
                                                                    .name ===
                                                                    item.name)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </SimpleBar>
                </div>
            )}
        </React.Fragment>
    );
}
