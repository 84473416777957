import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store as userStore } from "@components/VerticalLayout/store";
import { store as orbStore } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PropsType = {
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isDataUpdated: boolean | number;
    setIsModalConfirmationConfigurationOrb: Function;
    isModalConfirmationConfigurationOrb: boolean;
    rowData: any;
};

export default function ModalConfirmationConfigurationOrb({
    setIsDataUpdated,
    isDataUpdated,
    setIsModalConfirmationConfigurationOrb,
    isModalConfirmationConfigurationOrb,
    rowData,
}: PropsType) {
    const { t } = useTranslation();

    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(userStore);
    const {
        view,
        language,
        model,
        options,
        categoryAssignment,
        advertisingAnimation,
    } = useSnapshot(orbStore);

    async function handleUpdateAssociateShopButtonOnClickEvent(
        shopId: any,
        idProject: any
    ) {
        let apiUrl: any;
        let savedData = {
            userId: userID,
            operatorId: operatorID,
            franchiseId: franchiseID,
            shopId: shopId,
            idProject: idProject,
            template: {
                view: view,
                language: language,
                model: model,
                options: options,
                categoryAssignment: categoryAssignment,
                advertisingAnimation: advertisingAnimation,
            },
        };
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/associated/orb`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateShopButtonOnClickEvent() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/orb`;
        toast.dismiss();
        let localData: any = [];
        let isApp: boolean = false;
        let shopIds = "";
        let rowsApplication: any = [];

        Object.keys(rowData.associates).forEach((el: any) => {
            localData.push(
                fetch(apiUrlPlan, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        project_id: "PROJECT",
                        projectId: rowData.projectId,
                        shopId: el,
                        operatorId: operatorID,
                        data: {
                            template: {
                                view: view,
                                language: language,
                                model: model,
                                options: options,
                                categoryAssignment: categoryAssignment,
                                advertisingAnimation: advertisingAnimation,
                            },
                        },
                    }),
                }).then((response) => response.json())
            );
            rowData.subRows.forEach((elt: any) => {
                if (elt.id_boutique === el) {
                    shopIds = el;
                    if (elt.subRows.length > 0) {
                        isApp = true;
                        rowsApplication = elt.subRows;
                    } else {
                        isApp = false;
                    }
                    handleUpdateAssociateShopButtonOnClickEvent(
                        el,
                        rowData.projectId
                    );
                }
            });
        });
        try {
            mutate(
                apiUrlPlan,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                         toast.success(
                             `${t("ORB configuration saved successfully")}`,
                             {
                                 position: toast.POSITION.TOP_CENTER,
                                 autoClose: 2000,
                                 theme: "colored",
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 icon: (
                                     <CheckboxIcon
                                         height={25}
                                         width={25}
                                         fill="white"
                                     />
                                 ),
                             }
                         );
                        if (isApp === true) {
                            rowsApplication.forEach((app: any) => {
                                handleApplicationValidateButtonClickEvent(
                                    shopIds,
                                    app.appId,
                                    rowData.projectId
                                );
                            });
                        }
                        setIsModalConfirmationConfigurationOrb(
                            !isModalConfirmationConfigurationOrb
                        );
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function handleApplicationValidateButtonClickEvent(
        shopIds: any,
        appId: any,
        projectId: any
    ) {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/template`;

        let savedData = {
            userId: userID,
            operatorId: operatorID,
            franchiseId: franchiseID,
            shopId: shopIds,
            appId: "ORB",
            appId_children: appId,
            template: {
                [projectId]: {
                    view: view,
                    language: language,
                    model: model,
                    options: options,
                    categoryAssignment: categoryAssignment,
                    advertisingAnimation: advertisingAnimation,
                },
            },
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        if (rowData.key_project === undefined || oneShop)
                            toast.success(
                                `${t("ORB configuration saved successfully")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        setIsModalConfirmationConfigurationOrb(
                            !isModalConfirmationConfigurationOrb
                        );
                        setIsDataUpdated(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isModalConfirmationConfigurationOrb}
                style={{
                    maxWidth: "650px",
                    maxHeight: "500px",
                    marginTop: "300px",
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalConfirmationConfigurationOrb(
                            !isModalConfirmationConfigurationOrb
                        );
                        setIsDataUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>{t("Alert")}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{
                            font: "normal normal normal 20px/27px Segoe UI",
                            whiteSpace: "pre-line",
                            opacity: "1",
                            textAlign: "left",
                            color: "#000000",
                        }}
                    >
                        {t(
                            "Attention, if you click on validate, the specific configuration of the shops will be overwritten."
                        )}
                        !
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalConfirmationConfigurationOrb(
                                !isModalConfirmationConfigurationOrb
                            );
                            setIsDataUpdated(!isDataUpdated);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            if (rowData.key_project !== undefined && !oneShop) {
                                handleUpdateShopButtonOnClickEvent();
                            } else if (
                                rowData.key_project === undefined &&
                                !oneShop
                            ) {
                                rowData.subRows.forEach((el: any) => {
                                    handleApplicationValidateButtonClickEvent(
                                        rowData.id_boutique,
                                        el.appId,
                                        rowData.projectId
                                    );
                                });
                            } else if (oneShop) {
                                rowData.subRows.forEach((el: any) => {
                                    handleApplicationValidateButtonClickEvent(
                                        rowData.id_boutique,
                                        el.appId,
                                        rowData.projectId
                                    );
                                    handleUpdateAssociateShopButtonOnClickEvent(
                                        el.shopId,
                                        rowData.projectId
                                    );
                                });
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
