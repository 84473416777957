import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";
import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import { store as logo } from "../../../../components/VerticalLayout/store";
import store, {
    setIsHistoryShopsModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedLogo,
    setIsLoading,
    setKey,
} from "../store";
import ErrorToast from "@components/Common/ErrorTost";

export function CustomIcons({
    selectedRowData,
    setDataIsUpdated,
    isLogoArchived,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        logoData,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(logo);

    const [choiceState, setLogoState] = React.useState<boolean>(
        selectedRowData.selectedRow?.isActive
    );
    const [isClicked, setIsClicked] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    function ArchivedLogo() {
        let idsAssociated: any[] = [];
        if (logoData !== undefined) {
            logoData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedLogo();

    async function handleStateChange(dataState: boolean) {
        let id = selectedRowData?.selectedRow.Id;
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData?.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,
                    isArchived: selectedRowData?.selectedRow.isArchived,
                    isActive: dataState,
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],
                    img: selectedRowData?.selectedRow.img,
                },
            },
        };
        const isStateChange = dataState;
        const data = new FormData();
        data.append("dataLogo", JSON.stringify({ dataLogo, isStateChange }));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",

            body: data,
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !choiceState
                            ? `${t("logo has been successfully activated")}`
                            : `${t("logo has been successfully deactivated")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActiveLogo(selectedRowData: any) {
        setIsClicked(false);
        toast.dismiss();
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,
            data: {
                [selectedRowData.selectedRow.Id]: {
                    designation: selectedRowData.selectedRow[t("Designation")],
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],

                    shopId: selectedRowData.selectedRow.shopId,

                    tag: selectedRowData.selectedRow.tag,
                    isActive: false,
                    isArchived: false,
                    img: selectedRowData?.selectedRow.img,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",

            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("logo has been successfully activated")!}`,

                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(uuid());
                        setDataIsUpdated!(true);
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                        setIsClicked(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    async function handleArchiveLogo(selectedRowData: any) {
        setIsLoading(true);
        setIsClicked(false);
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData?.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData?.Id]: {
                    designation: selectedRowData[t("Designation")],
                    support_de_vente: selectedRowData[t("Sales support")],
                    urlImage: selectedRowData[t("Image")],

                    shopId: selectedRowData?.shopId,

                    tag: selectedRowData?.tag,
                    isActive: false,
                    isArchived: true,
                    img: selectedRowData?.img,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;

        const requestOptions = {
            method: "POST",
            body: data,
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(`${t("The logo is successfully archived")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });
                    setIsLoading(false);
                    setKey(uuid());
                    setDataIsUpdated!(true);
                    setNumberActiveMultiShop(numberActiveMultiShop - 1);
                    setNumberArchiveMultiShop(numberArchiveMultiShop + 1);
                    setIsClicked(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRowData.selectedRow[t("Sales support")]
                            .split(",")
                            .forEach((support: any) => {
                                if (
                                    elt.subRows.some(
                                        (sale: any) =>
                                            `${sale[t("Support de vente")]} ${
                                                sale.shopId
                                            }` ===
                                            `${support} ${selectedRowData.selectedRow.shopId}`
                                    )
                                ) {
                                    isSaleSupportArchived = true;
                                }
                            });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return isSaleSupportArchived;
    }

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isLogoArchived
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setLogoState(!choiceState);

                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-2 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isLogoArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isLogoArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsLoading(true);
                                                handleArchiveLogo(
                                                    selectedRowData?.selectedRow
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading || isClicked,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            selectedRowData.selectedRow.Id
                                        )
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                let isArchived = await isSaleSupportArchived();

                                                if (isArchived === true) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    handleActiveLogo(
                                                        selectedRowData
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        dataArchive.includes(
                                                            selectedRowData
                                                                .selectedRow.Id
                                                        ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );

                                    setSelectedLogo([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
