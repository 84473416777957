import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import { store, setIsEdited, setModify, setIsActionsClicked, setActions, setValidationAction } from "./store";


import CustomIcons from "./CustomIcons";
import CustomSideFilter from "./CustomSideFilter";
import CityForm from "./CityForm";

import { getPathAuthorization } from "@components/VerticalLayout";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";

export default function City({ selectedRowShops }: any) {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any>({});
  
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
 
    let dataLivraison =selectedRowShops.length>0 && JSON.parse(selectedRowShops[0][t("minimum price")]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isAddNewDeviseButtonClicked,
        setIsAddNewDeviseButtonClicked,
    ] = React.useState(false);
 
    const {
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(store);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddCity,
        setIsAddCity,
    ] = React.useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let schema: any = localStorage.getItem("schemaFranchise");
    let listTva: any[];
    function list() {
        let list: any[] = [];
        for (let el of selectedRowShops[0]["list Of VatRate"]) {
            list.push(el.value);
        }
        return list;
    }
    listTva = list();
    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    const urlApi = `${process.env.REACT_APP_API_V2_URL}/settings/list/city/c&c?schema=${schema}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setSelectedRows={setSelectedRows}
                    setIsAddCity={setIsAddCity}
                    isAddCity={isAddCity}
                    selectedRowShops={selectedRowShops}
                    setDataIsUpdated={setDataIsUpdated}
                    dataLivraison={dataLivraison}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 3,
            columnName: t("Tva"),
            customJsx: (e: any) => {
                return (
                    <span className="preview-text">
                        {listTva.includes(`${e.selectedRow[t("Tva")]}`)
                            ? `${e.selectedRow[t("Tva")]}`
                            : "0"}
                    </span>
                );
            },
        },
    ];
    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setDataIsUpdated(true);
        }
    }, [i18nextLng]);
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: "350px",
                    gridTemplateColumns: isAddCity ? "2fr 1fr " : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={urlApi}
                    showFilter
                    canSelect={true}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setIsAddCity={setIsAddCity}
                            isAddCity={isAddCity}
                            setLocalFilterActive={setLocalFilterActive}
                            setSelectedRows={setSelectedRows}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            isEdited={isEdited}
                            selectedRows={selectedRows}
                        />
                    }
                    showGlobalFilter
                    canExpand={false}
                    elevationTable={1}
                    minHeight={"350px"}
                    maxHeight={"400px"}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    setSelectedRows={setSelectedRows}
                    name="Choice"
                />
                {isAddCity ? (
                    <CityForm
                        isAddCity={isAddCity}
                        setIsAddCity={setIsAddCity!}
                        setDataIsUpdated={setDataIsUpdated}
                        setLocalFilterActive={setLocalFilterActive}
                        isAddNewDeviseButtonClicked={
                            isAddNewDeviseButtonClicked
                        }
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedRowShops={selectedRowShops}
                        list={listTva}
                        dataLivraison={dataLivraison}
                    />
                ) : null}
                {isActionsClicked === true ? (
                    <ConfirmationUpdatingDataMessageModal
                        isModalOpened={isActionsClicked}
                        setIsModalOpened={setIsActionsClicked}
                        validationAction={validationAction}
                        actions={actions}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewDeviseButtonClicked}
                        isClicked={isAddNewDeviseButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}

                <ToastContainer limit={1} />
            </div>
        </React.Fragment>
    );
}
