import React from "react";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";

import {
    generalConfigStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";
import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";

type PropsType = {
    item: ActionsButtonsDesignItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ActionsButtonsDesignItemType[]>
    >;
};

export function DesignButtonItemWrapper({
    item,
    index,
    setItems,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { files: images },
        imageKey,
        isResetOneImageButtonClicked,
        selectedActionsButtons1Language,
    } = useSnapshot(generalConfigStore);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        item.id,
        images,
        selectedActionsButtons1Language
    );
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localContent = image.content as ImageItemLanguagesType;
            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [selectedActionsButtons1Language as string]: {
                        ...localContent[
                            selectedActionsButtons1Language as string
                        ],
                        path: localContent[
                            selectedActionsButtons1Language as string
                        ].defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }

        setProjectFiles(imagesArray);
        setCardImageKey(uuid());
    }

    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            key={index}
            style={{ gap: "10px", margin: "auto" }}
        >
            <div
                className="d-flex align-items-center text-muted"
                style={{
                    gap: "10px",
                    textAlign: "left",
                    color: "#B2B2B2",
                    marginLeft: "5px",
                }}
            >
                {t(item.name)}
            </div>

            <Card
                className={`ksk_icn_expertTakeOrderDesign${item.name
                    .split(" ")
                    .map((word, i) => {
                        return (
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        );
                    })
                    .join("")}`}
                style={{
                    width: "262px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: "50px 100px auto",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent(item.id);
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} style={{}} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    {imageSrc.includes("png") ||
                    imageSrc.includes("webp") ||
                    imageSrc.includes("jpg") ? (
                        <img
                            alt="img"
                            src={imageSrc}
                            style={{
                                width: [
                                    "Customer account",
                                    "Allergen",
                                ].includes(item.name)
                                    ? "140px"
                                    : "80px",
                                height: "80px",
                            }}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={imageContent as string}
                            width={
                                ["Customer account", "Allergen"].includes(
                                    item.name
                                )
                                    ? "230px"
                                    : "120px"
                            }
                            height={"120px"}
                            selectedLanguage={selectedActionsButtons1Language}
                        />
                    )}
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <StyledIconButton
                        style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                    >
                        <Uploader
                            key={
                                isResetOneImageButtonClicked === false
                                    ? imageKey
                                    : cardImageKey
                            }
                            id={item.id}
                            activeLanguage={selectedActionsButtons1Language}
                        />
                    </StyledIconButton>
                </div>
            </Card>
        </div>
    );
}
