import React from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
    StyledModal,
    StyledButton,
    StyledLabel,
    CheckboxIcon,
    StyledH2,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import { capitalize } from "../../../helpers/general";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

export function ConfirmationModalArchived({
    isConfirmationModalArchived,
    setIsConfirmationModalArchived,
    setIsDataUpdated,
    selectedRows,
}: {
    isConfirmationModalArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsDataUpdated: Function;
    selectedRows: any;
}) {
    const { t } = useTranslation();
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        useStore
    );

    async function archive() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco`;
        const appId: string = "SCO";
        let data = {
            userId: userID,
            shopId: oneShop ? shopID : selectedRows[0]?.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            type: "Archivage",
            data: {
                [appId]: {
                    id_application: appId,
                    data_app: {
                        [selectedRows[0]?.appId]: {
                            id_application_children: selectedRows[0]?.appId,
                            printer: selectedRows[0].printer,
                            designation: selectedRows[0][t("Application")],
                            ip: selectedRows[0][t("Address IP")],
                            note: selectedRows[0][t("Note")],
                            isArchived: true,
                        },
                    },
                },
            },
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t("This application was successfully archived")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsConfirmationModalArchived(
                        !isConfirmationModalArchived
                    );
                    setIsDataUpdated!(true);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function archivedApplications() {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco`;
        const appId: string = "SCO";
        let localData: any = [];
        selectedRows.forEach((row: any) => {
            if (row.appId !== undefined) {
                let data = {
                    userId: userID,
                    shopId: oneShop ? shopID : row.shopId,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    type: "Archivage",
                    data: {
                        [appId]: {
                            id_application: appId,
                            data_app: {
                                [row?.appId]: {
                                    id_application_children: row?.appId,
                                    printer: row.printer,
                                    designation: row[t("Application")],
                                    ip: row[t("Address IP")],
                                    note: row[t("Note")],
                                    isArchived: true,
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t(
                                "This application was successfully archived"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsConfirmationModalArchived(
                            !isConfirmationModalArchived
                        );
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isConfirmationModalArchived!}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsConfirmationModalArchived(
                        !isConfirmationModalArchived
                    );
                    setIsDataUpdated(true);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Do you want to confirm the archiving of the selected application?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsConfirmationModalArchived(
                            !isConfirmationModalArchived
                        );
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <StyledButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        selectedRows.length > 1
                            ? archivedApplications()
                            : archive();
                    }}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
