import React from "react";
import classNames from "classnames";

type PropsType = {
    className?: string;
    gap?: string;
    alignItemsCentered?: boolean;
    justifyContentCentered?: boolean;
    children: React.ReactNode;
    styles?: {
        [key: string]: string;
    };
};

export function FlexboxGrid({
    className,
    gap,
    alignItemsCentered,
    justifyContentCentered,
    children,
    styles,
}: PropsType): JSX.Element {
    return (
        <div
            className={classNames(
                `d-flex ${className !== undefined ? className : ""}`,
                {
                    "align-items-center": alignItemsCentered === true,
                    "justify-content-center": justifyContentCentered === true,
                }
            )}
            style={{
                gap,
                ...styles,
            }}
        >
            {children}
        </div>
    );
}
