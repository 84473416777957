import React, { useEffect } from "react";
import { TrashIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { MdCloudUpload } from "react-icons/md";
import { Card } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { setInputImageUploader } from "../store";

import "./index.css";

type ImageUploaderPropsType = {
    uploadedImage: string | Blob;
    setUploadedImage: React.Dispatch<React.SetStateAction<string | Blob>>;
    id?: string;
    inputImage: boolean;
    setIsEdited: Function;
    setImg: Function;
    isJpgOrPng: boolean;
    setInputImage: Function;
    rowData: any;
    oneShop: boolean;
    inputImageUploader: boolean;
};

export function ImageUploader({
    uploadedImage,
    setUploadedImage,
    inputImage,
    id = "",
    setIsEdited,
    setImg,
    isJpgOrPng,
    setInputImage,
    rowData,
    oneShop,
    inputImageUploader,
}: ImageUploaderPropsType) {
    const [dragging, setDragging] = React.useState(false);
    const { t } = useTranslation();

    function onFileChange(e: any) {
        setImg(e.target.files[0].type);
        setUploadedImage(e.target.files[0]);
        setIsEdited(true);

        setInputImage(false);

        if (!isJpgOrPng) {
            setInputImageUploader(true);
        } else {
            setInputImageUploader(false);
        }
    }

    const handleClick = () => {
        setUploadedImage("");
        setIsEdited(true);
        setInputImageUploader(false);
        setInputImage(false);
    };
    useEffect(() => {
        if (!isJpgOrPng) {
            setInputImageUploader(true);
        } else {
            setInputImageUploader(false);
        }
    }, [isJpgOrPng]);

    return (
        <div
            className="d-flex flex-wrap justify-content-center align-items-center"
            style={{
                border: dragging ? "dashed grey 4px" : "unset",
                borderRadius: "6px",
                height: "100%",
                width: "auto",
                padding: "1rem",
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setDragging(true);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    e.dataTransfer.files[0].type !== "image/png" &&
                    e.dataTransfer.files[0].type !== "image/jpeg" &&
                    e.dataTransfer.files[0].type !== "image/jpg"
                        ? toast.error("Only images are accepted")
                        : setUploadedImage(e.dataTransfer.files[0]);
                }
                setDragging(false);
            }}
        >
            <Card
                className={classnames("uploader__clz lgo_inp_FRlogo", {
                    "not-allowed-input__clz":
                        oneShop && rowData.Id !== undefined,
                })}
                style={{
                    borderColor: inputImage || inputImageUploader ? "red" : "",
                }}
            >
                {uploadedImage ? (
                    <React.Fragment>
                        <div
                            className=" d-flex justify-content-center align-items-center"
                            style={{
                                height: "77%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={
                                    typeof uploadedImage === "string"
                                        ? uploadedImage
                                        : URL.createObjectURL(uploadedImage)
                                }
                                width="77%"
                                height="100%"
                                alt=""
                            />
                        </div>
                        <div className=" d-flex justify-content-around">
                            <label
                                htmlFor={`file-${id}`}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <input
                                    type="file"
                                    id={`file-${id}`}
                                    name="imgCollection"
                                    onChange={onFileChange}
                                    accept="image/*"
                                    style={{
                                        display: "none",
                                    }}
                                />
                            </label>
                            <TrashIcon
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                fill="red"
                                onClick={handleClick}
                                className="mt-2"
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <label htmlFor={`file-${id}`} style={{ cursor: "pointer" }}>
                        <MdCloudUpload
                            style={{
                                width: "139px",
                                height: "165px",
                                minHeight: "95px",
                                maxHeight: "106px",
                                color: "rgba(81, 77, 77, 0.85)",
                            }}
                            className="ml-5"
                        />
                        <div style={{ marginTop: 8 }}>
                            <span
                                style={{
                                    font: "normal normal 900 18px/22px Lato",
                                    color: "#2B2828",
                                }}
                            >
                                {t("Drag and drop your files here or")}
                            </span>
                        </div>

                        <input
                            type="file"
                            id={`file-${id}`}
                            name="imgCollection"
                            onChange={onFileChange}
                            accept="image/png,image/jpg, image/jpeg"
                            style={{ display: "none" }}
                        />
                    </label>
                )}
            </Card>
        </div>
    );
}
