import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { setIsClicked } from "@pages/Applications/DeviceAssociateModal/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    apiUrl: string;
    setDataIsUpdated?: Function;
    data?: any;
    message: string;
    text: any;
    setGlobalDataIsUpdated?: Function;
    setKey?: Function;
    setIsLoading?: Function;
    appMessage?: any;
};

export function ConfirmationDeleteMessage({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    apiUrl,
    data,
    message,
    text,
    setGlobalDataIsUpdated,
    setKey,
    setIsLoading,
    appMessage,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();

    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: data.shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleRemoveButtonOnClickEvent() {
        setIsLoading && setIsLoading(true);
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);

            toast.dismiss();
            if (setGlobalDataIsUpdated !== undefined)
                setGlobalDataIsUpdated!(true);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        method: "DELETE",
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while delete selection!");
                            }
                            toast.success(message, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            });
                            setKey && setKey(uuid());
                            setIsLoading && setIsLoading(false);
                            setDataIsUpdated!(true);
                            if (setGlobalDataIsUpdated !== undefined)
                                setGlobalDataIsUpdated!(true);
                            setIsClicked(false);
                            setIsValidateButtonDisabled(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function RedisPublishApplication() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/redis`;
        let dataObject: any = [
            {
                shopId: data.shopId,
                appId: data.appId_children[0],
            },
        ];
        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(dataObject),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {text}
                    {appMessage}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="afa_btn_cancelAlert"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 afa_btn_confirmAlert"
                    variant="primary"
                    onClick={async () => {
                        await handleRemoveButtonOnClickEvent();
                        setIsModalOpened(false);
                        if (
                            text ===
                                t("Please confirm unpairing this device") ||
                            text ===
                                t(
                                    "Please confirm unpairing of the application"
                                ) ||
                            text ===
                                t(
                                    "Do you want to confirm the dissociation of the selected application?"
                                )
                        ) {
                            await RedisPublishPeripheral();
                        } else if (
                            text ===
                                t(
                                    "Do you want to confirm the archiving of the selected application?"
                                ) &&
                            appMessage.props.children !== null &&
                            appMessage.props.children !== undefined
                        ) {
                            await RedisPublishApplication();
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
