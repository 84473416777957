import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";

import {
    generalConfigStore,
    setOrderTakingBackground,
    setImageKey,
    setIsResetOneImageButtonClicked,
    setProjectFiles,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { Uploader } from "@components/Uploader";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";

import { DesignButtonItemWrapper } from "./DesignButtonItemWrapper";
import { LanguagesSelectWrapper } from "./LanguagesSelectWrapper";

import "simplebar/src/simplebar.css";

export function DesignButtonsTabs(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                pages: {
                    ways: { orderTaking },
                },
            },
            files: images,
        },
        selectedActionsButtons1Language,
        isResetModalOpened,
        imageKey,
        isResetOneImageButtonClicked,
    } = useSnapshot(generalConfigStore);

    const [designButtonsItems, setDesignButtonsItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >(
        ((orderTaking as OrderTakingPageType)
            .design as ActionsButtonsDesignType).items
    );
    const [background, setBackground] = React.useState<BackgroundType>(
        (orderTaking as OrderTakingPageType).background as BackgroundType
    );
    const [cardImageKey, setCardImageKey] = React.useState<string>("");
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    const data = images.filter((image) => image.id === background.id)[0];

    const imageContent = getImageContentById(
        background.id as string,
        images,
        selectedActionsButtons1Language
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    const possibleExtensions = [
        "mp4",
        "mov",
        "avi",
        "flv",
        "wmv",
        "gif",
        "webm",
    ];

    const extension = imageSrc.split(".")[imageSrc.split(".").length - 1];

    function handleUploadImageOnClickEvent(id: string, data: ImageItemType) {
        setBackground(
            (prevState: BackgroundType): BackgroundType => {
                const newState = { ...prevState };
                if (newState.id === id) {
                    return {
                        ...newState,
                        content: data.content as string,
                        //type: data.type as string,
                    };
                }
                setOrderTakingBackground(newState);
                return newState;
            }
        );
    }
    function handleResetOnClickEvent() {
        const imagesArray = [...images];
        designButtonsItems
            .map((item: ActionsButtonsDesignItemType) => item.id)
            .forEach((id: string) => {
                const image = imagesArray.find((element) => element.id === id);
                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [selectedActionsButtons1Language]: {
                                ...localContent[
                                    selectedActionsButtons1Language
                                ],
                                path:
                                    localContent[
                                        selectedActionsButtons1Language
                                    ].defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        const backgroundObject = imagesArray.find(
            (element) => element.id === background.id
        );

        if (backgroundObject !== undefined) {
            const imageIndex = imagesArray.indexOf(backgroundObject);

            imagesArray[imageIndex] = {
                ...backgroundObject,
                content: imagesArray[imageIndex].defaultImage as string,
            };
        }
        setProjectFiles(imagesArray);
        setImageKey(uuid());
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,
                content: imagesArray[imageIndex].defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setCardImageKey(uuid());
    }
    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column cursor__clz"
                style={{ gap: "10px" }}
            >
                <div
                    className="d-flex flex-column mt-3"
                    style={{ gap: "10px" }}
                >
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Design")}
                    </span>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <LanguagesSelectWrapper />
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            setLocalIsResetModalOpened(
                                !localIsResetModalOpened
                            );
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>

                <div
                    className="mx-3 d-flex justify-content-center align-items-center"
                    style={{
                        gap: "40px",
                        flexWrap: "wrap",
                        marginBottom: "1vh",
                    }}
                >
                    {designButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => (
                            <DesignButtonItemWrapper
                                item={item}
                                index={index}
                                key={index}
                                setItems={setDesignButtonsItems}
                            />
                        )
                    )}

                    <div
                        className="d-flex flex-column justify-content-center cursor__clz"
                        style={{ gap: "10px", margin: "auto" }}
                    >
                        <div
                            className="d-flex align-items-center text-muted"
                            style={{
                                gap: "10px",
                                textAlign: "left",
                                color: "#B2B2B2",
                                marginLeft: "5px",
                            }}
                        >
                            {t("General background")}
                        </div>

                        <Card
                            className="ksk_icn_expertTakeOrderDesignGeneralBackground"
                            style={{
                                width: "262px",
                                borderRadius: "20px",
                                display: "grid",
                                gridTemplateRows: "50px 100px auto",
                            }}
                        >
                            <div className="d-flex justify-content-end">
                                <StyledIconButton
                                    title={t("Reset to default image")}
                                    outline={true}
                                    variant="danger"
                                    className="m-0 p-0"
                                    icon="RefreshIcon"
                                    style={{ height: "20%", width: "20px" }}
                                    onClick={() => {
                                        handleResetImagesOnClickEvent(
                                            background.id as string
                                        );
                                        setIsResetOneImageButtonClicked(true);
                                    }}
                                >
                                    <RefreshIcon height={20} width={20} />
                                </StyledIconButton>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                {possibleExtensions.includes(extension) ? (
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        style={{
                                            width: "80px",
                                            height: "80px",
                                            objectFit: "fill",
                                            borderRadius: "5px",
                                            boxShadow: "0 0 3px gainsboro",
                                        }}
                                        src={imageSrc}
                                    />
                                ) : (
                                    <img
                                        alt="img"
                                        src={imageSrc}
                                        style={{
                                            width: "80px",
                                            height: "80px",
                                            objectFit: "fill",
                                            borderRadius: "1px",
                                            boxShadow: "0 0 3px gainsboro",
                                        }}
                                    />
                                )}
                            </div>

                            <div
                                className="d-flex justify-content-center align-items-center"
                                onClick={() =>
                                    handleUploadImageOnClickEvent(
                                        background.id as string,
                                        data
                                    )
                                }
                            >
                                <StyledIconButton
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        boxShadow: "0px 3px 6px #00000029",
                                    }}
                                >
                                    <Uploader
                                        key={
                                            isResetOneImageButtonClicked ===
                                            false
                                                ? imageKey
                                                : cardImageKey
                                        }
                                        id={background.id}
                                        activeLanguage={
                                            selectedActionsButtons1Language
                                        }
                                    />
                                </StyledIconButton>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
