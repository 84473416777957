import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";
import { mutate } from "swr";
import { toast } from "react-toastify";
import moment from "moment";

import { setIsCreatedCoping } from "../../../store/project/index";
import { store as userStore } from "@components/VerticalLayout/store";
import { setIsLoading } from "../../../store/project";
import { setKey } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type CardFooterCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
    dataProject: any;
    setSelectedRows: Function;
    designation: string;
};

export default function CardFooterCreatedCoping({
    rowData,
    setIsDataUpdated,
    dataProject,
    setSelectedRows,
    designation,
}: CardFooterCreatedCopingType) {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        oneShop,
        shopID,
        shopName,
        operatorID,
    } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    function project() {
        let project: any = [];
        if (dataProject !== undefined) {
            dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
                if (el.projectId === rowData.projectId) {
                    project.push(el);
                }
            });
        }
        project = [...new Set(project)];
        return project;
    }

    async function handleDuplicate() {
        toast.info(
            t(
                "Please note that the template has been updated in accordance with the general settings."
            ),
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
        setIsLoading(true);
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/duplicate`;
        let projectId = uuid();
        let projects: any = project();
        let associate: any = {};
        if (!oneShop) {
            rowData.subRows.forEach(async (element: any) => {
                associate[element.id_boutique] = {
                    designation: element[t("Designation")],
                    id_boutique: element.id_boutique,
                };
            });
        } else {
            associate[shopID] = {
                designation: shopName,
                id_boutique: shopID,
                hoursOfUse: "",
            };
        }
        let savedData = {
            userId: userID,
            shopId: "0",
            franchiseId: franchiseID,
            etat: "Dupplication",
            operatorId: operatorID,
            uuidUser: uuidUser,
            itemProject: projects[0].projectId,
            designationProject: projects[0][t("Designation")],
            data: {
                PROJECT: {
                    project_id: "PROJECT",
                    data_project: {
                        [projectId]: {
                            projectId: projectId,
                            designation: designation,
                            associates: associate,
                            isArchived: false,
                            note: projects[0][t("Remark")],
                            nombre: projects[0].Nombre,
                            status: "en attente",
                            modified_at: `${moment().format(
                                "DD/MM/YY - HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                            name: designation,
                            "Template type": "",
                        },
                    },
                },
            },
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({ projectObject: savedData }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(
                            `${t("The project is copied successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(Math.floor(Math.random() * 10000));
                    })
            );
        } catch (e: any) {}
    }
    return (
        <ModalFooter className="border-top-0">
            <CustomSecondaryColorButton
                outline
                variant="light"
                onClick={() => {
                    setIsCreatedCoping(false);
                    setSelectedRows([]);
                }}
                rounded
            >
                {t("Cancel")}
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                className="mr-2"
                variant="primary"
                onClick={() => {
                    handleDuplicate();
                    setIsCreatedCoping(false);
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
