import { CardWrapperSpecific } from "./CardWrapperSpecific";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { setItemSalesMethods } from "../store";


export default function SalesMethods({ selectedRows }: any) {
    const { t } = useTranslation();
    const [items, setItems] = React.useState<any>([
        {
            name: t("On the spot"),
            active:
             selectedRows.length > 0
                    ? selectedRows[0][("On the spot")]!==undefined?selectedRows[0][("On the spot")]:false
                    : false,
            image: "./images/atTheCounter.png",
        },
        {
            name: t("To take"),
            active: selectedRows.length > 0
            ? selectedRows[0][("To take")]!==undefined?selectedRows[0][("To take")]:false
            :false,
            image: "./images/toTakeAway.png",
        },
        {
            name: t("Delivery"),
            active:    selectedRows.length > 0
            ? selectedRows[0][("Delivery")]!==undefined?selectedRows[0][("Delivery")]:false
            : false,
            image: "./images/delivery.png",
        },
    ]);
    useEffect(()=>{
        setItemSalesMethods(items)
      },[items])
    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font:  "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-3"
            >
                {t("Sales methods choice")}
            </StyledH2>
            <div
                className="d-flex flex-row justify-content-center align-items-center mt-5"
                style={{ gap: "100px" }}
            >
                {items.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={items}
                        index={index}
                        setItems={setItems}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}
