import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    StyledButton,
    StyledDangerButton,
    StyledH2,
    StyledLabel,
    StyledModal,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { capitalize } from "@helpers/general";

type SupportCodeModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    codeSupport: string;
    setCodeSupport: Function;
};
export function SupportCodeModal({
    isModalOpened,
    setIsModalOpened,
    codeSupport,
    setCodeSupport,
}: SupportCodeModalType) {
    const { t } = useTranslation();

    return (
        <StyledModal
            isOpen={isModalOpened}
            centered
            className="supportCode__clz"
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Modification mode"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel htmlFor="text" style={{ marginLeft: "100px" }}>
                    {t("Support code")}
                </StyledLabel>
                <StyledTextInput
                    id="example-input"
                    name="example-input"
                    placeholder="Insert text"
                    autoComplete="off"
                    value={codeSupport}
                    onChange={(e: any) => setCodeSupport(e.target.value)}
                    style={{ width: "250px", marginLeft: "100px" }}
                    min={1}
                />
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <StyledDangerButton
                    outline
                    variant="danger"
                    onClick={() => {
                        setIsModalOpened(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </StyledDangerButton>
                <StyledButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(false);
                    }}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
