import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store,setPosEditor as setPosEditorType  } from "../../../../components/VerticalLayout/store";
import {
    store as saleStore,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIdsLocalShop,
    setShopsPosEditorData,
    setRowsShop,
    setAssociatedPosEditor,
    setGlobalDataIsUpdated,
    setSelectedPosEditor,
} from "../store";

import { ActionColumn } from "./ActionColumn";
import { ConfirmationActivePosEditor } from "../ConfirmationActivePosEditor";
import { PosEditorAssociateModal } from "./PosEditorAssociateModal";
import { CustomIcons, redisPosEditor } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import InformationPosEditor from "../InformationPosEditor";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import BadgeNumber from "@components/Common/BadgeNumber";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import RefreshPosIcon from "@components/Common/SvgIcons/RefreshPosIcon";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { POS_EDITOR_CESAR } from "@constants/index";

export default function ShopsPosEditor({
    isPosEditorArchived,
    setIsPosEditorArchived,
    setLimit,
}: any) {
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        globalDataIsUpdated,
        isConsultModalOpened,
        modify,
    } = useSnapshot(saleStore);

    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [dataTable, setDataTable] = React.useState<any>({});

    const [i18nextLng] = React.useState<string>(
        localStorage.getItem("i18nextLng") || "fr"
    );
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Pos Editor"),
            customJsx: (e: any) =>
                e.selectedRow[t("Shop")] !== undefined ? (
                    <BadgeNumber value={e.selectedRow?.subRows?.length} />
                ) : (
                    e.selectedRow[t("Pos Editor")]
                ),
            disableFilter: false,
        },
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isPosEditorArchived={isPosEditorArchived}
                    selectedRows={selectedRows}
                    type={e.selectedRow[t("Pos Editor")]}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedPosEditor: any[] = [];
        Object.values(selectedRows).forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);
            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
                associatedPosEditor.push(el.subRows[i]);
            }
        });
        setAssociatedPosEditor(associatedPosEditor);
        setRowsShop(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsPosEditorData(dataTable);
    }, [selectedRows, dataTable, t]);
    React.useEffect(() => {
        setGlobalDataIsUpdated(true);
    }, [i18nextLng]);
    React.useEffect(() => {
        let localArchived: number = 0;
        let localActive: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element?.Boutique !== undefined) {
                    localActive += element?.PosEditorNumber.activated;

                    localArchived += element?.PosEditorNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el.Boutique).length > 0) {
                setNumberActiveMultiShop(localActive);

                setNumberArchiveMultiShop(localArchived);
            } else {
                setNumberActiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.activated
                        : 0
                );

                setNumberArchiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.archived
                        : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.activated
                    : 0
            );

            setNumberArchiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.archived
                    : 0
            );
        }
        if (
         
            dataTable !== undefined &&
            dataTable.data !== undefined &&
            dataTable.data.length > 0
        ) {
            setPosEditorType(dataTable.data[0].value);
        }else{
            setPosEditorType(POS_EDITOR_CESAR);
        }

    }, [dataTable.othersData, selectedRows, dataTable]);

    React.useEffect(() => {
        let array: any = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });

        setSelectedPosEditor(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);
    const ActionColumnList = (e: any) => {
        return [undefined, ""].indexOf(e?.selectedRow?.original[t("Shop")]) !==
            -1
            ? modify === true && (
                  <div
                      style={{
                          cursor: "pointer",
                      }}
                      className={`${classnames("dropdown pt-2", {
                          "not-allowed-icon__clz":
                              isPosEditorArchived ||
                              e?.selectedRow?.original[t("Pos Editor")] !==
                                  "Cashpad",
                      })}`}
                  >
                      <VerticalDotsIcon
                          id="dropdownMenuButton1"
                          className="cmn_drp_BTmenu"
                          data-bs-toggle="dropdown"
                          style={{
                              cursor: "pointer",
                              position: "relative",
                              top: "-5px !important",
                          }}
                          height={20}
                          width={20}
                          fill="black"
                      />
                      <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                      >
                          <div
                              className="dropdown-item pl-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                  redisPosEditor(
                                      e.selectedRow.original.shopId,
                                      t
                                  );
                              }}
                          >
                              <span className=" cmn_btn_BTlistActivated">
                                  <RefreshPosIcon
                                      height={50}
                                      width={50}
                                      style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                      }}
                                      className={`${classnames(
                                          "pointer__clz fed_icn_BTconsult",
                                          {
                                              "not-allowed-icon__clz": isPosEditorArchived,
                                          }
                                      )}`}
                                  />
                                  <div className="ml-5">
                                      {t("Recover Cashpad data")}
                                  </div>
                              </span>
                          </div>
                      </div>
                  </div>
              )
            : "";
    };
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isPosEditorArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    setData={setDataTable}
                    canExpand
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    <div className=" d-flex justify-content-start align-items-center">
                                        <CustomMainColorButton
                                            className={`${classnames(
                                                "w-100 ml-3 fed_btn_BTassignLoyalty",
                                                {
                                                    "not-allowed-icon__clz": isPosEditorArchived,
                                                }
                                            )}`}
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isShopSelected ? false : true
                                            }
                                            onClick={() => {
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Affect Pos Editor")}
                                        </CustomMainColorButton>
                                        <div></div>
                                    </div>
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                isPosEditorArchived={isPosEditorArchived}
                                setIsPosEditorArchived={setIsPosEditorArchived}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={ActionColumnList}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="PosEditor"
                />
            </div>

            <ConfirmationActivePosEditor
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <PosEditorAssociateModal
                    setData={setGlobalDataIsUpdated}
                    selectedShopsRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}

            {isConsultModalOpened ? (
                <InformationPosEditor
                    selectedShopsRows={selectedRows}
                    setGlobalDataIsUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
        </React.Fragment>
    );
}
