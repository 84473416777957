import React from "react";
import { useTranslation } from "react-i18next/";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { setMultiShopSwitch, store } from "@components/VerticalLayout/store";

import { CustomIconsComponent } from "./CustomIcons";
import PageTitle from "@components/Common/PageTitle";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function TestAuto() {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const { oneShop } = useSnapshot(store);

    React.useEffect(() => {}, [selectedRows]);

    // const urlApi = `${process.env.REACT_APP_ACCESS_API_URL}/entity/${uidEntity}` ;
    const urlApi = `/test-auto.json`;

        setMultiShopSwitch('8ecfe5ee-926b-423e-8050-c911dd81c150-test-auto', 'franchise_testauto');
        // eslint-disable-next-line


    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 9 : 10,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return <CustomIconsComponent e={e} />;
            },
        },
    ];
    return (
        <React.Fragment>
            <PageTitle title={t("Peripherals")} />

            <div
                className=""
                style={{
                    padding: "15px",
                }}
            >
                <h1>Choisir un compte</h1>
                <DynamicTable
                    url={urlApi}
                    canExpand={true}
                    canSelect={true}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    canResize
                    showGlobalFilter
                    elevationTable={1}
                    setSelectedRows={setSelectedRows}
                    name="Choice"
                    minHeight={"600px"}
                    maxHeight={"400px"}
                />
            </div>
        </React.Fragment>
    );
}
