import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";

import store, { setKey } from "../store";
import { store as aggregator } from "../../../../components/VerticalLayout/store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { uuid } from "uuidv4";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsAggregatorArchived,
    isAggregatorArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        archivedAggregatorFranchise,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(aggregator);

    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function archiveAggregatorFranchise() {
        let idsAssociated: any = [];
        if (
            archivedAggregatorFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedAggregatorFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };
    let arrayOfRows = getArrayOfRows();

    // function shopsIdArchiveAggregator(e: any) {
    //     let idsAssociated: any = [];
    //     let shopSelectedRows: any[] = [];
    //     selectedRows.forEach((el: any) => {
    //         if (el.shopId === e.shopId) {
    //             shopSelectedRows.push(el.shopId);
    //         }
    //     });

    //     if (shopsAggregatorData.othersData.allAggregator.data !== undefined) {
    //         shopsAggregatorData.othersData.allAggregator.data.forEach(
    //             (element: any) => {
    //                 if (
    //                     element.subRows !== undefined &&
    //                     element.subRows.length === 0 &&
    //                     ((element.archivedAggregator !== undefined &&
    //                         element.archivedAggregator.length === 1) ||
    //                         (selectedRows.subRows === undefined &&
    //                             shopSelectedRows.length === 1))
    //                 ) {
    //                     element.archivedAggregator.forEach((el: any) => {
    //                         if (el.Id === e.Id && el.shopId === e.shopId)
    //                             idsAssociated.push(el.shopId);
    //                     });
    //                 }
    //             }
    //         );
    //     }

    //     idsAssociated = [...new Set(idsAssociated)];
    //     return idsAssociated;
    // }
    async function activeAggregator() {
        toast.dismiss();
        let localData: any = [];

        let arrayOfRows = getArrayOfRows();

        arrayOfRows.forEach(async (el: any) => {
            let dataAggregator = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        aggregator: el[t("Aggregator")],
                        webhook: el[t("Webhook")],
                        note: el[t("Remark")],
                        isActive: false,
                        isArchived: false,
                        shopId: false,
                        state: false,
                        stateShop: false,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataAggregator),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                await Promise.all(localData).then((result) => {
                    setLimit(2);
                    if (error === false) {
                        toast.success(
                            `${t(
                                "The Aggregator has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setDataIsUpdated!(true);
                        setKey(uuid());
                    }
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_BTmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsAggregatorArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Aggregator Enabled(s)")} (
                                {numberActiveMultiShop})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1 "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsAggregatorArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Archived Aggregator(s)")}(
                                {numberArchiveMultiShop})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BThistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={` ${classnames(
                                        " dropdown-item pl-1 pointer__clz ",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isAggregatorArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            archiveAggregatorFranchise().includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                arrayOfRows.length === 0 ||
                                                getArrayOfRows().every(
                                                    (element: any) =>
                                                        element[t("State")] ===
                                                        true
                                                ),
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (arrayOfRows.length < 2) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            isAggregatorArchived
                                                ? activeAggregator()
                                                : setIsArchiveModalOpened(true);
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BTarchived cmn_btn_BTunarchive">
                                        {isAggregatorArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {setIsArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}
        </React.Fragment>
    );
}
