import React from "react";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import {
    setModify,
    setIsEdited,
    setIsModalDissociateOpened,
    setNumberActive,
    setNumberArchived,
    setSelectedApplicationOrb,
    store,
    setDataProject,
    setActions,
    setIsActionsClicked,
    setValidationAction,
} from "./store";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import HistoryModal from "./HistoryModal";
import { ConfirmationModalArchived } from "./ConfirmationModalArchived";
import { CustomIcons } from "./CustomIcons";
import { CustomSideFilter } from "./CustomSideFilter";
import { AddProject } from "./AddProject";
import { DropDownWrapper } from "./DropDownWrapper";
import { ConfirmationDissociateProject } from "./ConfirmationDissociateProject";
import { ConfirmationModalActived } from "./ConfirmationModalActived";
import { ConfirmationModalCopied } from "./ConfirmationModalCopied";
import ModalAssociateStore from "./ModalAssociateStore";
import { ModalWrapper } from "./ModalWrapper";
import { ModalContentWrapper } from "./ModalContentWrapper";
import ModalPublish from "./ModalPublish";
import BadgeNumber from "@components/Common/BadgeNumber";
import ModalConfirmationConfigurationOrb from "./ModalConfirmationConfigurationOrb";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

export default function ProjectSetup() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAddProject, setIsAddProject] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [isProjectArchived, setIsProjectArchived] = React.useState<boolean>(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") ?? "fr";
    const [data, setData] = React.useState<any>({});
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [selectedRows, setSelectedRows] = React.useState<any>({});
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [idStore, setIdStore] = React.useState<number>(0);
    const [
        isConfirmationModalArchived,
        setIsConfirmationModalArchived,
    ] = React.useState<boolean>(false);

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalActive,
        setIsConfirmationModalActive,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalCopied,
        setIsConfirmationModalCopied,
    ] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);
    const [
        isConfigurationModalOpened,
        setIsConfigurationModalOpened,
    ] = React.useState(false);
    const [isModalPublishOpened, setIsModalPublishOpened] = React.useState(
        false
    );
    const [
        isModalConfirmationConfigurationOrb,
        setIsModalConfirmationConfigurationOrb,
    ] = React.useState(false);

    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        useStore
    );

    const {
        isModalAssociateStore,
        isModalDissociateOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(store);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const url = oneShop
        ? `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/project?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&operatorId=${operatorID}`
        : `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/project?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&operatorId=${operatorID}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setIsDataUpdated}
                    selectedRows={selectedRows}
                    isAddProject={isAddProject}
                    setIsAddProject={setIsAddProject}
                    isProjectArchived={isProjectArchived}
                    setShopId={setShopId}
                    setLocalFilterActive={setLocalFilterActive}
                    setSelectedRows={setSelectedRows}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsConfigurationModalOpened={
                        setIsConfigurationModalOpened
                    }
                    setIdStore={setIdStore}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 1,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow[t("Designation")]}
                    />
                );
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Number/IP address"),
            customJsx: (e: any) => {
                return (
                    <span>
                        {e.selectedRow[t("Number/IP address")] !== "" &&
                        e.selectedRow["appId"] !== undefined ? (
                            e.selectedRow[t("Number/IP address")]
                        ) : e.selectedRows?.associates === undefined &&
                          e.selectedRow[t("Number/IP address")] !== "" &&
                          e.selectedRow[t("Number/IP address")] !==
                              undefined ? (
                            <BadgeNumber
                                value={e.selectedRow[t("Number/IP address")]}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                );
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Status"),
            customJsx: (e: any) => {
                return e.selectedRow.key_project !== undefined &&
                    e.selectedRow[t("Status")] === "En attente" ? (
                    !isProjectArchived ? (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge badge-pill badge-danger"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("Waiting")}
                                <br />
                                {`${e.selectedRow.hour}`}
                            </span>
                        </h4>
                    ) : (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge-secondary_clz"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("Archived")}
                                <br />
                                {`${e.selectedRow.hour}`}
                            </span>
                        </h4>
                    )
                ) : (e.selectedRow.key_project !== undefined &&
                      e.selectedRow[t("Status")]) === "Publié" ? (
                    !isProjectArchived ? (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge-success__clz"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("Published")}
                                <br />
                                {`${e.selectedRow.hour}`}
                            </span>
                        </h4>
                    ) : (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge-secondary_clz"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("Archived")}
                                <br />
                                {`${e.selectedRow.hour}`}
                            </span>
                        </h4>
                    )
                ) : null;
            },
        },
    ];
    React.useEffect(() => {
        if (filterActive) setIsAddProject(false);
    }, [filterActive]);

    React.useEffect(() => {
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            if (element.projectId !== undefined) {
                array.push({ [element.shopId]: element.projectId });
            }
        });
        setSelectedApplicationOrb(array);
    }, [selectedRows]);

    React.useEffect(() => {
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activeProjects : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archivedProjects : 0
        );
        setDataProject(data.data !== undefined ? data.data : []);
    }, [data]);

    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setIsDataUpdated(true);
        }
    }, [i18nextLng]);
    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);
    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("ORB").toUpperCase(),
                            link: "orb",
                        },
                        {
                            item: t(
                                typeDisplay !== "notArchived"
                                    ? t("Archived project").toUpperCase()
                                    : t("Project setup").toUpperCase()
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "In this section, you have the ability to configure the ORB (Order Routing Boards), allowing you to customize them according to the specific needs of your store."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: oneShop ? "650px" : "600px",
                        gridTemplateColumns: isAddProject ? "2fr 1fr " : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        name="Item_table"
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        canExpand
                        canSort
                        customSelect
                        customJsxSideFilterButton={
                            <CustomSideFilter
                                isAddProject={isAddProject}
                                setIsAddProject={setIsAddProject}
                                setLocalFilterActive={setLocalFilterActive}
                                selectedRows={selectedRows}
                                isProjectArchived={isProjectArchived}
                                setIsProjectArchived={setIsProjectArchived}
                                setIsConfirmationModalActive={
                                    setIsConfirmationModalActive
                                }
                                shopId={shopId}
                                setIsInInitializationModalOpened={
                                    setIsInInitializationModalOpened
                                }
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                setIsConfirmationModalArchived={
                                    setIsConfirmationModalArchived
                                }
                                setIsModalDissociateOpened={
                                    setIsModalDissociateOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                setTypeDisplay={setTypeDisplay}
                            />
                        }
                        canResize
                        canSelect
                        elevationTable={1}
                        setSelectedRows={setSelectedRows}
                        showGlobalFilter
                        showFilter
                        canMovedCheckboxLeftOnExpand
                        actionColumn={(e: any) => {
                            return (
                                <DropDownWrapper
                                    element={e}
                                    setSelectedRows={setSelectedRows}
                                    isProjectArchived={isProjectArchived}
                                    setIsConfirmationModalArchived={
                                        setIsConfirmationModalArchived
                                    }
                                    setIsConfirmationModalActive={
                                        setIsConfirmationModalActive
                                    }
                                    setIsConfirmationModalCopied={
                                        setIsConfirmationModalCopied
                                    }
                                    setIsConfigurationModalOpened={
                                        setIsConfigurationModalOpened
                                    }
                                    setIdStore={setIdStore}
                                    dataFranchise={
                                        data.othersData !== undefined
                                            ? data.othersData
                                            : []
                                    }
                                    setIsModalPublishOpened={
                                        setIsModalPublishOpened
                                    }
                                />
                            );
                        }}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        setDataIsUpdated={setIsDataUpdated}
                        dataIsUpdated={isDataUpdated}
                        minHeight={oneShop ? "500px" : "450px"}
                        maxHeight={oneShop ? "500px" : "450px"}
                    />
                    {isAddProject ? (
                        <AddProject
                            isAddProject={isAddProject}
                            setIsAddProject={setIsAddProject}
                            setDataIsUpdated={setIsDataUpdated}
                            isAddNewApplicationButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            data={data}
                            selectedRows={selectedRows}
                            shopId={shopId}
                        />
                    ) : null}
                    {isInInitializationModalOpened === true ? (
                        <ConfirmationInitializationMessage
                            isModalOpened={isInInitializationModalOpened}
                            setIsModalOpened={setIsInInitializationModalOpened}
                            setIsClicked={setIsAddNewApplicationButtonClicked}
                            isClicked={isAddNewApplicationButtonClicked}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    {isHistoryModalOpened ? (
                        <HistoryModal
                            ids={selectedRows}
                            setIsModalOpened={setIsHistoryModalOpened}
                            isModalOpened={isHistoryModalOpened}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isConfirmationModalArchived ? (
                        <ConfirmationModalArchived
                            setIsConfirmationModalArchived={
                                setIsConfirmationModalArchived
                            }
                            isConfirmationModalArchived={
                                isConfirmationModalArchived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfirmationModalActive ? (
                        <ConfirmationModalActived
                            setIsConfirmationModalActived={
                                setIsConfirmationModalActive
                            }
                            isConfirmationModalActived={
                                isConfirmationModalActive
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}

                    {isModalDissociateOpened ? (
                        <ConfirmationDissociateProject
                            isModalDissociateOpened={isModalDissociateOpened}
                            setIsModalDissociateOpened={
                                setIsModalDissociateOpened
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isModalAssociateStore ? (
                        <ModalAssociateStore
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isConfirmationModalCopied ? (
                        <ConfirmationModalCopied
                            setIsConfirmationModalCopied={
                                setIsConfirmationModalCopied
                            }
                            isConfirmationModalCopied={
                                isConfirmationModalCopied
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfigurationModalOpened ? (
                        <ModalWrapper
                            isConfigurationModalOpened={
                                isConfigurationModalOpened
                            }
                            setIsConfigurationModalOpened={
                                setIsConfigurationModalOpened
                            }
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            setIsModalConfirmationConfigurationOrb={
                                setIsModalConfirmationConfigurationOrb
                            }
                        >
                            <ModalContentWrapper
                                rowData={selectedRows}
                                idStore={idStore}
                            />
                        </ModalWrapper>
                    ) : null}
                    {isModalPublishOpened ? (
                        <ModalPublish
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            isModalPublishOpened={isModalPublishOpened}
                            setIsModalPublishOpened={setIsModalPublishOpened}
                        />
                    ) : null}
                    {isModalConfirmationConfigurationOrb ? (
                        <ModalConfirmationConfigurationOrb
                            setIsDataUpdated={setIsDataUpdated}
                            isDataUpdated={isDataUpdated}
                            setIsModalConfirmationConfigurationOrb={
                                setIsModalConfirmationConfigurationOrb
                            }
                            isModalConfirmationConfigurationOrb={
                                isModalConfirmationConfigurationOrb
                            }
                            rowData={selectedRows}
                        />
                    ) : null}
                    {isActionsClicked === true ? (
                        <ConfirmationUpdatingDataMessageModal
                            isModalOpened={isActionsClicked}
                            setIsModalOpened={setIsActionsClicked}
                            validationAction={validationAction}
                            actions={actions}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}
