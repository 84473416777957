import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setIsAssociateModalOpened,
    store as generalSettingStore,
    setIsValidateShopArchiveOpened,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { languages } from "@constants/index";

import { generalConfigStore, setItemsDisable } from "@store";

type CustomSideFilterType = {
    isShopSelected: boolean;
    setShopId: Function;
    selectedRows: any[];
    setDataIsUpdated: Function;
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setLocalFilterActive: Function;
    setIsArchivedLanguagesClicked: Function;
    isArchivedLanguagesClicked: boolean;
};

export function CustomSideFilter({
    isShopSelected,
    setShopId,
    selectedRows,
    setDataIsUpdated,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setLocalFilterActive,
    setIsArchivedLanguagesClicked,
    isArchivedLanguagesClicked,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const { shopID, userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        numberMultiShopActive,
        numberMultiShopArchived,
        archivedLanguageFranchise,
        modify,
        isLoading,
    } = useSnapshot(generalSettingStore);
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (archivedLanguageFranchise !== undefined) {
            archivedLanguageFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] !== undefined) {
                el.subRows.forEach((elt: any) => {
                    if (ids.indexOf(`${elt.shopId}${elt.Id}`) === -1) {
                        arrayOfRows.push(elt);
                        ids.push(`${elt.shopId}${elt.Id}`);
                    }
                });
            } else {
                if (ids.indexOf(`${el.shopId}${el.Id}`) === -1) {
                    arrayOfRows.push(el);
                    ids.push(`${el.shopId}${el.Id}`);
                }
            }
        });
        return arrayOfRows;
    };
   async function activeAllLanguage() {
       setIsLoading(true);
       toast.dismiss();
       let isSuccuss: any = [];
       let arrayOfRows = getArrayOfRows();
       const item = [...JSON.parse(JSON.stringify(itemsDisable))];
       for (let el of arrayOfRows) {
           const id = el.Id;
           let dataLanguage = {
               userId: userID,
               shopId: [el.shopId],
               franchiseId: franchiseID,
               operatorId: operatorID,
               uuidUser: uuidUser,
               isShop: false,
               data: {
                   [id]: {
                       id: id,
                       language: languages.find(
                           (lg: any) => lg.value === el.abbreviation
                       )?.label,
                       display_name: el.display_name,
                       country: el.country,
                       urlImage: el.url_image,
                       isArchived: false,
                       shopId: el.shopId,
                       isActive: false,
                       isFavorite: el.isFavorite,
                       abbreviation: el.abbreviation,
                       advancedDisplayName: el.advancedDisplayName,
                   },
               },
           };

           item.push({
               id: el.Id,
               state: false,
               shopId: el.shopId,
           });
           const data = new FormData();
           data.append("dataLanguage", JSON.stringify(dataLanguage));
           data.append("image", "");
           const requestOptions = {
               method: "POST",
               body: data,
           };
           const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
           try {
               mutate(
                   apiUrlAdd,
                   await fetch(apiUrlAdd, requestOptions)
                       .then((response) => response.json())
                       .then((data) => {
                           if (data.error === true) {
                               setError(true);
                           } else {
                               isSuccuss.push(true);
                           }
                       })
               );
           } catch (e) {
               toast.error(`${t("There's an error")}`, {
                   position: toast.POSITION.TOP_CENTER,
                   autoClose: 2000,
                   theme: "colored",
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
               });
               setError(true);
               setIsLoading(false);
           }
       }
       setItemsDisable(item);
       localStorage.setItem("itemsDisable", JSON.stringify(item));
       if (error === false && isSuccuss.length !== 0) {
           toast.success(`${t("The language was successfully unarchived")}`, {
               position: toast.POSITION.TOP_CENTER,
               theme: "colored",
               icon: <CheckboxIcon height={25} width={25} fill="white" />,
           });
           setIsLoading(false);
           setKey(uuid());
       }
   }

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className={`${classnames(
                            "lan_btn_BTassignLanguage w-100 ml-2",
                            {
                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                            }
                        )}`}
                        rounded
                        disabled={isShopSelected ? false : true}
                        variant="primary"
                        onClick={() => {
                            setIsAssociateModalOpened(true);
                            setShopId(shopID);
                            setLocalFilterActive(false);
                        }}
                    >
                        {t("Affect language")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_BTlistActivated pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedLanguagesClicked(false);
                    }}
                >
                    {t("Enabled language(s)")}({numberMultiShopActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_BTlistArchived pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedLanguagesClicked(true);
                    }}
                >
                    {t("Archived language(s)")}({numberMultiShopArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item cmn_btn_BThistory pl-1 ",
                        {
                            "not-allowed-icon__clz":
                                getArrayOfRows().length === 1 ||
                                selectedRows.length === 0 ||
                                (isArchivedLanguagesClicked &&
                                    getArrayOfRows().some((element: any) =>
                                        ArchivedLanguagesFranchise().includes(
                                            element.Id
                                        )
                                    )),
                        }
                    )}`}
                    onClick={() => {
                        if (
                            selectedRows.length === 1 &&
                            (selectedRows[0].subRows === undefined ||
                                selectedRows[0].subRows.length < 2)
                        ) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item cmn_btn_BTarchived cmn_btn_BTunarchive pl-1 ",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        getArrayOfRows().length === 0 ||
                                        selectedRows.length === 0 ||
                                        (isArchivedLanguagesClicked &&
                                            getArrayOfRows().some(
                                                (element: any) =>
                                                    ArchivedLanguagesFranchise().includes(
                                                        element.Id
                                                    )
                                            )),
                                }
                            )}`}
                            onClick={() => {
                                setIsLoading(true);
                                if (
                                    (selectedRows.length === 1 &&
                                        (selectedRows[0].subRows ===
                                            undefined ||
                                            selectedRows[0].subRows.length <
                                                2)) ||
                                    getArrayOfRows().length === 1
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                    setIsLoading(false);
                                } else {
                                    if (isArchivedLanguagesClicked) {
                                        activeAllLanguage();
                                    } else {
                                        setIsValidateShopArchiveOpened(true);
                                        setIsLoading(false);
                                    }
                                }
                            }}
                        >
                            {isArchivedLanguagesClicked
                                ? t("Unarchive")
                                : t("Archive")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
