import moment from "moment";
import { proxy } from "valtio";

type StoreType = {
    isDataUpdated: boolean;
    key: any;
    isArchivedTemplateClicked: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    initialProject: ProjectType;

};

const defaultStateStore = {
    isDataUpdated: false,
    key: 0,
    isArchivedTemplateClicked: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    initialProject: {
        projectId: 0,
        name: "",
        associates: {},
        isArchived: false,
        Status: "en attente",
        modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
        copy_status: false,
        hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                printerOptions: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            language: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
            autoLaunch: true,
        },
        files: [
            {
                name: "",
                id: "",
                content: "",
                type: "",
                timeout: 0,
                key: "",
                subName: "",
                defaultImage: "",
            },
        ],
        note: "",
        designation: "",
        hoursOfUse: "",
        shopId: "0",
    }
};



const initialStateStore = defaultStateStore;
export const store = proxy<StoreType>(initialStateStore);

export function setDataIsUpdated(isDataUpdated: boolean): void {
    store.isDataUpdated = isDataUpdated;
}

export function setKey(key: number): void {
    store.key = key;
}
export function setIsArchivedTemplateClicked(isArchivedTemplateClicked: boolean): void {
    store.isArchivedTemplateClicked = isArchivedTemplateClicked;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setInitialProject(initialProject: ProjectType): any {
    store.initialProject = initialProject;
}
