import { proxy } from "valtio";
import i18n from "i18next";

type StoreType = {
    isModalOpened: boolean;
    isConfigurationCashManagementClicked: boolean;
    displayMode: string;
    isDataUpdated: boolean;
    isModalOpenedOrders: boolean;
    duration: any;
    pattern: any;
    itemActivation: any;
    ItemSalesMethods: any;
    ItemPayementChoice: any;
    loyaltyAccount: any;
    options: any;
    orders: any;
    dataSetupApk: any;
    hourOfUse: any[];
    dataShops: any[];
    apkId: any;
    dataSite: any[];
    globalDataIsUpdated: boolean | number;
    modify: boolean;
    isButton:boolean;
    isAddCity:boolean;
    isAddZone:boolean;
    isErreur:boolean;
    isEmptyHours:boolean;
    overLaps:any,
    optionComposite:any
};

export const store = proxy<StoreType>({
    isModalOpened: false,
    isConfigurationCashManagementClicked: false,
    displayMode: i18n.t("View by app"),
    isDataUpdated: false,
    isModalOpenedOrders: false,
    duration: "",
    pattern: "",
    itemActivation: [],
    ItemSalesMethods: [],
    ItemPayementChoice: [],
    loyaltyAccount: {},
    options: {},
    orders: {},
    dataSetupApk: {},
    hourOfUse: [],
    dataShops: [],
    apkId: {},
    dataSite: [],
    globalDataIsUpdated: false,
    modify: false,
    isButton:false,
    isAddCity:false,
    isAddZone:false,
    isErreur:false,
    isEmptyHours:false,
    overLaps:[],
    optionComposite: { REGLEGRATUITE: "",
        CMD_DIFFERE: { ACITVE: false, NBR_JOUR: "0" },}
});
export function setModify(m: boolean): void {
    store.modify = m;
}
export function setOverLaps(overLaps: any): void {
    store.overLaps = overLaps;
}

export function setIsErreur(isErreur: boolean): void {
    store.isErreur = isErreur;
}
export function setIsAddCity(isAddCity: boolean): void {
    store.isAddCity = isAddCity;
}
export function setIsAddZone(isAddZone: boolean): void {
    store.isAddZone = isAddZone;
}
export function setIsEmptyHours(isEmptyHours: boolean): void {
    store.isEmptyHours = isEmptyHours;
}
export function setIsModalOpened(isModalOpened: boolean): void {
    store.isModalOpened = isModalOpened;
}
export function setDuration(duration: any): void {
    store.duration = duration;
}
export function setPattern(pattern: any): void {
    store.pattern = pattern;
}
export function setIsConfigurationCashManagementClicked(
    isConfigurationCashManagementClicked: boolean
): void {
    store.isConfigurationCashManagementClicked = isConfigurationCashManagementClicked;
}

export function setDisplayMode(displayMode: string): void {
    store.displayMode = displayMode;
}
export function setIsDataUpdated(isDataUpdated: boolean): void {
    store.isDataUpdated = isDataUpdated;
}
export function setIsModalOpenedOrders(isModalOpenedOrders: boolean): void {
    store.isModalOpenedOrders = isModalOpenedOrders;
}
export function setItemActivation(itemActivation: any): void {
    store.itemActivation = itemActivation;
}
export function setItemSalesMethods(ItemSalesMethods: any): void {
    store.ItemSalesMethods = ItemSalesMethods;
}
export function setPaymentChoice(ItemPayementChoice: any): void {
    store.ItemPayementChoice = ItemPayementChoice;
}
export function setLoyaltyAccount(loyaltyAccount: any): void {
    store.loyaltyAccount = loyaltyAccount;
}
export function setOptionComposite(optionComposite: any): void {
    store.optionComposite = optionComposite;
}
export function setOptions(options: any): void {
    store.options = options;
}
export function setHourOfUse(hourOfUse: any): void {
    store.hourOfUse = hourOfUse;
}
export function setOrders(orders: any): void {
    store.orders = orders;
}
export function setDataSetupApk(dataSetupApk: any): void {
    store.dataSetupApk = dataSetupApk;
}
export function setDataShop(dataShops: any): void {
    store.dataShops = dataShops;
}
export function setApkId(apkId: any): void {
    store.apkId = apkId;
}
export function setDataSite(dataSite: any): void {
    store.dataSite = dataSite;
}
export function setGlobalDataIsUpdated(dataIsUpdated: boolean | number): void {
    store.globalDataIsUpdated = dataIsUpdated;
}

export default store;
