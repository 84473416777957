import React from "react";
import { PlusIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import store from "./store";

import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

export default function CustomSideFilter({ isAddApk }: any) {
    const { t } = useTranslation();
    const { modify } = useSnapshot(store);
    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isAddApk !== true && modify === true ? (
                <span>
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                        disabled
                    >
                        {t("Add a APK")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : modify === true ? (
                <StyledIconButton
                    icon="PlusIcon"
                    className=" lgo_btn_FRreset"
                    rounded
                    onClick={() => {}}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </StyledIconButton>
            ) : null}
        </div>
    );
}
