import React from "react";
import {
    SettingsIcon,
    StyledLabel,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { components } from "react-select";
import { useSnapshot } from "valtio";

import { setMoneticData, store } from "./store";
import { store as userStore } from "../../../../components/VerticalLayout/store";

import {
    AdyenCurrencies,
    ipMatchWord,
    numberMatchWord,
} from "@constants/index";
import { ArrowDownIcon } from "@components/Common/SvgIcons/ArrowDownIcon";
import { SupportCodeModal } from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm/SupportCodeModal";
import { AdyenConfigurationModal } from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm/AdyenConfigurationModal";
import "react-toastify/dist/ReactToastify.css";

type AdyenConfigType = {
    setIsEdited: Function;
    adyenConfig: any;
    setAdyenConfig: Function;
    shopIds: any;
    editedData: any;
};

export function AdyenConfiguration({
    setIsEdited,
    adyenConfig,
    setAdyenConfig,
    shopIds,
    editedData,
}: AdyenConfigType) {
    const { t } = useTranslation();
    const {
        port,
        ipAddress,
        inputIp,
        ipExist,
        adyenIpAddress,
        inputAdyenIpAddress,
        mode,
        inputMode,
        isConsult,
        webhook,
        inputWebHook,
        inputPort,
    } = useSnapshot(store);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [codeSupport, setCodeSupport] = React.useState<string>("");
    const [isValidCode, setIsValidCode] = React.useState<boolean>(false);
    const [isConfig, setIsConfig] = React.useState<boolean>(false);
    const [
        isConfigurationModalOpened,
        setIsConfigurationModalOpened,
    ] = React.useState<boolean>(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : shopIds[0] ?? editedData.shopId;
    const [merchantAccount, setMerchantAccount] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.merchantAccount
            : ""
    );
    const [cryptoVersion, setCryptoVersion] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.cryptoVersion
            : ""
    );
    const [keyIdentifier, setKeyIdentifier] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.keyIdentifier
            : ""
    );
    const [passphrase, setPassphrase] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.passphrase
            : ""
    );
    const [keyVersion, setKeyVersion] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.keyVersion
            : ""
    );
    const [communication, setCommunication] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.communication
            : ""
    );
    const [currency, setCurrency] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.currency
            : ""
    );
    const [webhookConfig, setWebhookConfig] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.webhook
            : ""
    );
    const [merchantTicket, setMerchantTicket] = React.useState<boolean>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.merchantTicket
            : false
    );
    const [customerTicket, setCustomerTicket] = React.useState<boolean>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.customerTicket
            : false
    );
    const [apiKey, setApiKey] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.apiKey
            : ""
    );

    const [userName, setUserName] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.userName
            : ""
    );
    const [userPassword, setUserPassword] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.userPassword
            : ""
    );
    const [devise, setDevise] = React.useState<string[]>([]);
    const [shopIdentifier, setShopIdentifier] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.shopIdentifier
            : ""
    );

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const dataFetch = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();

        let devise: any[] = [];
        if (data.data !== undefined) {
            data.data.forEach((element: any) => {
                let deviseExist = AdyenCurrencies.find(
                    (el: any) => el.code === element[t("Currency")]
                );
                if (deviseExist !== undefined && element.isActive === true) {
                    devise.push({
                        label: element[t("Currency")],
                        value: element[t("Currency")],
                    });
                }
            });
        }

        setDevise(devise);
    };

    React.useEffect(() => {
        shopIds.length <= 1 || editedData.peripheralId !== undefined
            ? dataFetch()
            : setDevise([]);
        // eslint-disable-next-line
    }, [shopIds.length, editedData.peripheralId]);

    React.useEffect(() => {
        setMerchantAccount(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.merchantAccount
                : ""
        );
        setCryptoVersion(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.cryptoVersion
                : ""
        );
        setKeyIdentifier(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.keyIdentifier
                : ""
        );
        setPassphrase(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.passphrase
                : ""
        );
        setKeyVersion(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.keyVersion
                : ""
        );
        setCommunication(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.communication
                : ""
        );
        setCurrency(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.currency
                : ""
        );
        setWebhookConfig(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.webhook
                : ""
        );
        setMerchantTicket(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.merchantTicket
                : false
        );
        setCustomerTicket(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.customerTicket
                : false
        );
        setApiKey(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.apiKey
                : ""
        );
        setUserName(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.userName
                : ""
        );
        setUserPassword(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.userPassword
                : ""
        );
        setShopIdentifier(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.shopIdentifier
                : ""
        );
    }, [adyenConfig]);

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <span
                    onClick={() => {
                        if (codeSupport === "") {
                            setIsConfig(false);
                            setIsModalOpened(true);
                        }
                    }}
                >
                    <ArrowDownIcon fill="currentColor" />
                </span>
            </components.DropdownIndicator>
        );
    };

    return (
        <React.Fragment>
            <AvForm>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <StyledLabel htmlFor="example-input">
                        {t("Adyen Configuration ")}
                    </StyledLabel>
                    <div className="pl-5">
                        <SettingsIcon
                            className="pointer__clz"
                            height={20}
                            width={20}
                            onClick={() => {
                                setIsConfig(true);
                                setIsModalOpened(true);
                                setCodeSupport("");
                            }}
                        />
                    </div>
                </div>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="mt-3 required__clz"
                    >
                        {t("IP address")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputIp || ipExist,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddress"
                        name="ipAddress"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "ipAddress");
                            if (
                                !e.target.value.match(ipMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setMoneticData(true, "inputIp");
                            } else {
                                setMoneticData(false, "inputIp");
                            }
                            setIsEdited(true);
                        }}
                        value={ipAddress}
                    />
                    {inputIp || ipExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : !ipAddress.match(ipMatchWord)
                                    ? "IP address is invalid"
                                    : ipExist
                                    ? "The ip address is already exists"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("ID Adyen")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputAdyenIpAddress,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddressAdyen"
                        name="ipAddressAdyen"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "adyenIpAddress");
                            setMoneticData(false, "inputAdyenIpAddress");
                            setIsEdited(true);
                        }}
                        value={adyenIpAddress}
                    />
                    {inputAdyenIpAddress ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an Adyen ID")}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Port number")}
                    </StyledLabel>
                    <AvField
                        name="port_num"
                        id="port_num"
                        onChange={(e: any) => {
                            setIsEdited(true);
                            setMoneticData(e.target.value, "port");
                            if (
                                !e.target.value.match(numberMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setMoneticData(true, "inputPort");
                            } else {
                                setMoneticData(false, "inputPort");
                            }
                        }}
                        placeholder={t("Write")}
                        value={port}
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="90px"
                        className={classnames("imp_inp_portNumber", {
                            readOnly__clz: isConsult,
                            input__clz: inputPort,
                        })}
                    />
                    {inputPort ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                port === ""
                                    ? "Please enter a port number"
                                    : !port.match(numberMatchWord)
                                    ? "Port number is invalid"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Webhook Port")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputWebHook,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="webhook"
                        name="webhook"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "webhook");
                            setIsEdited(true);
                            if (
                                !e.target.value.match(numberMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setMoneticData(true, "inputWebHook");
                            } else {
                                setMoneticData(false, "inputWebHook");
                            }
                        }}
                        value={webhook}
                    />
                    {inputWebHook && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                webhook === ""
                                    ? "Please enter a webhook"
                                    : !webhook.match(numberMatchWord)
                                    ? "Webhook is invalid"
                                    : ""
                            )}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="payment type"
                        className="required__clz mt-3"
                    >
                        {t("Mode")}
                    </StyledLabel>
                    <StyledSelectInput
                        disabled
                        components={{ DropdownIndicator }}
                        className={classnames({
                            invalid__clz: inputMode,
                            readOnly__clz: isConsult,
                        })}
                        name="mode"
                        required
                        options={
                            codeSupport === ""
                                ? []
                                : [
                                      {
                                          label: "Test",
                                          value: "Test",
                                      },
                                      {
                                          label: "Production",
                                          value: "Production",
                                      },
                                  ]
                        }
                        placeholder={t("Select")}
                        onChange={(e: any) => {
                            setMoneticData(e.label, "mode");
                            setMoneticData(false, "inputMode");
                            setIsEdited(true);
                        }}
                        value={{ label: mode, value: mode }}
                        onFocus={() => {
                            if (codeSupport === "") {
                                setIsConfig(false);
                                setIsModalOpened(true);
                            }
                        }}
                    />
                    {inputMode && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a mode")}
                        </div>
                    )}
                </AvGroup>
            </AvForm>
            {isModalOpened ? (
                <SupportCodeModal
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    codeSupport={codeSupport}
                    setCodeSupport={setCodeSupport}
                    isValidCode={isValidCode}
                    setIsValidCode={setIsValidCode}
                    isConfig={isConfig}
                    setIsModalConfigOpened={setIsConfigurationModalOpened}
                />
            ) : null}
            {isConfigurationModalOpened ? (
                <AdyenConfigurationModal
                    isModalOpened={isConfigurationModalOpened}
                    setIsModalOpened={setIsConfigurationModalOpened}
                    setCodeSupport={setCodeSupport}
                    merchantAccount={merchantAccount}
                    setMerchantAccount={setMerchantAccount}
                    cryptoVersion={cryptoVersion}
                    setCryptoVersion={setCryptoVersion}
                    keyIdentifier={keyIdentifier}
                    setKeyIdentifier={setKeyIdentifier}
                    passphrase={passphrase}
                    setPassphrase={setPassphrase}
                    keyVersion={keyVersion}
                    setKeyVersion={setKeyVersion}
                    communication={communication}
                    setCommunication={setCommunication}
                    currency={currency}
                    setCurrency={setCurrency}
                    setAdyenConfig={setAdyenConfig}
                    adyenConfig={adyenConfig}
                    webhook={webhookConfig}
                    setWebhook={setWebhookConfig}
                    merchantTicket={merchantTicket}
                    customerTicket={customerTicket}
                    apiKey={apiKey}
                    userName={userName}
                    userPassword={userPassword}
                    setMerchantTicket={setMerchantTicket}
                    setCustomerTicket={setCustomerTicket}
                    setApiKey={setApiKey}
                    setUserName={setUserName}
                    setUserPassword={setUserPassword}
                    isConsult={isConsult}
                    devise={devise}
                    shopIds={shopIds}
                    setIsEdited={setIsEdited}
                    setShopIdentifier={setShopIdentifier}
                    shopIdentifier={shopIdentifier}
                />
            ) : null}
        </React.Fragment>
    );
}
