import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setIsConfigurationPrinterClicked,
    store as storeApp,
} from "../Applications/Kiosk/store";

import { setGlobalDataIsUpdated, setKey } from "../Applications/store";

import { store as userStore } from "@components/VerticalLayout/store";
import { resetStore as resetStoreCustomer } from "../ApplicationSetting/CustomerTicket/Hook/store";
import { resetStore as resetStoreKitchenTicket } from "../ApplicationSetting/KitchenTicket/Hook/store";
import { resetStore as resetStoreDivers } from "../ApplicationSetting/Divers/Hook/store";
import { resetStore as resetStoreModelTicket } from "../ApplicationSetting/ModeleTicket/store";
import {
    store,
    setPrinterConfigModalOpened,
    setPrinterConfigModalData,
} from "./store";
import {
    StoreGeneral,
    setTypeLogoCustomer,
} from "../ApplicationSetting/General/Hook/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { verifyIfPeripheralAssociate } from "@helpers/peripheralsHelpers";

import {
    store as printStore,
    setIsSettingModalOpened,
} from "@pages/Peripherals/Printers/store";

type ModalFooterType = {
    rowData: any;
    resetStore: Function;
};

export function PrinterFooter({ rowData, resetStore }: ModalFooterType) {
    const { t } = useTranslation();
    const { oneShop, shopID, userID, franchiseID, operatorID } = useSnapshot(
        userStore
    );
    const { isConfigurationPrinterClicked } = useSnapshot(storeApp);
    const { isSettingFromPrinter } = useSnapshot(printStore);
    const {
        dataGeneral,
        dataDivers,
        dataKitchenTicket,
        dataCustomerTicket,
        dataFirstTicket,
        dataSecondTicket,
        dataThreedTicket,
        dataFourthTicket,
        dataFifthTicket,
    } = useSnapshot(store);
    const { typeLogoCustomer, typeLogoKitchen } = useSnapshot(StoreGeneral);
    const isJpgOrPng =
        typeLogoCustomer === "image/jpeg" ||
        typeLogoCustomer === "image/png" ||
        typeLogoCustomer === "image/ico" ||
        typeLogoCustomer === "image/jpg";
    const isJpgOrPngKitchen =
        typeLogoKitchen === "image/jpeg" ||
        typeLogoKitchen === "image/png" ||
        typeLogoKitchen === "image/ico" ||
        typeLogoKitchen === "image/jpg";
    const [
        isValidateButtonEnabled,
        setIsValidateButtonEnabled,
    ] = React.useState<boolean>(true);
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;

        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: rowData.shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    async function handleValidateButtonClickEvent() {
        setIsValidateButtonEnabled(false);
        const uuidUser = localStorage.getItem("uuidUser");
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/printer/setting`;
        let shopId: string;
        let modelKitchenTicket = {
            model_1: dataFirstTicket,
            model_2: dataSecondTicket,
            model_3: dataThreedTicket,
            model_4: dataFourthTicket,
            model_5: dataFifthTicket,
        };

        if (oneShop) {
            shopId = shopID.toString();
        } else {
            shopId = rowData.shopId;
        }
        let savedData = {
            userId: userID,
            shopId: shopId,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            operatorId: operatorID,
            appName: [rowData?.appName],
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                customer_ticket: dataCustomerTicket,

                divers: dataDivers,
                general: dataGeneral,
                Kitchen_ticket: dataKitchenTicket,
                modelKitchenTicket: modelKitchenTicket,
            },
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(`${t("Registration successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        RedisPublishPeripheral();
                        setIsConfigurationPrinterClicked(
                            !isConfigurationPrinterClicked
                        );
                        setKey(uuid());
                        setIsValidateButtonEnabled(false);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function editPrinterConfig() {
        let shopId: string;
        shopId = shopID.toString();
        const appId = "KIOSK";
        let ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
            rowData["Id"],
            shopId,
            franchiseID,
            userID,
            appId
        );

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

        let modelKitchenTicket = {
            model_1: dataFirstTicket,
            model_2: dataSecondTicket,
            model_3: dataThreedTicket,
            model_4: dataFourthTicket,
            model_5: dataFifthTicket,
        };

        if (oneShop) {
            shopId = shopID.toString();
        } else {
            shopId = rowData.shopId;
        }
        const uuidUser = localStorage.getItem("uuidUser");
        let savedData = {
            userId: userID,
            shopId: shopId,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            operatorId: operatorID,
            appName: ifPeripheralAssociateObject.applicationNames,
            appId: "KIOSK",
            appId_children: ifPeripheralAssociateObject.appId_children,
            peripheralId: rowData["Id"],
            data: [
                {
                    id: rowData["Id"],
                    designation: rowData[t("Printer name")],
                    mark: rowData.mark,
                    note: rowData.note,
                    shopId: rowData.shopId,
                    type: rowData.type,
                    speed: rowData.speed,
                    path: rowData.path,
                    ip: rowData.ip,
                    port: rowData.port,
                    font_size: rowData[t("Font size")],
                    edit_date: new Date(),
                    add_date: "",
                    ticket_width: rowData.ticket_width,
                    port_series: rowData.port_series,
                    isArchived: rowData.isArchived,
                    code: rowData.code,
                    tickets: rowData.tickets,
                    customer_ticket: dataCustomerTicket,
                    divers: dataDivers,
                    general: dataGeneral,
                    Kitchen_ticket: dataKitchenTicket,
                    modelKitchenTicket: modelKitchenTicket,
                },
            ],
        };

        setPrinterConfigModalData({ savedData: savedData, apiUrl: apiUrl });
        setPrinterConfigModalOpened(true);
    }

    return (
        <ModalFooter>
            <CustomSecondaryColorButton
                outline
                style={{
                    height: "48px",
                }}
                rounded
                className="mr-2 afa_btn_cancelPopup"
                variant="light"
                onClick={() => {
                    setIsConfigurationPrinterClicked(false);
                    resetStore();
                    resetStoreCustomer();
                    resetStoreKitchenTicket();
                    resetStoreDivers();
                    resetStoreModelTicket();
                    setGlobalDataIsUpdated(true);
                    setIsSettingModalOpened(false);
                    setTypeLogoCustomer("");
                }}
            >
                <span>{t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                style={{
                    height: "48px",
                }}
                className="afa_btn_validatePopup"
                variant="primary"
                disabled={!isValidateButtonEnabled}
                onClick={() => {
                    if (
                        (isJpgOrPng && isJpgOrPngKitchen) ||
                        (typeLogoCustomer === "" && typeLogoKitchen === "") ||
                        (isJpgOrPng && typeLogoKitchen === "") ||
                        (isJpgOrPngKitchen && typeLogoCustomer === "")
                    ) {
                        if (isSettingFromPrinter !== true) {
                            handleValidateButtonClickEvent();
                        } else {
                            editPrinterConfig();
                        }
                    }
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
