import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { uuid } from "uuidv4";

import { store as appStore, setIsEdited } from "../../store";
import { store as userStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { ipMatchWord } from "@constants/index";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { setSelectedRow } from "@pages/SettingGeneral/Language/store";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type applicationConfigurationType = {
    setIsAddApplication: Function;
    setDataIsUpdated: Function;
    isAddApplication: boolean;
    isAddNewApplicationButtonClicked: boolean;
    selectedRows: any;
    data: any;
};

export function AddApplication({
    setIsAddApplication,
    isAddApplication,
    setDataIsUpdated,
    isAddNewApplicationButtonClicked,
    selectedRows,
    data,
}: applicationConfigurationType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        userStore
    );
    const { isEdited, isPlusIconClicked } = useSnapshot(appStore);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [designation, setDesignation] = useState<string>("");
    const [typeOfKDS, setTypeOfKDS] = useState<any>("");
    const [, setApplication] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [ip, setIp] = useState<string>("");
    const [isApplicationListExist, setIsApplicationListExist] = React.useState<
        boolean
    >(false);
    const [localNamesApplications, setLocalNamesApplications] = useState<
        string[]
    >([]);

    const isExist = React.useCallback(() => {
        let localNamesApplication: any[] = [];
        data.othersData !== undefined &&
            data.othersData.listDesignation.length !== 0 &&
            data.othersData.listDesignation.forEach((elt: any) => {
                if (!oneShop && elt.shopId === selectedRows[0]?.shopId) {
                    localNamesApplication.push(elt["Application"]);
                }
            });
        return localNamesApplication;
    }, [data, selectedRows, oneShop]);
    let isExistDesignation: any[] = isExist();
    const matchWordDesignation = new RegExp(
        new RegExp(/^[\w]+([-_\s]{1}[A-Za-z0-9]+)*$/i)
    );

    async function addApplication() {
        let id = uuid();
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds`;
        setIsClickedBouttonValider(false);
        const appId: string = "KDS";
        let data = {
            userId: userID,
            shopId: oneShop ? shopID : selectedRows[0]?.shopId,
            franchiseId: franchiseID,
            type: "Add",
            operatorId: operatorID,
            data: {
                [appId]: {
                    id_application: appId,
                    data_app: {
                        [id]: {
                            id_application_children: id,
                            printer: {},
                            designation: designation,
                            ip: ip,
                            note: remark,
                            typeOfKDS: typeOfKDS !== "" ? typeOfKDS : "",
                            isArchived: false,
                        },
                    },
                },
            },
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t("The application was successfully added")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDesignation("");
                    setTypeOfKDS("");

                    setRemark("");
                    setIp("");
                    setIsClickedBouttonValider(true);
                    setDataIsUpdated!(true);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editApplication() {
        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds`;
        const appId: string = "KDS";
        let data = {
            userId: userID,
            shopId: oneShop ? shopID : selectedRows[0]?.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [appId]: {
                    id_application: appId,
                    data_app: {
                        [selectedRows[0]?.appId]: {
                            id_application_children: selectedRows[0]?.appId,
                            printer: selectedRows[0].printer,
                            designation: designation,
                            ip: ip,
                            note: remark,
                            typeOfKDS: typeOfKDS !== "" ? typeOfKDS : "",
                            isArchived: false,
                        },
                    },
                },
            },
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t("The application was successfully changed")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDesignation("");
                    setTypeOfKDS("");
                    setRemark("");
                    setIp("");
                    setDataIsUpdated!(true);
                    setIsClickedBouttonValider(true);
                    setIsAddApplication!(!isAddApplication);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    useEffect(() => {
        if (selectedRows[0]?.appId) {
            setTitle(t("Modify an application"));
            setDesignation(selectedRows[0][t("Application")]);
            setApplication("KDS");
            setTypeOfKDS(selectedRows[0][t("Type of KDS")]);
            setRemark(selectedRows[0][t("Note")]);
            setIp(selectedRows[0][t("Address IP")]);
        } else {
            setTitle(t("Add an application"));
            setDesignation("");
            setApplication("KDS");
            setRemark("");
            setIp("");
            setTypeOfKDS("");
            setInputDesignation(false);
            setIsApplicationListExist(false);
            setIsClickedBouttonValider(true);
            // setExist(false);
        }

        // eslint-disable-next-line
    }, [selectedRows, t, isAddNewApplicationButtonClicked, isAddApplication]);
    React.useEffect(() => {
        isExist();
        if (!oneShop) {
            setLocalNamesApplications(isExistDesignation);
        } else {
            data.othersData !== undefined &&
                setLocalNamesApplications(data.othersData.listDesignation);
        }
        // eslint-disable-next-line
    }, [data, designation, isExist, oneShop]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setInputDesignation(false);
            setIsApplicationListExist(false);
        }
    }, [isPlusIconClicked]);
    useEffect(() => {
        if (Object.values(selectedRows).length !== 0) {
            if (selectedRows[t("Application")] !== "") {
                setInputDesignation(false);
            }
        }
        setIsClickedBouttonValider(true);
        // eslint-disable-next-line
    }, [selectedRows]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3">{title}</h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddApplication!(!isAddApplication);
                            setIsClickedBouttonValider(true);
                            setDataIsUpdated!(true);
                            // if (isEdited === true) {
                            //     setIsActionsClicked(!isActionsClicked);
                            //     setActions(() => {
                            //         activateCheckbox();
                            //         setIsAddAppClicked!(!isAddAppClicked);
                            //         setIsEdited(false);
                            //         setIsConsult(false);
                            //     });
                            // } else {
                            //     activateCheckbox();
                            //     setIsConsult(false);
                            //     setIsAddAppClicked!(!isAddAppClicked);
                            // }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody className="pl-3 pr-5">
                    <AvGroup>
                        <StyledLabel
                            htmlFor="name"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("afa_inp_designation", {
                                input__clz:
                                    inputDesignation ||
                                    isApplicationListExist ||
                                    (designation.trim() === "" &&
                                        designation !== ""),
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (
                                    localNamesApplications.includes(
                                        e.target.value.trim()
                                    )
                                ) {
                                    setIsApplicationListExist(true);
                                } else {
                                    setIsApplicationListExist(false);
                                }
                                setDesignation(e.target.value);
                                setIsEdited!(true);
                                setIsClickedBouttonValider(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            validate={{
                                pattern: {
                                    value: matchWordDesignation,
                                    errorMessage: t("Prohibited characters"),
                                },
                            }}
                            autoFocus
                        />
                        {inputDesignation ||
                        (designation.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isApplicationListExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Type of KDS")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            className={classnames("afa_inp_typeKds", {
                                // invalid__clz: inputChange || isApplicationListExist,
                                // "not-allowed-input__clz": devises.id,
                            })}
                            noOptionsMessage={() => t("No options")}
                            autocomplete="off"
                            name="change"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                setIsEdited(true);
                                setTypeOfKDS(e === null ? "" : e.label);
                            }}
                            options={[
                                {
                                    label: t("Assembly"),
                                    value: t("Assembly"),
                                },
                                {
                                    label: t("Production"),
                                    value: t("Production"),
                                },
                                {
                                    label: t("Dessert"),
                                    value: t("Dessert"),
                                },
                            ]}
                            value={
                                typeOfKDS === ""
                                    ? null
                                    : {
                                          label: typeOfKDS,
                                          value: typeOfKDS,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="example-input" className="mt-3">
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            id="ip"
                            name="ip"
                            autocomplete="off"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                setIp(e !== null ? e.target.value : "");
                                setIsEdited!(true);
                            }}
                            className={classnames("afa_inp_ipAddress", {
                                input__clz:
                                    ip !== undefined &&
                                    !ip.match(ipMatchWord) &&
                                    ip !== "",
                                // readOnly__clz: isConsult,
                            })}
                            value={ip}
                        />

                        {ip !== undefined &&
                            !ip.match(ipMatchWord) &&
                            ip !== "" && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("IP address is invalid")!}
                                </div>
                            )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                setRemark(e.target.value);
                                if (setIsEdited !== undefined)
                                    setIsEdited!(true);
                            }}
                            value={remark}
                            className={classnames("afa_inp_remark", {
                                // readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 cmn_btn_FRcancel"
                        onClick={() => {
                            setIsAddApplication!(!isAddApplication);
                            setDataIsUpdated!(true);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        disabled={!isClickedBouttonValider}
                        variant="primary"
                        onClick={() => {
                            if (designation === "") setInputDesignation(true);
                            if (
                                !isEdited ||
                                ((selectedRows[0] !== undefined &&
                                    selectedRows[0][t("Application")]) ===
                                    designation &&
                                    selectedRows[0] !== undefined &&
                                    selectedRows[0][t("Type of KDS")] ===
                                        typeOfKDS &&
                                    selectedRows[0] !== undefined &&
                                    selectedRows[0][t("Note")] === remark &&
                                    selectedRows[0] !== undefined &&
                                    selectedRows[0][t("Address IP")] === ip)
                            ) {
                                setIsModalOpened(true);
                            } else if (
                                designation.length !== 0 &&
                                designation.trim() !== "" &&
                                designation.match(matchWordDesignation) &&
                                !isApplicationListExist &&
                                !oneShop
                            ) {
                                if (selectedRows.length === 0) {
                                    ErrorToast(
                                        `${t(
                                            "Attention ! you must select a store."
                                        )!}`
                                    );
                                } else if (
                                    selectedRows[0] !== undefined &&
                                    selectedRows[0].appId === undefined
                                ) {
                                    addApplication();
                                } else if (
                                    selectedRows[0] !== undefined &&
                                    selectedRows[0].appId !== undefined
                                ) {
                                    editApplication();
                                }
                            } else if (
                                designation.length !== 0 &&
                                designation.trim() !== "" &&
                                designation.match(matchWordDesignation) &&
                                !isApplicationListExist &&
                                oneShop &&
                                selectedRows[0] === undefined
                            ) {
                                addApplication();
                            } else if (
                                designation.length !== 0 &&
                                designation.trim() !== "" &&
                                designation.match(matchWordDesignation) &&
                                !isApplicationListExist &&
                                selectedRows[0] !== undefined &&
                                selectedRows[0].appId !== undefined &&
                                oneShop
                            ) {
                                editApplication();
                            }
                        }}
                        className={classnames("cmn_btn_validate", {
                            // readOnly__clz: isConsult,
                            // "not-allowed-icon__clz": isConsult,
                        })}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddApplication}
                setIsAddClicked={setIsAddApplication}
                setEditedData={setSelectedRow}
            />
        </React.Fragment>
    );
}
