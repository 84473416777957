import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Activation from "../GeneralConfigC&C/Activation";
import LoyaltyAccount from "../GeneralConfigC&C/LoyaltyAccount";
import PaymentChoice from "../GeneralConfigC&C/PaymentChoice";
import SalesMethods from "../GeneralConfigC&C/SalesMethods";
import Options from "../GeneralConfigC&C/Options";
import Orders from "../GeneralConfigC&C/Orders";
import Delivery from "../GeneralConfigC&C/Delivery";
import Schedule from "../GeneralConfigC&C/Schedule";

import "./index.css";
import { setIsAddCity, setIsAddZone } from "../store";

type TabsMenuType = {
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};
type ModalContentWrapperType = {
    selectedRows:any
};
export function ModalContentWrapper({selectedRows}:ModalContentWrapperType): JSX.Element {
    const { t } = useTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let tabs: TabsMenuType[] = [];

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    tabs = [
        {
            title: t("Activation"),
            content: <Activation selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuTemplateChoice",
        },

        {
            title: t("Loyalty account"),
            content: <LoyaltyAccount  selectedRows={selectedRows}/>,
            customClassName: "pme_txt_menuOptions",
        },
        {
            title: t("Payment choice"),
            content: <PaymentChoice selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuAdversitingScreen",
        },
        {
            title: t("Sale Mode"),
            content: <SalesMethods selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuSaleMode",
        },
        {
            title: t("Options"),
            content: <Options  selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuPaymentMethod",
        },
        {
            title: t("Orders"),
            content: <Orders selectedRows={selectedRows}/>,
            customClassName: "pme_txt_menuInformationMode",
        },
        {
            title: t("Delivery"),
            content: <Delivery selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuCustomerAccount",
        },
        {
            title: t("Schedule"),
            content: <Schedule selectedRows={selectedRows} />,
            customClassName: "pme_txt_menuOrderTaking",
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: "#323333" }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "pb-1",
                                element.customClassName
                            )}
                        >
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);
                                    setIsAddCity(false)
                                    setIsAddZone(false)
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink>
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                className={classNames({
                    // "px-5": !isWaysTabActive && activeTab !== 3,
                })}
                // style={{
                //     overflowY: isWaysTabActive ? "hidden" : "auto",
                // }}
            >
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    // className={classNames({
                                    //     "d-none": subMenuIndex !== activeSubTab,
                                    // })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            key={index}
                            // className={classNames("h-100", {
                            //     "design-active-tab__clz": activeTab === 3,
                            // })}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
