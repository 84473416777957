import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as fidelity } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setDesignation: Function;
    setChoiceFidelity: Function;
    setAuthenticationMode: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setDesignation,
    setChoiceFidelity,
    setAuthenticationMode,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { isArchived, fidelityData, fidelityFranchise } = useSnapshot(
        fidelity
    );
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    async function AssociateFidelity() {
        toast.dismiss();
        let objectDataArchive: any =
            fidelityData.data[0] !== undefined
                ? {
                      [fidelityData.data[0].Id]: {
                          designation: fidelityData.data[0][t("Display name")],
                          type: fidelityData.data[0][t("Loyalty")],
                          number_fidelity_points:
                              fidelityData.data[0].number_fidelity_points,
                          money_correspondence:
                              fidelityData.data[0].moneyCorrespondence,
                          devise: fidelityData.data[0].Devise,
                          pays: fidelityData.data[0].Pays,
                          authentication_mode:
                              fidelityData.data[0].authentication_mode,
                          sortedVisibility:
                              fidelityData.data[0].sortedVisibility,
                          Login: fidelityData.data[0].Login,
                          password: fidelityData.data[0][t("Password")],
                          note: fidelityData.data[0][t("Remark")],
                          isActive: false,
                          isArchived: true,
                          shopId: shopID,
                          dashboardLink: fidelityData.data[0]["Dashboard Link"],
                          user: fidelityData.data[0].user,
                          apiKey: fidelityData.data[0]["API key"],
                          auresKonnectGroup: fidelityData.data[0][t("Group")],
                          isCentralizationActive:
                              fidelityData.data[0][t("State")],
                          isVisibiliteActive:
                              fidelityData.data[0][t("isVisibiliteActive")],

                          fidelityChoiceIndex:
                              fidelityData.data[0].fidelityChoiceIndex,
                      },
                  }
                : {};
        let objectData =
            fidelityFranchise.othersData.allData.data[
                fidelityFranchise.othersData.allData.data.findIndex(
                    (el: any) =>
                        el[t("Display name")] === data.designation &&
                        el[t("Loyalty")] === data.choiceFidelity &&
                        ((el[t("Group")] === data.group &&
                            data.choiceFidelity.startsWith(
                                t("Fidelity Aures Konnect")
                            )) ||
                            !data.choiceFidelity.startsWith(
                                t("Fidelity Aures Konnect")
                            ))
                )
            ];

        Object.assign(objectDataArchive, {
            [objectData.Id]: {
                designation: objectData[t("Display name")],
                type: objectData[t("Loyalty")],
                number_fidelity_points: objectData.number_fidelity_points,
                money_correspondence: objectData.moneyCorrespondence,
                devise: objectData.Devise,
                pays: objectData.Pays,
                authentication_mode: objectData.authentication_mode,
                sortedVisibility: objectData.sortedVisibility,
                Login: objectData.Login,
                password: objectData[t("Password")],
                note: objectData[t("Remark")],
                isActive: false,
                isArchived: false,
                shopId: shopID,
                auresKonnectGroup: objectData[t("Group")],
                isCentralizationActive: objectData[t("State")],
                isVisibiliteActive: objectData[t("isVisibiliteActive")],
                fidelityChoiceIndex: objectData.fidelityChoiceIndex,
            },
        });

        let fidelity = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data:
                fidelityData.data.length > 0
                    ? objectDataArchive
                    : {
                          [objectData.Id]: {
                              designation: objectData[t("Display name")],
                              type: objectData[t("Loyalty")],
                              number_fidelity_points:
                                  objectData.number_fidelity_points,
                              money_correspondence:
                                  objectData.moneyCorrespondence,
                              devise: objectData.Devise,
                              pays: objectData.Pays,
                              authentication_mode:
                                  objectData.authentication_mode,
                              sortedVisibility: objectData.sortedVisibility,
                              Login: objectData.Login,
                              password: objectData[t("Password")],
                              note: objectData[t("Remark")],
                              isArchived: false,
                              shopId: shopID,
                              auresKonnectGroup: objectData[t("Group")],
                              isCentralizationActive: objectData[t("State")],
                              isVisibiliteActive:
                                  objectData[t("isVisibiliteActive")],

                              fidelityChoiceIndex:
                                  objectData.fidelityChoiceIndex,
                          },
                      },
            isShop: true,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(fidelity),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fidelity settings has been successfully assigned"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);
                        clearMultiCustomSelectionData();
                        clearData();
                        setDesignation("");
                        setChoiceFidelity("");

                        setAuthenticationMode([]);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!isArchived
                        ? t(
                              "The fidelity settings already exist in the franchise. Would you like to associate it with this store?"
                          )
                        : t(
                              "Attention ! the fidelity settings is already archived at the franchise level"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isArchived) {
                            AssociateFidelity();
                        }

                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
