import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { Dropdown, DropdownButton } from "react-bootstrap";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import store from "./store";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type DropdownStatusType = {
    state: string;
    typeDisplay: string;
    data: any;
    franchiseID: string;
};

export function DropdownStatus({
    state,
    typeDisplay,
    data,
    franchiseID,
}: DropdownStatusType) {
    const { t } = useTranslation();
    const [lastState, setLastState] = useState<string>(t(data.Statut));
    // eslint-disable-next-line
    const [StatusValue, setStatusValue] = React.useState<string>(
        t(data.Statut)
    );
    const { isAddAppClicked } = useSnapshot(store);
    const [color1, setColor1] = useState<string>();

    useEffect(() => {
        if (data.Application !== "") {
            if (data.Statut !== undefined) {
                setStatusValue(data.Statut);
                setLastState(data.Statut);
                if (data.Statut === "In line" || data.Statut === "En ligne") {
                    setColor1("success");
                } else {
                    setColor1("danger");
                }
            } else {
                setStatusValue(data.Status);
                setLastState(data.Status);
                console.log(data.Status);
                if (data.Status === "In line" || data.Status === "En ligne") {
                    setColor1("success");
                } else {
                    setColor1("danger");
                }
            }
        }
        // eslint-disable-next-line
    }, [state, t]);
    // useEffect(() => {
    //     if (data.Statut !== undefined && data.Statut !== "")
    //         setLastState(StatusValue);
    // }, [StatusValue, t, state]);
    async function ClickEventEdit(data2: any, text: any) {
        if (text !== undefined) {
            setStatusValue(text);
            setLastState(text);

            if (text === "In line") {
                setColor1("success");
            } else {
                setColor1("danger");
            }

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/modifstate`;
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            franchiseId: franchiseID,
                            shopId: data.shopId,
                            applicationId: data.appId,
                            val: text,
                            nameKiosk: data.appName,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            } else {
                                toast.success(
                                    `${t("modification made successfully")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    if (data.Application !== "") {
        return (
            <div
                className={`${classnames("d-flex justify-content-around w-50", {
                    "not-allowed-icon__clz":
                        isAddAppClicked || typeDisplay === "archived",
                })}`}
            >
                <DropdownButton
                    key="Success"
                    id="dropdown-split-variants-Success"
                    variant={color1}
                    title={t(lastState)}
                    size="sm"
                >
                    <Dropdown.Item
                        onClick={() => ClickEventEdit(data, "In line")}
                        eventKey="1"
                    >
                        {t("In line")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => ClickEventEdit(data, "Offline")}
                        eventKey="2"
                        className="not-allowed-icon__clz"
                    >
                        {t("Offline")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => ClickEventEdit(data, "Standby state")}
                        eventKey="3"
                    >
                        {t("Standby state")}
                    </Dropdown.Item>
                </DropdownButton>
            </div>
        );
    } else {
        return <div></div>;
    }
}
