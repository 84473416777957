import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";

import {
    resetStore,
    setCheckModificationEffect,
    setIsAddPeripheralClicked,
} from "./store";

import { capitalize } from "@helpers/general";

import { MenuWrapperAssociate } from "../Kiosk/MenuWrapperAssociate/index";
import { setIsLoadingAssociateValidation } from "@components/VerticalLayout/store";

import "../index.css";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    oneShop: boolean;
    selectedRows:any
};

export default function DeviceAssociateModal({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    oneShop,
    selectedRows,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    setCheckModificationEffect(false);
    setIsLoadingAssociateValidation(false);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz modal-assoc__clz"
                isOpen={isDeviceAssociateModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsAddPeripheralClicked(false);
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        );
                        setDataIsUpdated(!dataIsUpdated);
                        resetStore();
                    }}
                >
                    <StyledH2>
                        {capitalize(
                            t("Associate a device with an application")
                        )}
                    </StyledH2>
                </ModalHeader>
                <MenuWrapperAssociate
                    rowData={rowData}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    oneShop={oneShop}
                    selectedRows={selectedRows}
                />
            </Modal>
        </React.Fragment>
    );
}
